import React, { useState, useEffect, Component } from 'react';
import {
  Grid, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, makeStyles,
  Switch, FormControlLabel, IconButton, DeleteIcon,
  EditIcon, _, swal, toast, useSelector, useDispatch
} from './../../utilities/PackagesList';
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay
} from "./../../utilities/ComponentsList";
import { isEmptyObj } from "./../../utilities/AppFunctions";
import { AddJobTypes } from './../../api/Services';
const useStyles = makeStyles(theme => ({
  dialogTitle: {

  }
}))
const Create = (props) => {
  const dispatch = useDispatch();
  const createState = useSelector((state) => state.AddJobTypesReducer);
  const classes = useStyles();
  const [CreateValues, setCreateValues] = useState({
    JobCode: '',
    JobType: ''
  });
  const [Createerrors, setCreateErrors] = useState({});
  if (createState.Success && !isEmptyObj(createState.AddingResponse)) {
    toast.success('Added Successfully!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: false,
    });
    props.ModelConfirm()
  } else if (createState.Failure && !isEmptyObj(createState.AddingResponse)) {
    toast.error(createState.AddingResponse.message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: false,
    });
  }
  const handleChange = name => event => {
    let targetValue = event.target.value;
    setCreateValues({ ...CreateValues, [name]: targetValue });
    setCreateErrors({ ...Createerrors, [name]: targetValue ? false : true })
  };
  const submitForm = () => {
    let srrorObj = {};
    let errorStatus = false;
    Object.keys(CreateValues).map(function (k) {
      srrorObj[k] = CreateValues[k] ? false : true;
      errorStatus = errorStatus ? true : !CreateValues[k];
    })


    setCreateErrors(srrorObj);
    if (!errorStatus) {
      let addObject = {
        jobCode: CreateValues.JobCode,
        jobType: CreateValues.JobType
      }

      dispatch(AddJobTypes(addObject));
    }


  }

  return (
    <Dialog
      open={props.Status}
      fullWidth
      maxWidth="sm"
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
    >
      <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <Grid
              container
              spacing={4}
              direction="column"
            >
              <Grid item lg={12} md={12} sm={12} xl={12} xs={12} spacing={4}>
                <InputField
                  label="Job Code"
                  type={"text"}
                  required={true}
                  value={CreateValues.JobCode}
                  changeText={handleChange('JobCode')}
                  error={Createerrors.JobCode}
                  initialName={"Job Code"}
                  errorMsg={"Please enter job code."}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xl={12} xs={12} spacing={4}>
                <InputField
                  label="Job Type Name"
                  type={"text"}
                  required={true}
                  value={CreateValues.JobType}
                  changeText={handleChange('JobType')}
                  error={Createerrors.JobType}
                  initialName={"Job Type Name"}
                  errorMsg={"Please enter job type name."}
                />
              </Grid>
            </Grid>

          </Grid>
          <Buttons
            close={() => props.ModelCancel()}
            closeText={"Cancel"}
            save={() => submitForm()}
            saveText={"Submit"}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default Create;