import React, { useState, useEffect, Component } from "react";
import {
  Grid, TextField, Button, Switch,
  FormControlLabel, IconButton, DeleteIcon,
  EditIcon, MUIDataTable, Autocomplete, connect,
  _, swal, toast, Typography
} from "./../../utilities/PackagesList";
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay, ButtonIcon
} from "./../../utilities/ComponentsList";
import { VechileItems } from "./../../utilities/MockData";
import { isEmptyObj , UnChangeObj} from "./../../utilities/AppFunctions";
import { GetJobTypes, UpdateJobTypes, DeleteJobTypes } from './../../api/Services';
import { refreshReduxStates } from './../../api/Refresh';
import Create from "./CreateJobType";
import Edit from "./EditJobType";

class JobType extends Component {

  constructor(props) {
    super(props);
    this.state = {
      DialogState: false,
      DeleteID: null,
      EditID: null,
      SelectedIndex: null,
      EditState:false,
      DeleteState:false,
      AddState:false,
      JobTypesLists:[],
      isLoading: true,
      getCalls: [1],
      postCalls: [0],
      SelectingList:[]
    }
    props.GetJobTypes();
  }
  UNSAFE_componentWillReceiveProps = (props) => {
    if (this.state.getCalls[0]) {
      if (props.JobTypeSuccess) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        let JobLists = _.orderBy(props.JobTypesList, ['jobTypeId'], ['asc']);
        this.setState({
          JobTypesLists: JobLists,
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.JobTypeFailure) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          JobTypesLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.postCalls[0]) {
      if (props.DeleteJobTypeSuccess) {
        let updatedList = this.state.JobTypesLists;
        updatedList.splice(this.state.SelectedIndex, 1);
        this.setState({
          postCalls: [0],
          isLoading: false,
          DeleteState: false,
          DeleteID: null,
          SelectedIndex: null,
          JobTypesLists: updatedList
        }, () => {
          this.props.refreshReduxStates();
          toast.success('Deleted Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      } else if (props.DeleteJobTypeFailure) {
        this.setState({
          postCalls: [0],
          isLoading: false,
          DeleteState: false,
          DeleteID: null,
          SelectedIndex: null,
        }, () => {
          toast.error(props.DeleteJobTypeResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      }
    }
  }
  editPress = (id, index) => {
    let ListData = UnChangeObj(this.state.JobTypesLists);
    this.setState({
      EditState: true,
      EditID: id,
      SelectingList : ListData,
      SelectedIndex: index
    })
  }
  CloseEdit = () => {
    this.setState({
      EditState: false,
      EditID: null,
      SelectingList : [],
      SelectedIndex: null,
    })
  }
  Edited = (EditedObject) => {
    /* let ListData = this.state.JobTypesLists;
    ListData[this.state.SelectedIndex] = EditedObject; */
    let ListData = this.state.JobTypesLists;
    let EditingID = this.state.EditID;
    let ItemIndex = ListData.findIndex(itm => itm.jobTypeId === EditingID);
    ListData[ItemIndex] = EditedObject;
    this.setState({
      EditState: false,
      EditID: null,
      SelectedIndex: null,
      isLoading: false,
      JobTypesLists: ListData,
      SelectingList : [],
      getCalls: [0],
    }, () => {
      this.props.refreshReduxStates();
    })
  }
  deletePress = (id, index) => {
    this.setState({
      DeleteState : true,
      DeleteID: id,
      SelectedIndex: index
    })
  }
  DeleteClose = () => {
    this.setState({
      DeleteState: false,
      DeleteID: null,
      SelectedIndex: null,
    })
  }

  DeleteConfirm = () => {
    this.setState({
      isLoading: true,
      postCalls:[1]
    }, () => {
      this.props.DeleteJobTypes(this.state.DeleteID);
    })
  }
  AddPress = () => {
    this.setState({
      AddState: true
    })
  }
  CloseAdd = () => {
    this.setState({
      AddState : false
    })
  }
  Added = () => {
    this.setState({
      AddState : false,
      isLoading: true,
      getCalls: [1],
    }, () => {
      this.props.GetJobTypes();
    })
  }
  render() {
    const columns = [
      {
        name: "jobTypeId",
        label: "ID",
        options: {
          filter: false,
          sort: false,
          display:false
        }
      },
      {
        name: "",
        label: "SNO",
        options: {
          filter: false,
          sort: true,
          display:true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item md={12}>
                  <Typography>{tableMeta.rowIndex+1}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "jobCode",
        label: "Job Code",
        options: {
          filter: false,
          sort: true,
          display:true,
        }
      },
      {
        name: "jobType",
        label: "Job Description",
        options: {
          filter: false,
          sort: true,
          display:true,
        }
      },
      {
        name: "status",
        label: "Status",
        options: {
          filter: false,
          sort: true,
          display:true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item md={12}>
                <Typography>{value ? "Active" : "InActive"}</Typography>
            </Grid>
            )
          }
        }
      },
      {
        name: "",
        label: "Actions",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <ButtonIcon
                EditTitle={"Edit Job Type"}
                DeleteTitle={"Delete Job Type"}
                Edit={() => this.editPress(tableMeta.rowData[0], tableMeta.rowIndex)}
                Delete={() => this.deletePress(tableMeta.rowData[0], tableMeta.rowIndex)}
              />
            )
          }
        }
      }
    ];

    return (
      <>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <MUIDataTable
              title="Job Types"
              data={this.state.JobTypesLists}
              columns={columns}
              options={{
                sort: true,
                selectableRows: false,
                print: false,
                download: false,
                viewColumns: false,
                filter: false,
                responsive:true,
                customToolbar: () => {
                  return (
                    <CustomToolbar title={"Add Job Type"} iconOnPress={() => { this.AddPress() }} />
                  );
                }
              }}
            />
          </Grid>
          <AlertDialog
            Status={this.state.DeleteState}
            DialogConfirm={() => { this.DeleteConfirm() }}
            DialogCancel={() => { this.DeleteClose() }}
            Title={"Delete Job Type"}
            Content={"Are you sure, you want to delete the job type ?"}
          />
          {this.state.AddState ? <Create
            Title={"Add Job Type"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.AddState}
            ModelConfirm={this.Added}
            ModelCancel={this.CloseAdd}
          /> : null}
          {this.state.EditState ? <Edit
            Title={"Edit Job Type"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.EditState}
            ModelConfirm={this.Edited}
            ModelCancel={this.CloseEdit}
          /> : null}
          <LoadingOverlay Status={this.state.isLoading} />
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    JobTypeSuccess: state.GetJobTypesReducer.Success,
    JobTypeFailure: state.GetJobTypesReducer.Failure,
    JobTypesList: state.GetJobTypesReducer.JobTypes,

    DeleteJobTypeSuccess: state.DeleteJobTypesReducer.Success,
    DeleteJobTypeFailure: state.DeleteJobTypesReducer.Failure,
    DeleteJobTypeResponse: state.DeleteJobTypesReducer.DeleteResponse,

    UserDetails: state.LoginReducer.UserDetails,
    LoaderState: state.LoaderReducer.State
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetJobTypes: () => dispatch(GetJobTypes()),
    DeleteJobTypes: (ID) => dispatch(DeleteJobTypes(ID)),
    refreshReduxStates: () => dispatch(refreshReduxStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobType);
