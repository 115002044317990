import MUIDataTable from "mui-datatables";
import { 
    Grid, TextField, Button, Switch, FormControlLabel, IconButton , 
    Checkbox, Typography , GridList, GridListTile, GridListTileBar, Divider, Paper,
    Tabs , Tab,  AppBar, TextareaAutosize, CssBaseline, CircularProgress, Fade,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Tooltip, Toolbar, InputBase, Menu, MenuItem, Fab, Link, Collapse, Box,
    List, ListItem, ListItemIcon, ListItemText, Badge, Avatar, Card ,CardMedia, CardContent, CardActions,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter, Input, InputAdornment,
    Radio, RadioGroup, FormControl, FormLabel  
} from "@material-ui/core";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import { TabPanel, TabContext, TabList  } from '@material-ui/lab';
import { 
    Delete as DeleteIcon, Edit as EditIcon, 
    Menu as MenuIcon, MailOutline as MailIcon,
    NotificationsNone as NotificationsIcon, Person as AccountIcon,
    Search as SearchIcon, Send as SendIcon,
    ArrowBack as ArrowBackIcon, Add as AddIcon,
    Inbox as InboxIcon, MoreVert as MoreIcon,
    Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon,
    PlaylistAddCheck as AddCheckIcon, SettingsBackupRestore as RestoreIcon,
    Cancel as CancelIcon, CheckCircle as CheckedIcon, CheckCircleOutline as UnCheckedIcon,
    Schedule as ScheduleIcon
    } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, ThemeProvider, useTheme} from "@material-ui/styles";
import { withStyles } from "@material-ui/core/styles";
import { useHistory, withRouter } from "react-router-dom";
import {Provider, connect, useDispatch, useSelector} from 'react-redux';
import { createStore, applyMiddleware , combineReducers } from 'redux';
import classnames from "classnames";
import axios from 'axios';
import thunk from 'redux-thunk'; 
import _ from 'lodash';
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import FullCalendar, { formatDate } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import XLSX from 'xlsx';
export{
    MUIDataTable, Autocomplete, useSelector, useDispatch,
    Grid, TextField, Button, Switch, FormControlLabel, IconButton,
    Toolbar, InputBase, Menu, MenuItem, Fab, Link, Tooltip, withStyles,
    DeleteIcon, MenuIcon, EditIcon, NotificationsIcon, AccountIcon, SendIcon,
    SearchIcon, ArrowBackIcon, MailIcon, AddIcon, InboxIcon, MoreIcon,
    makeStyles, Checkbox , GridList, GridListTile, GridListTileBar, Typography, Divider, useHistory, Paper,
    Tabs, Tab, TabPanel, AppBar, TabContext, TabList, TextareaAutosize, Provider, connect,
    createStore, applyMiddleware, combineReducers, thunk, CircularProgress, Fade, withRouter, classnames,
    axios, CssBaseline, ThemeProvider, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText,
    useTheme, Collapse, List, ListItem, ListItemIcon, ListItemText, Badge, _, swal, ToastContainer, toast,
    moment, Avatar, Card ,CardMedia, CardContent, CardActions, Box, Table, TableBody, TableCell, TableFooter, TableContainer, TableHead, TableRow,
    SRLWrapper, SimpleReactLightbox, Input, InputAdornment, VisibilityIcon, VisibilityOffIcon, AddCheckIcon, RestoreIcon, CancelIcon,
    AgGridColumn, AgGridReact, UnCheckedIcon, CheckedIcon, ScheduleIcon, Radio, RadioGroup, FormControl, FormLabel, FullCalendar, formatDate, dayGridPlugin,
    timeGridPlugin, interactionPlugin, XLSX
}