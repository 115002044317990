import React, { useState, useEffect, Component } from "react";
import {
  Grid, TextField, Button, Switch,
  FormControlLabel, IconButton, DeleteIcon,
  EditIcon, MUIDataTable, Typography, connect,
  _, swal, toast
} from "./../../utilities/PackagesList";
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay, ButtonIcon
} from "./../../utilities/ComponentsList";
import { VechileItems } from "./../../utilities/MockData";
import { isEmptyObj, UnChangeObj } from "./../../utilities/AppFunctions";
import { GetPaymentTypes, DeletePaymentTypes } from './../../api/Services';
import { refreshReduxStates } from './../../api/Refresh';
import { LoaderStatus } from './../../api/Loading';
import Create from "./CreatePayment";
import Edit from "./EditPayment";

class PaymentType extends Component {

  constructor(props) {
    super(props);
    this.state = {
      DeleteID: null,
      EditID: null,
      SelectedIndex: null,
      EditState:false,
      AddState:false,
      DeleteState:false,
      PaytmentTypesList:[],
      isLoading: true,
      getCalls: [1],
      postCalls: [0],
      SelectingList:[]
    }
    props.GetPaymentTypes();
  }
  UNSAFE_componentWillReceiveProps = (props) => {
    if (this.state.getCalls[0]) {
      if (props.PaymentTypeSuccess) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        let PaymentLists = _.orderBy(props.PaymentTypeList, ['id'], ['asc']);
        this.setState({
          PaytmentTypesList: PaymentLists,
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.PaymentTypeFailure) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          PaytmentTypesList: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.postCalls[0]) {
      if (props.DeletePaymentSuccess) {
        let updatedList = this.state.PaytmentTypesList;
        updatedList.splice(this.state.SelectedIndex, 1);
        this.setState({
          postCalls: [0],
          isLoading: false,
          DeleteState: false,
          DeleteID: null,
          SelectedIndex: null,
          PaytmentTypesList: updatedList
        }, () => {
          this.props.refreshReduxStates();
          toast.success('Deleted Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      } else if (props.DeletePaymentFailure) {
        this.setState({
          postCalls: [0],
          isLoading: false,
          DeleteState: false,
          DeleteID: null,
          SelectedIndex: null,
        }, () => {
          toast.error(props.DeletePaymentResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      }
    }
  }
  editPress = (id, index) => {
    let ListData = UnChangeObj(this.state.PaytmentTypesList);
    this.setState({
      EditState: true,
      EditID: id,
      SelectingList : ListData,
      SelectedIndex: index
    })
  }
  CloseEdit = () => {
    this.setState({
      EditState: false,
      EditID: null,
      SelectingList : [],
      SelectedIndex: null,
    })
  }
  Edited = (EditedObject) => {
    /* let ListData = this.state.PaytmentTypesList;
    ListData[this.state.SelectedIndex] = EditedObject; */
    let ListData = this.state.PaytmentTypesList;
    let EditingID = this.state.EditID;
    let ItemIndex = ListData.findIndex(itm => itm.id === EditingID);
    ListData[ItemIndex] = EditedObject;
    this.setState({
      EditState: false,
      EditID: null,
      SelectedIndex: null,
      isLoading: false,
      PaytmentTypesList: ListData,
      getCalls: [0],
      SelectingList : []
    }, () => {
      this.props.refreshReduxStates();
    })
  }
  deletePress = (id, index) => {
    this.setState({
      DeleteState:true,
      DeleteID: id,
      SelectedIndex: index,
      postCalls: [1]
    })
  }
  DeleteClose = () => {
    this.setState({
      DeleteState : false,
      DeleteID: null,
      SelectedIndex: null,
    })
  }

  DeleteConfirm = () => {
    this.setState({
      isLoading: true
    }, () => {
      this.props.DeletePaymentTypes(this.state.DeleteID);
    })
  }
  AddItem = () => {
    this.setState({
      AddState : true
    })
  }
  
  CloseCreate = () => {
    this.setState({
      AddState: false
    })
  }
  Added = () => {
    this.setState({
      AddState: false,
      isLoading: true,
      getCalls: [1],
    }, () => {
      this.props.GetPaymentTypes();
    })
  }
  render() {
    const columns = [
      {
        name: "id",
        label: "ID",
        options: {
          filter: false,
          sort: false,
          display:false
        }
      },
      {
        name: "",
        label: "SNO",
        options: {
          filter: false,
          sort: true,
          display:true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item md={12}>
                  <Typography>{tableMeta.rowIndex+1}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "name",
        label: "Payment Type",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "code",
        label: "Payment Code",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "",
        label: "Actions",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <ButtonIcon
                EditTitle={"Edit Payment Type"}
                DeleteTitle={"Delete Payment Type"}
                Edit={() => this.editPress(tableMeta.rowData[0], tableMeta.rowIndex)}
                Delete={() => this.deletePress(tableMeta.rowData[0], tableMeta.rowIndex)}
              />
            )
          }
        }
      }
    ];

    return (
      <>
        {/*  <PageTitle title="Vehicles" /> */}
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <MUIDataTable
              title="Payment Types"
              data={this.state.PaytmentTypesList}
              columns={columns}
              options={{
                sort: true,
                selectableRows: false,
                print: false,
                download: false,
                viewColumns: false,
                filter: false,
                responsive:true,
                customToolbar: () => {
                  return (
                    <CustomToolbar title={"Add Payment Type"} iconOnPress={() => { this.AddItem() }} />
                  );
                }
              }}
            />
          </Grid>
          <AlertDialog
            Status={this.state.DeleteState}
            DialogConfirm={() => { this.DeleteConfirm() }}
            DialogCancel={() => { this.DeleteClose() }}
            Title={"Delete Payment Type"}
            Content={"Are you sure, you want to delete the payment type ?"}
          />
          {this.state.AddState ? <Create
            Title={"Add Payment Type"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.AddState}
            ModelConfirm={this.Added}
            ModelCancel={this.CloseCreate}
          /> : null}
          {this.state.EditState ? <Edit
            Title={"Edit Payment Type"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.EditState}
            ModelConfirm={this.Edited}
            ModelCancel={this.CloseEdit}
          /> : null}
          <LoadingOverlay Status={this.state.isLoading} />
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    PaymentTypeSuccess: state.GetPaymentTypesReducer.Success,
    PaymentTypeFailure: state.GetPaymentTypesReducer.Failure,
    PaymentTypeList: state.GetPaymentTypesReducer.PaymentTypes,

    DeletePaymentSuccess: state.DeletePaymentTypesReducer.Success,
    DeletePaymentFailure: state.DeletePaymentTypesReducer.Failure,
    DeletePaymentResponse: state.DeletePaymentTypesReducer.DeleteResponse,

    UserDetails: state.LoginReducer.UserDetails,
    LoaderState: state.LoaderReducer.State
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetPaymentTypes: () => dispatch(GetPaymentTypes()),
    DeletePaymentTypes: (ID) => dispatch(DeletePaymentTypes(ID)),
    refreshReduxStates: () => dispatch(refreshReduxStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentType);
