import React, { useState, useEffect, Component } from "react";
import {
  Grid, TextField, Button, Switch,
  FormControlLabel, IconButton, DeleteIcon,
  EditIcon, MUIDataTable, Autocomplete, connect,
  _, swal, toast, Typography, Checkbox, Tab,
  TabPanel, AppBar, TabContext, TabList, withStyles,
  CardMedia, SRLWrapper, SimpleReactLightbox
} from "../../utilities/PackagesList";
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay, ButtonIcon
} from "../../utilities/ComponentsList";
import {
  isEmptyObj, dateTimeFormating, todayDate,
  LastDayMonth, DateMinusYMD, AMPM,
  convertDateDMY, nullToStr, CheckSame
} from "../../utilities/AppFunctions";
import { getAdhoc, getSeller, getOrder } from '../../api/Adhoc';
import { getTeams } from '../../api/Team';
import { refreshReduxStates } from '../../api/Refresh';
import styles from "../../utilities/StylesClass";
import BaseDetails from './../../utilities/BaseDetails.json';

class DeliveryReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      MarketPlaceID: "",
      MarketPlaceObj: "",
      SellersLists: [],
      DeliveriesList: [],
      OrderLists: [],
      OrdStartDate: "",//DateMinusYMD(5),
      OrdEndDate: "",//todayDate(),
      DelStartDate: "",//DateMinusYMD(5),
      DelEndDate: "",//todayDate(),
      FilterInvoice: "",
      FilterMobile: "",
      FilterOrderID: "",
      TeamSelectedID: "",
      TeamSelected: "",
      isLoading: true,
      getCalls: [0, 1, 1, 0],
      TeamsLists: []

    }
    props.getTeams(2);
    props.getSeller();

  }

  UNSAFE_componentWillReceiveProps = (props) => {
    if (this.state.getCalls[0]) {
      if (props.AdhocListSuccess) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        let InitialOrders = this.state.OrderLists;
        let NormalDeliveries = props.AdhocList;
        let FullOrdersList = InitialOrders.concat(NormalDeliveries);
        let FilterInvoiceNo = this.state.FilterInvoice;
        let MarketID = this.state.MarketPlaceID;
        let SellerTypeObj = this.state.MarketPlaceObj;
        let MobileSelected = this.state.FilterMobile;
        let OrderIDSelected = this.state.FilterOrderID;
        let OrderStart = this.state.OrdStartDate ? this.state.OrdStartDate : "";
        let OrderEnd = this.state.OrdEndDate ? this.state.OrdEndDate : "";
        let TeamID = this.state.TeamSelectedID ? this.state.TeamSelectedID : "";
       
        if(OrderStart || OrderEnd){
          FullOrdersList = _.orderBy(FullOrdersList, ['createdAt'], ['desc']);
        }else{
          FullOrdersList = _.orderBy(FullOrdersList, ['deliveryDate', 'deliveryTime'], ['desc', 'desc']);
        }
        let FilteredItems = _.filter(FullOrdersList, function (item) {
          let filterThings = (MarketID > 0 ? (SellerTypeObj.name.toLowerCase() == item.sellerType) : true)
            && (FilterInvoiceNo ? (FilterInvoiceNo == item.customerInvoiceNumber) : true)
            && (MobileSelected ? (MobileSelected == item.contactNumber) : true)
            && (OrderIDSelected ? (OrderIDSelected == item.orderId) : true)
            && (TeamID ? (TeamID == item.teamId) : true);
            if(item.deliveryStatusId!='5'){
              item.updatedAt = ""
            }
            return filterThings ? item : "";
        });
        console.log(JSON.stringify(FilteredItems))
        this.setState({
          DeliveriesList: FilteredItems,
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.AdhocListFailure) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          DeliveriesList: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.getCalls[1]) {
      if (props.TeamListSuccess) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        let newTeam = {
          "id": 0,
          "teamName": "All",
          "vehicleNumber": "",
          "vehicelId": "",
          "assignedStaff": [],
          "teamTypeId": 0,
          "teamTypeName": "all"
        };
        let Teams = props.TeamLists;
        Teams.unshift(newTeam);
        this.setState({
          TeamsLists: props.TeamLists,
          isLoading: loaderStatus,
          TeamSelectedID: Teams[0].id,
          TeamSelected: Teams[0],
          getCalls: succed
        })
      } else if (props.TeamListFailure) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          TeamsLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.getCalls[2]) {
      if (props.SellerListSuccess) {
        let succed = this.state.getCalls;
        succed[2] = 0;
        let loaderStatus = succed.includes(1);
        let newSeller = {
          "id": 0,
          "name": "All"
        };
        let Sellers = props.SellerLists;
        Sellers.unshift(newSeller)
        this.setState({
          SellersLists: Sellers,
          isLoading: loaderStatus,
          MarketPlaceID: Sellers[0].id,
          MarketPlaceObj: Sellers[0],
          getCalls: succed
        })
      } else if (props.SellerListFailure) {
        let succed = this.state.getCalls;
        succed[2] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          SellersLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.getCalls[3]) {
      if (props.OrdersListSuccess) {
        let succed = this.state.getCalls;
        succed[3] = 0;
        succed[0] = 1;
        let loaderStatus = succed.includes(1);
        let orders = props.OrdersLists;
        let OrderStart = this.state.OrdStartDate ? this.state.OrdStartDate : "";
        let OrderEnd = this.state.OrdEndDate ? this.state.OrdEndDate : "";
        let DeliveryStart = this.state.DelStartDate ? this.state.DelStartDate : "";
        let DeliveryEnd = this.state.DelEndDate ? this.state.DelEndDate : "";
        let SellerType = this.state.MarketPlaceID ? this.state.MarketPlaceID : "";
        let SellerTypeObj = this.state.MarketPlaceObj;
        let TeamSelectedID = this.state.TeamSelectedID;
        let filterObj = {
          OrderStartDate: OrderStart,
          OrderEndDate: OrderEnd,
          StartDate: DeliveryStart,
          EndDate: DeliveryEnd,
          OrderType: "",
          DeliveryStatus: "",
          TeamID: TeamSelectedID,
          sellerType: parseInt(SellerType) > 0 ? SellerTypeObj.name.toLowerCase() : ""
        }
        
        this.setState({
          OrderLists: orders,
          isLoading: loaderStatus,
          getCalls: succed
        },()=>{
          this.props.getAdhoc(filterObj);
        })
      } else if (props.OrdersListFailure) {
        let succed = this.state.getCalls;
        succed[3] = 0;
        succed[0] = 1;
        let loaderStatus = succed.includes(1);
        let OrderStart = this.state.OrdStartDate ? this.state.OrdStartDate : "";
        let OrderEnd = this.state.OrdEndDate ? this.state.OrdEndDate : "";
        let DeliveryStart = this.state.DelStartDate ? this.state.DelStartDate : "";
        let DeliveryEnd = this.state.DelEndDate ? this.state.DelEndDate : "";
        let SellerType = this.state.MarketPlaceID ? this.state.MarketPlaceID : "";
        let SellerTypeObj = this.state.MarketPlaceObj;
        let TeamSelectedID = this.state.TeamSelectedID;
        let filterObj = {
          OrderStartDate: OrderStart,
          OrderEndDate: OrderEnd,
          StartDate: DeliveryStart,
          EndDate: DeliveryEnd,
          OrderType: "",
          DeliveryStatus: "",
          TeamID: TeamSelectedID,
          sellerType: parseInt(SellerType) > 0 ? SellerTypeObj.name.toLowerCase() : ""
        }
        this.setState({
          OrderLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        },()=>{
          this.props.getAdhoc(filterObj);
        })
      }
    }
  }

  searchClick = () => {
    if (!this.state.OrdStartDate && !this.state.OrdEndDate && !this.state.DelStartDate && !this.state.DelEndDate && !this.state.MarketPlaceID
      && !this.state.TeamSelectedID) {//&& !this.state.FilterOrderID && !this.state.FilterInvoice && !this.state.FilterMobile) {
      toast.warning('Please choose any one filter option!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      });
      return;
    }/* else if(!this.state.OrdStartDate && !this.state.OrdEndDate && !this.state.DelStartDate && !this.state.DelEndDate){
      toast.warning('Please choose order/delivery date filter', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      });
      return;
    } */
    let OrderStart = this.state.OrdStartDate ? this.state.OrdStartDate : "";
    let OrderEnd = this.state.OrdEndDate ? this.state.OrdEndDate : "";
    let DeliveryStart = this.state.DelStartDate ? this.state.DelStartDate : "";
    let DeliveryEnd = this.state.DelEndDate ? this.state.DelEndDate : "";
    let SellerType = this.state.MarketPlaceID ? this.state.MarketPlaceID : "";
    let SellerTypeObj = this.state.MarketPlaceObj;
    let TeamSelectedID = this.state.TeamSelectedID;
    if(OrderStart || OrderEnd){
        let filterObj = {
          StartDate: OrderStart,
          EndDate: OrderEnd,
          OrderType: "",
          DeliveryStatus: 1,
          SellerType: parseInt(SellerType) > 0 ? SellerTypeObj.name.toLowerCase() : ""
        }
        this.setState({
          getCalls: [0, 0, 0, 1],
          isLoading: true,
          DeliveriesList: [],
          OrderLists: []
        }, () => {
          this.props.getOrder(filterObj);
        })
    }else{
      let filterObj = {
        OrderStartDate: OrderStart,
        OrderEndDate: OrderEnd,
        StartDate: DeliveryStart,
        EndDate: DeliveryEnd,
        OrderType: "",
        DeliveryStatus: "",
        TeamID: TeamSelectedID,
        sellerType: parseInt(SellerType) > 0 ? SellerTypeObj.name.toLowerCase() : ""
      }
      this.setState({
        getCalls: [1, 0, 0, 0],
        isLoading: true,
        DeliveriesList: [],
        OrderLists: []
      }, () => {
        this.props.getAdhoc(filterObj);
      })
    }

  }

  clearSearchClick = () => {
    this.setState({
      DeliveriesList: [],
      OrderLists: [],
      OrdStartDate: "",//DateMinusYMD(5),
      OrdEndDate: "",//todayDate(),
      DelStartDate: "",//DateMinusYMD(5),
      DelEndDate: "",//todayDate(),
      FilterInvoice: "",
      FilterMobile: "",
      FilterOrderID: "",
      TeamSelectedID: "",
      TeamSelected: "",
      getCalls: [0, 0, 0, 0]
    });

  }

  render() {
    const { classes } = this.props;

    const columns = [
      {
        name: "id",
        label: "Select",
        options: {
          sort: false,
          filter: false,
          display: false,
          print: false
        }
      },
      {
        name: "sellerType",
        label: "Marketplace",
        options: {
          filter: false,
          sort: true,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item md={12}>
                <Typography>{nullToStr(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "createdAt",
        label: "Order Date",
        options: {
          filter: false,
          sort: true,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item md={12}>
                <Typography>{convertDateDMY(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "orderNumber",
        label: "Order Number",
        options: {
          filter: false,
          sort: true,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item md={12}>
                <Typography>{nullToStr(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "customerInvoiceNumber",
        label: "Custom Invoice",
        options: {
          filter: false,
          sort: true,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item md={12}>
                <Typography>{nullToStr(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "customerName",
        label: "Customer Name",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item md={12}>
                <Typography>{nullToStr(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "contactNumber",
        label: "Contact No",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true,
        }
      },
      {
        name: "address",
        label: "Address",
        options: {
          filter: false,
          sort: false,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item md={12}>
                <Typography>{nullToStr(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "postalCode",
        label: "Postal Code",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true
        }
      },
      {
        name: "items",
        label: "Product",
        options: {
          filter: false,
          sort: false,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item md={12}>
                <Typography>{nullToStr(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "remark",
        label: "Remark",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item md={12}>
                <Typography>{nullToStr(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "deliveryTime",
        label: "Delivery Time",
        options: {
          filter: false,
          sort: false,
          display: false,
          print: false
        }
      },
      {
        name: "deliveryDate",
        label: "Date of Delivery",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item md={12}>
                <Typography>{convertDateDMY(value)}</Typography>
                <Typography>{AMPM(tableMeta.rowData[11])}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "teamName",
        label: "Team",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true
        }
      },
      {
        name: "deliveryStatusId",
        label: "Status",
        options: {
          filter: false,
          sort: false,
          display: false,
          print: false
        }
      },
      {
        name: "completedAt",
        label: "Completed Date",
        options: {
          filter: false,
          sort: true,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item lg={12}>
                <Typography>{dateTimeFormating(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "updatedAt",
        label: "Canceled Date",
        options: {
          filter: false,
          sort: true,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item lg={12}>
                <Typography>{tableMeta.rowData[14]=='5' ? dateTimeFormating(value) : ""}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "remark",
        label: "Delivery Remark",
        options: {
          filter: false,
          sort: false,
          display: false,
          print: false
        }
      }
    ];
    const ReportList = this.state.DeliveriesList;
    return (
      <>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <Grid container spacing={1} md={12} direction={"row"}>
              <Grid item lg={2} md={2} sm={2} xl={2} xs={2}>
                <InputField
                  label={"Order Start Date"}
                  type={"date"}
                  required={false}
                  value={this.state.OrdStartDate}
                  changeText={(data) => {
                    this.setState({
                      OrdStartDate: data ? data.target.value : null,
                      OrdEndDate: data ? LastDayMonth(data.target.value) : null
                    })
                  }}
                  initialName={"Order Start Date"}
                  errorMsg={"Please enter start date."}
                />
              </Grid>
              <Grid item lg={2} md={2} sm={2} xl={2} xs={2}>
                <InputField
                  label={"Order End Date"}
                  type={"date"}
                  required={false}
                  value={this.state.OrdEndDate}
                  changeText={(data) => { this.setState({ OrdEndDate: data ? data.target.value : null }) }}
                  // error={Createerrors.Date}
                  initialName={"Order End Date"}
                  errorMsg={"Please enter end date."}
                />
              </Grid>
              <Grid item lg={2} md={2} sm={2} xl={2} xs={2}>
                <Dropdown
                  label={"Marketplace"}
                  data={this.state.SellersLists}
                  getOptionLabel={option => (option.name ? option.name : "")}
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={this.state.MarketPlaceObj}
                  ItemChange={(event, newValue) => {
                    this.setState({
                      MarketPlaceID: newValue ? newValue.id : null,
                      MarketPlaceObj: newValue ? newValue : null
                    })
                  }}
                  // error={this.state.syncErrors[2]}
                  initialName={"Marketplace"}
                  errorMsg={"Please choose marketplace."}
                />
              </Grid>
              <Grid item lg={2} md={2} sm={2} xl={2} xs={2}>
                <Dropdown
                  label={"Select Team"}
                  data={this.state.TeamsLists}
                  getOptionLabel={option => (option.teamName ? option.teamName : "")}
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={this.state.TeamSelected}
                  ItemChange={(event, newValue) => {
                    this.setState({
                      TeamSelectedID: newValue ? newValue.id : null,
                      TeamSelected: newValue ? newValue : null
                    })
                  }}
                  //error={Createerrors.TeamSelectedID}
                  initialName={"Team"}
                  errorMsg={"Please choose team."}
                />
              </Grid>
              <Grid item lg={2} md={2} sm={2} xl={2} xs={2}>
                <InputField
                  label={"Delivery Start Date"}
                  type={"date"}
                  required={false}
                  value={this.state.DelStartDate}
                  changeText={(data) => {
                    this.setState({
                      DelStartDate: data ? data.target.value : null,
                      DelEndDate: data ? LastDayMonth(data.target.value) : null
                    })
                  }}
                  initialName={"Delivery Start Date"}
                  errorMsg={"Please enter start date."}
                />
              </Grid>
              <Grid item lg={2} md={2} sm={2} xl={2} xs={2}>
                <InputField
                  label={"Delivery End Date"}
                  type={"date"}
                  required={false}
                  value={this.state.DelEndDate}
                  changeText={(data) => { this.setState({ DelEndDate: data ? data.target.value : null }) }}
                  // error={Createerrors.Date}
                  initialName={"Delivery End Date"}
                  errorMsg={"Please enter end date."}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} lg={12} md={12} sm={12} xl={12} xs={12} direction={"row"} style={{ marginTop: 10 }}>

              {/* <Grid item lg={2} md={2} sm={2} xl={2} xs={2}>
                <InputField
                  label={"Order ID"}
                  type={"text"}
                  required={false}
                  value={this.state.FilterOrderID}
                  changeText={(data) => this.setState({ FilterOrderID: data ? data.target.value : null })}
                  // error={Createerrors.Date}
                  initialName={"Order ID"}
                  errorMsg={"Please enter order id."}
                />
              </Grid>
              <Grid item lg={2} md={2} sm={2} xl={2} xs={2}>
                <InputField
                  label={"Customer Mobile"}
                  type={"text"}
                  required={false}
                  value={this.state.FilterMobile}
                  changeText={(data) => this.setState({ FilterMobile: data ? data.target.value : null })}
                  // error={Createerrors.Date}
                  initialName={"Customer Mobile"}
                  errorMsg={"Please enter customer mobile."}
                />
              </Grid>
              <Grid item lg={2} md={2} sm={2} xl={2} xs={2}>
                <InputField
                  label={"Custom Invoice"}
                  type={"text"}
                  required={false}
                  value={this.state.FilterInvoice}
                  changeText={(data) => this.setState({ FilterInvoice: data ? data.target.value : null })}
                  // error={Createerrors.Date}
                  initialName={"Customer Name"}
                  errorMsg={"Please enter customer name."}
                />
              </Grid> */}
              <Grid item lg={1} md={1} sm={1} xl={1} xs={1}>
                <Button
                  onClick={() => this.clearSearchClick()}
                  className={classes.buttonCancel}
                  variant="contained" color="primary">
                  Clear
                  </Button>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xl={1} xs={1}>
                <Button
                  onClick={() => this.searchClick()}
                  className={classes.buttonSuccess}
                  variant="contained" color="primary">
                  Search
                  </Button>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xl={12} xs={12} style={{ marginTop: 10 }}>
              <MUIDataTable
                title=""
                data={this.state.DeliveriesList}
                columns={columns}
                options={{
                  downloadOptions: {
                    filename: "Delivery_Report.csv",
                    filterOptions: {
                      useDisplayedColumnsOnly: true,
                      useDisplayedRowsOnly: true
                    }
                  },
                  sort: true,
                  selectableRows: false,
                  selectableRowsOnClick: false,
                  responsive: true,
                  print: true,
                  download: true,
                  viewColumns: false,
                  filter: false,
                   /* onDownload: (buildHead, buildBody, columns, data) => {
                    data.forEach(function (prow, pIndex){
                      if(prow.data[10]){
                        let FilteredItems = _.filter(ReportList, function (item) {
                          return CheckSame(prow.data[10],item.deliveryDate) ? true : false
                            && (prow.data[5] == item.contactNumber)  ? true : false
                            && (prow.data[4] == item.customerName)  ? true : false
                            && (prow.data[3] == item.customerInvoiceNumber)  ? true : false
                            && (prow.data[2] == item.orderNumber)  ? true : false
                            && CheckSame(prow.data[1],item.createdAt)  ? true : false
                            && (prow.data[0] == item.sellerType)  ? true : false;
                        });
                        console.log(prow.data[1],prow.data[5]+'--'+FilteredItems.length)
                        if(FilteredItems.length>0){
                          //console.log(FilteredItems[0].updatedAt,JSON.stringify(FilteredItems[0].deliveryStatusId))
                          data[pIndex].data[13] = "";
                          if(parseInt(FilteredItems[0].deliveryStatusId)===5){
                            data[pIndex].data[13] = FilteredItems[0].updatedAt;
                          }
                        }else{
                          data[pIndex].data[13] = "";
                        }
                      }else{
                        data[pIndex].data[13] = "";
                      }
                     
                    });
                    return buildHead(columns) + buildBody(data);
                  },  */
                }}
              />
            </Grid>

          </Grid>
          <LoadingOverlay Status={this.state.isLoading} />
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    TeamListSuccess: state.getTeamsReducer.Success,
    TeamListFailure: state.getTeamsReducer.Failure,
    TeamLists: state.getTeamsReducer.TeamList,

    AdhocListSuccess: state.getAdhocReducer.Success,
    AdhocListFailure: state.getAdhocReducer.Failure,
    AdhocList: state.getAdhocReducer.AdhocList,

    OrdersListSuccess: state.getOrderReducer.Success,
    OrdersListFailure: state.getOrderReducer.Failure,
    OrdersLists: state.getOrderReducer.OrdersList,

    SellerListSuccess: state.getSellerReducer.Success,
    SellerListFailure: state.getSellerReducer.Failure,
    SellerLists: state.getSellerReducer.SellerList,

    UserDetails: state.LoginReducer.UserDetails,
    LoaderState: state.LoaderReducer.State
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTeams: (ID) => dispatch(getTeams(ID)),
    getSeller: () => dispatch(getSeller()),
    getAdhoc: (filterObj) => dispatch(getAdhoc(filterObj)),
    getOrder: (filterObj) => dispatch(getOrder(filterObj)),
    refreshReduxStates: () => dispatch(refreshReduxStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DeliveryReport));
