import {axios , useSelector} from "./../utilities/PackagesList";
let loginURL = "http://165.22.48.6:5953/";
let orderURL = "http://165.22.48.6:5952/";
let serviceURL = "http://165.22.48.6:5954/";
let notificationURL = "http://165.22.48.6:5955/";
let sellnchill = 'https://sellnchill.com/M3';
function getBaseUrl(url,type) {
  if (url == "login") {
    axios.defaults.baseURL = loginURL;
    return url;
  }else if(type == "service"){
    axios.defaults.baseURL = serviceURL;
    return url;
  }else if(type == "sms"){
    axios.defaults.baseURL = notificationURL;
    return url;
  }else if(type == "sellnchill"){
    axios.defaults.baseURL = sellnchill;
    return url;
  }else{
    axios.defaults.baseURL = orderURL;
    return url;
  }
}
export function returnApiCallPromise(method, url, object = null, config = null, type = null) {
  
  axios.defaults.headers['Content-Type'] = 'application/json';
  axios.defaults.headers['Cache-Control'] = 'no-cache';
  axios.defaults.headers['If-Modified-Since'] = 0;
  axios.defaults.headers['company_id'] = global.CompanyID;
  //axios.defaults.headers['Access-Control-Allow-Origin'] = '*';
  //axios.defaults.baseURL = 'http://165.22.48.6:5952/';
  switch (method) {
    case 'GET':
      return axios.get(getBaseUrl(url,type));
    case 'POST':
      return axios.post(getBaseUrl(url,type), object, config);
    case 'PUT':
      return axios.put(getBaseUrl(url,type), object, config);
    case 'DELETE':
      return axios.delete(getBaseUrl(url,type), object, config);
    case 'FORMPOST': 
      const fpconfigs = {
          headers: {
            'Content-Type': 'multipart/form-data',
            //'Content-Length':JSON.stringify(object).length,
            'content-type': 'multipart/form-data'
        }
      }
      return axios.post(getBaseUrl(url,type), object, fpconfigs);
    case 'FORM': 
      const fconfigs = {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Length':JSON.stringify(object).length,
            'content-type': 'multipart/form-data'
        }
      }
      return axios.put(getBaseUrl(url,type), object, fconfigs);
  }
}

export function returnSellnchillApiPromise(method, url, object = null, config = null, type = null, token = false) {
  delete axios.defaults.headers["company_id"];
  axios.defaults.headers['Content-Type'] = 'application/json';
  axios.defaults.headers['Cache-Control'] = 'no-cache';
  axios.defaults.headers['If-Modified-Since'] = 0;
  /*axios.defaults.headers['Access-Control-Allow-Origin'] = '*';
  axios.defaults.headers['Access-Control-Allow-Credentials'] = true;
  axios.defaults.headers['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,OPTIONS';
  axios.defaults.headers['Access-Control-Allow-Headers'] = "Content-Type, Authorization, Content-Length, X-Requested-With";  
  axios.defaults.baseURL = 'https://sellnchill.com/M3';*/
  if(global.SToken){
    config = {
        headers: { Authorization: `Bearer ${global.SToken}` }
    };
  }else if(token){
    delete axios.defaults.headers["If-Modified-Since"];
    delete axios.defaults.headers["Content-Type"];
    delete axios.defaults.headers["Cache-Control"];
  }
  
  switch (method) {
    case 'GET':
      return axios.get(getBaseUrl(url,type));
    case 'POST':
      return axios.post(getBaseUrl(url,type), object, config);
    case 'PUT':
      return axios.put(getBaseUrl(url,type), object, config);
    case 'DELETE':
      return axios.delete(getBaseUrl(url,type), object, config);
  }
}
export function returnAllApiCallPromise(methodArr, urlArr, arrObject = [], config = null, type = null) {
  delete axios.defaults.headers["company_id"];
  axios.defaults.headers['Content-Type'] = 'application/json';
  axios.defaults.headers['Cache-Control'] = 'no-cache';
  axios.defaults.headers['If-Modified-Since'] = 0;
  config = {
      headers: { Authorization: `Bearer ${global.SToken}` }
  };
  let allPromise = [];
  for(let i=0; i<arrObject.length; i++){
    let object = arrObject.length ? arrObject[i] : null;
    allPromise.push(axios.post(getBaseUrl(urlArr[i],type), object, config))
  }
  return axios.all(allPromise)
}