import React from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import {connect, axios, useDispatch} from "./../utilities/PackagesList"
import {LoadingOverlay} from "./../utilities/ComponentsList";
import {validateTime} from './../utilities/AppFunctions';
// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";

// context
//import { useUserState } from "../context/UserContext";

function App(props) {
  // global
  const dispatch = useDispatch();
  const { LoginStatus, LoginDetails } = props;
  const isAuthenticated  = LoginStatus;
  
  if(LoginDetails && LoginDetails.length>0){
    localStorage.setItem("DMSloginTime",Date.now());
    global.CompanyID = LoginDetails[0].userInfo.companyId;
  }  
  

  return (
    <BrowserRouter>
      {/* <LoadingOverlay Status={props.LoaderState} /> */}
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/app/vehicles" />} />
        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/vehicles" />}
        /> 
        <PrivateRoute path="/app" component={Layout} />
        <Route path="/login" component={Login} />
        <Route component={Error} />
       {/*  <Route exact path="/" render={() => <Redirect to="/vehicles" />} /> 
        <PrivateRoute path="/vehicles" component={Layout} />
        <PrivateRoute path="/staffs" component={Layout} />
        <PrivateRoute path="/addstaff" component={Layout} />
        <PrivateRoute path="/teams" component={Layout} />
        <PrivateRoute path="/delivery" component={Layout} />
        <PrivateRoute path="/deliveries" component={Layout} />
        <PrivateRoute path="/adhocdelivery" component={Layout} />
        <PrivateRoute path="/adhocschedule" component={Layout} />
        <PrivateRoute path="/deliverystatus" component={Layout} />
        <PublicRoute path="/login" component={Login}  exact/>
        <Route component={Error} /> */}
      </Switch>
    </BrowserRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    console.debug('PrivateRoute' + JSON.stringify(isAuthenticated) );
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    console.debug('PublicRoute' + JSON.stringify(isAuthenticated) );
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/app",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
const mapStateToProps = (state) => {
  return{
    LoginStatus : state.LoginReducer.Status,
    LoginDetails: state.LoginReducer.UserDetails,
    LoaderState : state.LoaderReducer.State
  }
};
export default connect(mapStateToProps)(App);
