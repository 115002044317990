import React, { useState, useEffect, Component } from 'react';
import {
    Grid, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, makeStyles,
    Switch, FormControlLabel, IconButton, DeleteIcon,
    EditIcon, _, swal, toast, useSelector, useDispatch
} from './../../utilities/PackagesList';
import {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown,
    Buttons, LoadingOverlay
} from "./../../utilities/ComponentsList";
import { isEmptyObj } from "./../../utilities/AppFunctions";
import { UpdateVechile } from './../../api/Vechile';
const useStyles = makeStyles(theme => ({
    dialogTitle: {

    }
}))
const EditVechile = (props) => {
    const dispatch = useDispatch();
    const EditState = useSelector((state) => state.UpdateVechileReducer);
    const classes = useStyles();
    const [Editvalues, setEditValues] = useState({
        vehicleTypeId: null,
        VehicleType: '',
        name: '',
        VehicleSize: '',
        vehicleNumber: '',
        vehicleSizeId: '',
        VechileTypeSelected: '',
        isActive: false,
        modelState: false
    });
    const [errors, setErrors] = useState({});
    let ParentState = props.parentState;
    let userSelection = ParentState.SelectedIndex;
    let EditingID = ParentState.EditID;
    let vechileTypes = ParentState.VechileTypesList ? ParentState.VechileTypesList : [];
    let vehiles = ParentState.SelectingList ? ParentState.SelectingList : [];
    vehiles = _.filter(vehiles, { 'id': EditingID });  
    let vehilesize = ParentState.VechileSizeList ? ParentState.VechileSizeList : [];
    let editDetails = ParentState.EditID ? vehiles[0] : null;
    
    let VechileTypeSel = ParentState.EditID ? _.filter(vechileTypes, { 'vehicelType': editDetails.vechicelType }) : [];
    let VechileSizeSelected = ParentState.EditID ? _.filter(vehilesize, { 'vehicelSize': editDetails.vechicelSize }) : [];
    useEffect(() => {
        if (ParentState.EditID) {
            setEditValues({
                ...Editvalues,
                VehicleType: ParentState.EditID ? VechileTypeSel[0] : "",
                name: ParentState.EditID ? vehiles[0].name : "",
                VehicleSize: ParentState.EditID ? VechileSizeSelected[0] : "",
                vehicleSizeId: ParentState.EditID ? VechileSizeSelected[0].id : "",
                vehicleNumber: ParentState.EditID ? vehiles[0].vechicelNumber : "",
                VechileTypeSelected: ParentState.EditID ? VechileTypeSel[0].id : "",
                isActive: true,
                modelState: props.Status
            })
        }

    }, [ParentState.EditID])

    if (EditState.Success && !isEmptyObj(EditState.UpdateResponse)) {

        toast.success('Updated Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
        let EditedObject={
            "id": parseInt(ParentState.EditID),
            "name": Editvalues.name,
            "vechicelType": Editvalues.VehicleType.vehicelType,
            "vechicelNumber": Editvalues.vehicleNumber,
            "vechicelSize": Editvalues.VehicleSize.vehicelSize
        }
        props.parentClass.EditedVechile(EditedObject)
    } else if (EditState.Failure && !isEmptyObj(EditState.UpdateResponse)) {
        toast.error(EditState.UpdateResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
    }
    const handleChange = name => event => {
        let targetValue = event.target.value;
        setEditValues({ ...Editvalues, [name]: targetValue });
        setErrors({ ...errors, [name]: targetValue ? false : true })
    };
    const saveEdit = () => {
        let srrorObj = {};
        let errorStatus = false;
        Object.keys(Editvalues).map(function (k) {
            srrorObj[k] = Editvalues[k] ? false : true;
            errorStatus = errorStatus ? true : !Editvalues[k];
        })

        setErrors(srrorObj);

        if (Editvalues.VechileTypeSelected && Editvalues.name && Editvalues.vehicleSizeId && Editvalues.vehicleNumber) {
            let updateObject = {
                vehicleTypeId: Editvalues.VechileTypeSelected,
                name: Editvalues.name,
                vehicleSizeId: Editvalues.vehicleSizeId,
                vehicleNumber: Editvalues.vehicleNumber,
                isActive: Editvalues.isActive,
            }
            dispatch(UpdateVechile(updateObject, ParentState.EditID));
        }
    };

    return (
        <Dialog
            open={Editvalues.modelState}
            //onClose={()=>this.closeDialog()}
            fullWidth
            maxWidth="sm"
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
        >
            <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={4}>
                    <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                        <Grid
                            container
                            spacing={6}
                            direction="column"
                        >
                            <Grid item lg={12} md={12} sm={12} xl={12} xs={12} >
                                <Dropdown
                                    label={"Vehicle Type"}
                                    data={vechileTypes}
                                    getOptionLabel={option => (option.vehicelType ? option.vehicelType : "")}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    value={Editvalues.VehicleType}
                                    ItemChange={(event, newValue) => {
                                        setEditValues({
                                            ...Editvalues,
                                            VechileTypeSelected: newValue ? newValue.id : null,
                                            VehicleType: newValue ? newValue : null
                                        });
                                        setErrors({
                                            ...errors,
                                            VechileTypeSelected: newValue ? false : true,
                                            VehicleType: newValue ? false : true
                                        })
                                    }}
                                    error={errors.VechileTypeSelected}
                                    initialName={"Type"}
                                    errorMsg={"Please choose vehicle type."}
                                />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                                <InputField
                                    label="Vehicle Name"
                                    type={"text"}
                                    required={true}
                                    value={Editvalues.name}
                                    changeText={handleChange('name')}
                                    error={errors.name}
                                    initialName={"Name"}
                                    errorMsg={"Please enter vechile name."}
                                />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                                <InputField
                                    label="Vehicle Number"
                                    type={"text"}
                                    required={true}
                                    value={Editvalues.vehicleNumber}
                                    changeText={handleChange('vehicleNumber')}
                                    error={errors.vehicleNumber}
                                    initialName={"Number"}
                                    errorMsg={"Please enter vechile number."}
                                />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                                <Dropdown
                                    label={"Vehicle Size"}
                                    data={vehilesize}
                                    getOptionLabel={option => (option.vehicelSize ? option.vehicelSize : "")}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    value={Editvalues.VehicleSize}
                                    ItemChange={(event, newValue) => {
                                        setEditValues({
                                            ...Editvalues,
                                            vehicleSizeId: newValue ? newValue.id : null,
                                            VehicleSize: newValue ? newValue : null
                                        });
                                        setErrors({
                                            ...errors,
                                            vehicleSizeId: newValue ? false : true,
                                            VehicleSize: newValue ? false : true
                                        })
                                    }}
                                    error={errors.vehicleSizeId}
                                    initialName={"Size"}
                                    errorMsg={"Please choose vehicle size."}
                                />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xl={12} xs={12} >
                                <FormControlLabel
                                    value={Editvalues.isActive}
                                    control={
                                        <Switch
                                            size="medium"
                                            checked={Editvalues.isActive}
                                            onChange={()=>{setEditValues({...Editvalues,isActive:!Editvalues.isActive})}}
                                            inputProps={{ 'aria-label': 'primary' }}
                                            color="primary" />
                                    }
                                    label="Activate"
                                    fullWidth={true}
                                    labelPlacement="start"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Buttons
                        close={() => props.parentClass.CloseEdit()}
                        closeText={"Cancel"}
                        save={() => saveEdit()}
                        saveText={"Save"}
                    />
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default EditVechile;