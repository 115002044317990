const styles = theme => ({
    buttonSuccess:{
        backgroundColor: theme.palette.btns.primary,
        opacity:0.9,
        borderRadius:30,
        fontSize:"0.7rem",
        '&:hover': {
            backgroundColor: theme.palette.btns.secondary
        }
    },
    buttonCancel:{
        backgroundColor: theme.palette.btns.default,
        opacity:0.9,
        borderRadius:30,
        fontSize:"0.7rem",
        '&:hover': {
            backgroundColor: theme.palette.btns.gray
        }
    },
    ItemText:{
        color: theme.palette.text.link
    },
    largeBoldText:{
        fontSize:"0.73rem",
        fontWeight:"600",
        color:"#b26500"
    },
    boldText:{
        fontWeight:"600"
    },
    SboldText:{
        fontWeight:"500"
    },
    checkBoxSize:{
        fontSize: "0.7rem",
        size:"0.7rem"
    },
    smallBtn:{
        height:15,
        borderRadius:10,
        fontSize:"0.6rem",
        textTransform: 'none',
        float: "right",
        marginTop:5,
        backgroundColor: theme.palette.btns.default,
        opacity:0.9,
        '&:hover': {
            backgroundColor: theme.palette.btns.gray
        }
    },
    titleBar: {
        background: 'transparent',
        height: 20
    },
    boldText1: {
        fontSize: "1rem",
        fontWeight: "600",
    },
    boldText8: {
        fontSize: "0.8rem",
        fontWeight: "600",
    },
    boldText7: {
        fontSize: "0.7rem",
        fontWeight: "600",
    },
    table: {
        minWidth: "100%",
        maxWidth: "100%",
        overflowX: 'auto'
    },
    paper: {
        width: "100%",
        marginTop: theme.spacing.unit * 3,
        overflow: 'auto',
    },
    whiteText:{
        color: theme.palette.text.white
    },
    tabcellWidth:{
        maxWidth: 30,
        margin:10
    },
    complete:{
        backgroundColor: theme.palette.secondary.main,
    },
    progress:{
        backgroundColor: theme.palette.warning.main,
    },
    onLeave:{
        backgroundColor: theme.palette.info.main,
    },
    open:{
        backgroundColor: theme.palette.success.main,
    },
    others:{
        backgroundColor: theme.palette.primary.main,
    },
    TableRow: {
        fontSize: "0.7rem",
      },
    TableCell: {
          border: "1px solid rgba(224, 224, 224, .5)"
      },
  });

export default styles;