import { returnApiCallPromise , returnSellnchillApiPromise} from './ApiContent';

export function GetCustomerProduct(filterObj) {
    let patameter = "customer-product";
    if(filterObj){
        patameter = `customer-product?customerEmail=`+filterObj.Email+
        `&customerName=`+filterObj.Name+
        `&customerNumber=`+filterObj.CustomerNo+
        `&postalCode=`+filterObj.Postal+
        `&phoneNumber=`+filterObj.Phone+
        `&serialNumber=`+filterObj.Serail;
    }
    return (dispatch) => {
        dispatch({ type: 'FETCHING_CUST_PRODUCT' });
        returnApiCallPromise("GET", patameter, null, null, "service")
            .then(response => {  
                dispatch({
                    type: 'CUST_PRODUCT_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'CUST_PRODUCT_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function GetServiceStatus() {
    let patameter = "service-detail/service-status";
    return (dispatch) => {
        dispatch({ type: 'FETCHING_SERVICE_STATUS' });
        returnApiCallPromise("GET", patameter, null, null, "service")
            .then(response => {  
                dispatch({
                    type: 'SERVICE_STATUS_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'SERVICE_STATUS_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function GetTeamService(filterObj) {
    let patameter = "service";
    if(filterObj){
        patameter = `service?serviceStartDate=`+filterObj.StartDate+
        `&serviceEndDate=`+filterObj.EndDate+
        `&serviceStatusId=`+filterObj.StatusID+
        `&jobTypeId=`+filterObj.JobType+
        `&byTeam=`+filterObj.TeamID;
    }
   
    return (dispatch) => {
        dispatch({ type: 'FETCHING_GET_TEAM_SERVICE' });
        returnApiCallPromise("GET", patameter, null, null, "service")
            .then(response => {  
                dispatch({
                    type: 'GET_TEAM_SERVICE_SUCCESS',
                    data: filterObj.TeamID ? response.data.data : []
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'GET_TEAM_SERVICE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function GetService(filterObj) {
    let patameter = "service";
    if(filterObj){
        patameter = `service?serviceStartDate=`+filterObj.StartDate+
        `&serviceEndDate=`+filterObj.EndDate+
        `&serviceStatusId=`+filterObj.StatusID+
        (filterObj.JobType ? `&jobTypeId=`+filterObj.JobType : "")+
        (filterObj.TeamID ? `&byTeam=`+filterObj.TeamID : "")+
        (filterObj.PayStatus ? `&paymentStatus=`+filterObj.PayStatus : "");
    }
    return (dispatch) => {
        dispatch({ type: 'FETCHING_GET_SERVICE' });
        returnApiCallPromise("GET", patameter, null, null, "service")
            .then(response => {  
                dispatch({
                    type: 'GET_SERVICE_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'GET_SERVICE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function AssignService(AddObject) {
   
    let subURL = "service/assign-team";
    return (dispatch) => {
        returnApiCallPromise("POST", subURL, AddObject, null, "service")
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'ASSIGN_SERVICE_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'ASSIGN_SERVICE_SUCCESS',
                        data: response.data
                    })
                } 
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'ASSIGN_SERVICE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function AddService(reqObject) {
    let subURL = "service";
    return (dispatch) => {
        returnApiCallPromise("POST", subURL, reqObject, null, "service")
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'ADD_SERVICE_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'ADD_SERVICE_SUCCESS',
                        data: response.data
                    })
                }  
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'ADD_SERVICE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function UpdateService(reqObject, ID) {
   
    let subURL = "service/"+ID;
    
    return (dispatch) => {
        returnApiCallPromise("FORM", subURL, reqObject, null, "service")
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'UPDATE_SERVICE_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'UPDATE_SERVICE_SUCCESS',
                        data: response.data
                    })
                } 
            }).catch(error => {  
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'UPDATE_SERVICE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function DeleteService(ID) {
    let subURL = "service/"+ID;
    return (dispatch) => {
        returnApiCallPromise("DELETE", subURL, null, null, "service")
            .then(response => {
                if(response.data.error){
                    dispatch({
                        type: 'DELETE_SERVICE_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'DELETE_SERVICE_SUCCESS',
                        data: response.data
                    })
                } 
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'DELETE_SERVICE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function AddServiceDetail(reqObject) {
    let subURL = "service-detail";
    return (dispatch) => {
        returnApiCallPromise("POST", subURL, reqObject, null, "service")
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'ADD_SERVICE_DETAIL_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'ADD_SERVICE_DETAIL_SUCCESS',
                        data: response.data
                    })
                }  
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'ADD_SERVICE_DETAIL_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function GetAdhocService(filterObj) {
    let patameter = "adhoc-service";
    if(filterObj){
        patameter = `adhoc-service?serviceStartDate=`+filterObj.StartDate+
        `&serviceEndDate=`+filterObj.EndDate;
    }
    return (dispatch) => {
        dispatch({ type: 'FETCHING_GET_ADHOC_SERVICE' });
        returnApiCallPromise("GET", patameter, null, null, "service")
            .then(response => {  
                dispatch({
                    type: 'GET_ADHOC_SERVICE_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'GET_ADHOC_SERVICE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function AddAdhocService(reqObject) {
    let subURL = "adhoc-service";
    return (dispatch) => {
        returnApiCallPromise("POST", subURL, reqObject, null, "service")
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'ADD_ADHOC_SERVICE_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'ADD_ADHOC_SERVICE_SUCCESS',
                        data: response.data
                    })
                }  
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'ADD_ADHOC_SERVICE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function UpdateAdhocService(reqObject, ID) {
   
    let subURL = "adhoc-service/"+ID;
    
    return (dispatch) => {
        returnApiCallPromise("PUT", subURL, reqObject, null, "service")
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'UPDATE_ADHOC_SERVICE_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'UPDATE_ADHOC_SERVICE_SUCCESS',
                        data: response.data
                    })
                } 
            }).catch(error => {  
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'UPDATE_ADHOC_SERVICE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function DeleteAdhocService(ID) {
   
    let subURL = "adhoc-service/"+ID;
    return (dispatch) => {
        returnApiCallPromise("DELETE", subURL, null, null, "service")
            .then(response => {
                if(response.data.error){
                    dispatch({
                        type: 'DELETE_ADHOC_SERVICE_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'DELETE_ADHOC_SERVICE_SUCCESS',
                        data: response.data
                    })
                } 
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'DELETE_ADHOC_SERVICE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function GetJobTypes() {
    let patameter = "job-type";
    return (dispatch) => {
        dispatch({ type: 'FETCHING_JOB_TYPES' });
        returnApiCallPromise("GET", patameter, null, null, "service")
            .then(response => {  
                dispatch({
                    type: 'JOB_TYPES_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'JOB_TYPES_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function AddJobTypes(reqObject) {
    let subURL = "job-type";
    return (dispatch) => {
        returnApiCallPromise("POST", subURL, reqObject, null, "service")
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'ADD_JOB_TYPE_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'ADD_JOB_TYPE_SUCCESS',
                        data: response.data
                    })
                }  
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'ADD_JOB_TYPE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function UpdateJobTypes(reqObject, ID) {
   
    let subURL = "job-type/"+ID;
    return (dispatch) => {
        returnApiCallPromise("PUT", subURL, reqObject, null, "service")
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'UPDATE_JOB_TYPE_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'UPDATE_JOB_TYPE_SUCCESS',
                        data: response.data
                    })
                } 
            }).catch(error => {  
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'UPDATE_JOB_TYPE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function DeleteJobTypes(ID) {
    let subURL = "job-type/"+ID;
    return (dispatch) => {
        returnApiCallPromise("DELETE", subURL, null, null, "service")
            .then(response => {
                if(response.data.error){
                    dispatch({
                        type: 'DELETE_JOB_TYPE_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'DELETE_JOB_TYPE_SUCCESS',
                        data: response.data
                    })
                } 
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'DELETE_JOB_TYPE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function GetPaymentStatus() {
    let patameter = "service-detail/payment-status";
    
    return (dispatch) => {
        dispatch({ type: 'FETCHING_PAYMENT_STATUS' });
        returnApiCallPromise("GET", patameter, null, null, "service")
            .then(response => {  
                dispatch({
                    type: 'PAYMENT_STATUS_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'PAYMENT_STATUS_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function GetPaymentTypes() {
    let patameter = "payment-type";
    return (dispatch) => {
        dispatch({ type: 'FETCHING_PAYMENT_TYPES' });
        returnApiCallPromise("GET", patameter, null, null, "service")
            .then(response => {  
                dispatch({
                    type: 'PAYMENT_TYPES_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'PAYMENT_TYPES_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function AddPaymentTypes(reqObject) {
    let subURL = "payment-type";
    return (dispatch) => {
        returnApiCallPromise("POST", subURL, reqObject, null, "service")
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'ADD_PAYMENT_TYPE_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'ADD_PAYMENT_TYPE_SUCCESS',
                        data: response.data
                    })
                }  
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'ADD_PAYMENT_TYPE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function UpdatePaymentTypes(reqObject, ID) {
   
    let subURL = "payment-type/"+ID;
    
    return (dispatch) => {
        returnApiCallPromise("PUT", subURL, reqObject, null, "service")
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'UPDATE_PAYMENT_TYPE_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'UPDATE_PAYMENT_TYPE_SUCCESS',
                        data: response.data
                    })
                } 
            }).catch(error => {  
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'UPDATE_PAYMENT_TYPE_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function DeletePaymentTypes(ID) {
    let subURL = "payment-type/"+ID;
    return (dispatch) => {
        returnApiCallPromise("DELETE", subURL, null, null, "service")
            .then(response => {
                if(response.data.error){
                    dispatch({
                        type: 'DELETE_PAYMENT_TYPE_FAILURE',
                        data: response.data.message
                    })
                }else{
                    dispatch({
                        type: 'DELETE_PAYMENT_TYPE_SUCCESS',
                        data: response.data
                    })
                } 
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'DELETE_PAYMENT_TYPE_FAILURE',
                    data: errorMessage
                })
            })
    }
}

export function GetTimeSlots(filterObj) {
    let patameter = "time-slots";
    if(filterObj){
        patameter = `time-slots?teamId=`+filterObj.TeamID+
        `&date=`+filterObj.StartDate;
    }
    return (dispatch) => {
        dispatch({ type: 'FETCHING_TIME_SLOTS' });
        returnApiCallPromise("GET", patameter, null, null, "service")
            .then(response => {  
                dispatch({
                    type: 'TIME_SLOTS_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'TIME_SLOTS_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function AddTimeSlots(reqObject) {
    let subURL = "time-slots";
    return (dispatch) => {
        returnApiCallPromise("POST", subURL, reqObject, null, "service")
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'ADD_TIME_SLOTS_FAILURE',
                        data: response.data
                    })
                }else{
                    dispatch({
                        type: 'ADD_TIME_SLOTS_SUCCESS',
                        data: response.data
                    })
                }  
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'ADD_TIME_SLOTS_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function UpdateTimeSlots(reqObject, ID) {
   
    let subURL = "time-slots/"+ID;
    
    return (dispatch) => {
        returnApiCallPromise("PUT", subURL, reqObject, null, "service")
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'UPDATE_TIME_SLOTS_FAILURE',
                        data: response.data
                    })
                }else{
                    dispatch({
                        type: 'UPDATE_TIME_SLOTS_SUCCESS',
                        data: response.data
                    })
                } 
            }).catch(error => {  
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'UPDATE_TIME_SLOTS_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function DeleteTimeSlots(ID) {
    let subURL = "time-slots/"+ID;
    return (dispatch) => {
        returnApiCallPromise("DELETE", subURL, null, null, "service")
            .then(response => {
                if(response.data.error){
                    dispatch({
                        type: 'DELETE_TIME_SLOTS_FAILURE',
                        data: response.data
                    })
                }else{
                    dispatch({
                        type: 'DELETE_TIME_SLOTS_SUCCESS',
                        data: response.data
                    })
                } 
            }).catch(error => { 
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'DELETE_TIME_SLOTS_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function GetTimeSlotReport(filterObj) {
    let patameter = "time-slot-report";
    if(filterObj){
        patameter = `time-slot-report?startDate=`+filterObj.StartDate+
        `&endDate=`+filterObj.EndDate+
        `&teamId=`+filterObj.TeamID;
    }
    return (dispatch) => {
        dispatch({ type: 'FETCHING_TSLOT_REPORT' });
        returnApiCallPromise("GET", patameter, null, null, "service")
            .then(response => {  
                dispatch({
                    type: 'TSLOT_REPORT_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'TSLOT_REPORT_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function GetServiceForms() {
    let patameter = "service-form";
    return (dispatch) => {
        dispatch({ type: 'FETCHING_S_FORM' });
        returnApiCallPromise("GET", patameter, null, null, "service")
            .then(response => {  
                dispatch({
                    type: 'S_FORM_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'S_FORM_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function GetFilledServiceForms(ID) {
    let patameter = "service/forms?serviceId="+ID;
    return (dispatch) => {
        dispatch({ type: 'FETCHING_FS_FORM' });
        returnApiCallPromise("GET", patameter, null, null, "service")
            .then(response => {  
                dispatch({
                    type: 'FS_FORM_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'FS_FORM_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function GetServiceReport(filterObj) {
      let  patameter = `service/report?type=`+filterObj.Type+
      (filterObj.TeamID ? `&teamId=`+filterObj.TeamID : "")+
      (filterObj.StartDate ? `&serviceStartDate=`+filterObj.StartDate : "")+
      (filterObj.EndDate ? `&serviceEndDate=`+filterObj.EndDate : "")+
      (filterObj.Name ? `&customerName=`+filterObj.Name : "")+
      (filterObj.Brand ? `&productBrand=`+filterObj.Brand : "")+
      (filterObj.Phone ? `&phoneNumber=`+filterObj.Phone : "")+
      (filterObj.Model ? `&productModel=`+filterObj.Model : "")+
      (filterObj.Serial ? `&serialNumber=`+filterObj.Serial : "")+
      (filterObj.CustNo ? `&customerNumber=`+filterObj.CustNo : "");
    return (dispatch) => {
        dispatch({ type: 'FETCHING_SERVICE_REPORT' });
        returnApiCallPromise("GET", patameter, null, null, "service")
            .then(response => {  
                dispatch({
                    type: 'SERVICE_REPORT_SUCCESS',
                    data: response.data.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'SERVICE_REPORT_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function ExportCustomers(reqObject) {
   
    let subURL = "customer-product/upload";
    
    return (dispatch) => {
        returnApiCallPromise("FORMPOST", subURL, reqObject, null, "service")
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'UPLOAD_CUSTOMER_FAILURE',
                        data: response.data
                    })
                }else{
                    dispatch({
                        type: 'UPLOAD_CUSTOMER_SUCCESS',
                        data: response.data
                    })
                } 
            }).catch(error => {  
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'UPLOAD_CUSTOMER_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function GetSellnchillToken(reqObject) {
    const subURL = "Token"//?Password=Wong@2021&UserName=Petshop&grant_type=password";
    return (dispatch) => {
        returnSellnchillApiPromise("POST", subURL, reqObject, null, "sellnchill", true)
            .then(response => {  
                dispatch({
                    type: 'S_TOKEN_SUCCESS',
                    data: response.data
                })
            }).catch(error => {
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'S_TOKEN_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function GetCustomerOrders(reqObject) {
    let subURL = "Lookup/GetCustomerOrders";
    return (dispatch) => {
        returnSellnchillApiPromise("POST", subURL, reqObject, null, "sellnchill")
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'CUST_ORDERS_FAILURE',
                        data: response.data
                    })
                }else{
                    dispatch({
                        type: 'CUST_ORDERS_SUCCESS',
                        data: response.data
                    })
                } 
            }).catch(error => {  
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'CUST_ORDERS_FAILURE',
                    data: errorMessage
                })
            })
    }
}
export function GetCustomerOrderDetails(reqObject) {
    let subURL = "Lookup/GetCustomerOrderDetails";
    return (dispatch) => {
        returnSellnchillApiPromise("POST", subURL, reqObject, null, "sellnchill")
            .then(response => { 
                if(response.data.error){
                    dispatch({
                        type: 'CUST_ORDERS_DETAIL_FAILURE',
                        data: response.data
                    })
                }else{
                    dispatch({
                        type: 'CUST_ORDERS_DETAIL_SUCCESS',
                        data: response.data
                    })
                } 
            }).catch(error => {  
                let errorMessage = error;
                if(error.response){
                    if(error.response.data.error){
                        errorMessage = error.response.data;
                    }
                } 
                dispatch({
                    type: 'CUST_ORDERS_DETAIL_FAILURE',
                    data: errorMessage
                })
            })
    }
}