import {moment} from "./PackagesList";
export function isEmptyObj(object) {
    for (var key in object) {
        if (object.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
}
export function DMY(InpDate){
    let dateFormated = InpDate;
    if(InpDate){
        if(InpDate.includes(".")){
            dateFormated = moment(InpDate).utcOffset("+08:00").format("DD MMM YYYY");
        }else{
            dateFormated = moment(InpDate).format("DD MMM YYYY");
        }        
    }
    return dateFormated;
}
export function DMYD(InpDate){
    let dateFormated = InpDate;
    if(InpDate){
        dateFormated = moment(InpDate).format("DD-MMM-YYYY (ddd)");
    }
    return dateFormated;
}
export function DDMY(InpDate){
    let dateFormated = InpDate;
    if(InpDate){
        dateFormated = moment(InpDate).utcOffset("+08:00").format("ddd, DD MMM YYYY");
    }
    return dateFormated;
}
export function AMPM(InpTime){
    let Formated = InpTime;
    let FormatedData = null;
    if(Formated){
        FormatedData = moment(Formated, ["HH:mm"]).format("hh:mm A");
    }
    return FormatedData;
}
export function CurrentTime(){
    return moment().format("HH:mm");
}
export function validateTime(dateTime1,dateTime2){    
    var diffMs = (dateTime2 - dateTime1); // milliseconds between now & Exist
    var diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24)); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    //var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    if(parseInt(diffDays)<=0 && parseInt(diffHrs)<=8){
        return true;
    }
    return false;
}
export function ReformatDate(dateTime){    
    let Formated = dateTime;
    let FormatedData = null;
    if(Formated){
        FormatedData = moment(Formated).format("DD-MM-YYYY HH:MM");
    }
    return FormatedData;
}
export function ExportFileData(dateTime){    
    let Formated = dateTime;
    let FormatedData = null;
    if(Formated){
        FormatedData = moment(Formated).format("DD MMM YYYY hh:mm A");
    }
    return FormatedData;
}

export function dateTimeFormating(dateTime){    
    let Formated = dateTime;
    let FormatedData = null;
    if(Formated){
        //Formated = moment(Formated).utcOffset(60);
        //Formated = Formated.split('.')[0];
        FormatedData = moment(Formated).utcOffset("+08:00").format("DD MMM YYYY hh:mm A");
    }
    return FormatedData;
}
export function dateTimetoDate(dateTime){    
    let Formated = dateTime;
    let FormatedData = null;
    if(Formated){
        //Formated = Formated.split('.')[0];
        FormatedData = moment(Formated).utcOffset("+08:00").format("YYYY-MM-DD");
    }
    return FormatedData;
}
export function dateTimetoTime(dateTime){    
    let Formated = dateTime;
    if(Formated){
        //Formated = Formated.split('.')[0];
        Formated = moment(Formated).utcOffset("+08:00").format("hh:mm A");
    }
    return Formated;
}
export function todayDate(){    
    
    return moment().format("YYYY-MM-DD");
    
}
export function todayDateDMY(){    
    
    return moment().format("DD-MM-YYYY");
    
}

export function convertDateDMY(dateTime){    
    
    let FormatedData = null;
    if(dateTime){
        dateTime = dateTime.split('.')[0];
        FormatedData = moment(dateTime).format("DD-MM-YYYY");
    }
    return FormatedData;
    
}
export function isFalse(inpData){
    return !inpData ? true : false;
}

export function isBoolean(val) {
    return val === false || val === true;
 }
export function CDateMinusYMD(cDate, inpDays){
    return moment(cDate).subtract(inpDays, "days").format("YYYY-MM-DD");
}
export function CDatePlusYMD(cDate, inpDays){
    return moment(cDate).add(inpDays, "days").format("YYYY-MM-DD");
}
export function DateMinusYMD(inpDays){
    return moment().subtract(inpDays, "days").format("YYYY-MM-DD");
}
export function DateMinusDMY(inpDays){
    return moment().subtract(inpDays, "days").format("DD-MM-YYYY");
}
export function DatePlusYMD(inpDays){
    return moment().add(inpDays, "days").format("YYYY-MM-DD");
}

export function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
export function MonthNumber(date){
    if(date){
        return moment(date).month();
    }else{
        return moment().month();
    }    
}
export function WeekStartDate(InpDate){
    if(InpDate){
        return moment(InpDate).startOf('week').format('YYYY-MM-DD');
    }else{
        return moment().startOf('week').format('YYYY-MM-DD');
    }    
}
export function WeekEndDate(InpDate){
    if(InpDate){
        return moment(InpDate).endOf('week').format('YYYY-MM-DD');
    }else{
        return moment().endOf('week').format('YYYY-MM-DD');
    }    
}
export function MonthStartDate(shotMonth){
    if(shotMonth){
        return moment(moment().month(shotMonth)).startOf('month').format('YYYY-MM-DD');
    }else{
        return moment().startOf('month').format('YYYY-MM-DD');
    }    
}
export function MonthEndDate(shotMonth){
    if(shotMonth){
        return moment(moment().month(shotMonth)).endOf('month').format('YYYY-MM-DD');
    }else{
        return moment().endOf('month').format('YYYY-MM-DD');
    }    
}
export function capsFirstLetter(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}
export function GetYorN(string) {
    return string ? "Yes" : "No";
}
export function GetBooleanStatus(string) {
    let rData = "";
    if(string===true){
        rData = "Yes";
    }else if(string===false){
        rData = "No";
    }
    return rData;
}
export function AddTime2Date(date){    
    let Formated = date;
    let FormatedData = null;
    if(Formated){
        FormatedData = date+' 01:00:00';
    }
    return FormatedData;
}
export function AddTimeStamp2Date(date){    
    let Formated = date;
    let FormatedData = null;
    if(Formated){
        FormatedData = date+'T01:00:00.000Z';
    }
    return FormatedData;
}
export function RemoveTime(dateTime){    
    let FormatedData = null;
    if(dateTime){
        dateTime = dateTime.split(' ')[0];
        FormatedData = dateTime;
    }
    return FormatedData;
    
}
export function PriceFormat(amount){ 
    let  price = amount ? amount : 0.0;
    return '$'+parseFloat(price).toFixed(2);
}
export function DecimalFormat(amount){ 
    let  price = amount=='NaN' ? 0.0 : amount;
    price = amount ? amount : 0.0;
    return parseFloat(price).toFixed(2);
}
export function PartialSearch(inpData,serarchData){ 
    let  returnData = false;
    if(inpData){
        inpData = (inpData.toString()).toLowerCase();
        serarchData = serarchData ? (serarchData.toString()).toLowerCase() : "";
        returnData = inpData.includes(serarchData);
    }
    return returnData;
}
export function ServiceStatusType(Input){ 
    let status = "Not Completed";
    if(parseInt(Input)===4){
        status = "Completed"
    }/* else if(parseInt(Input)===5){
        status = "Cancelled"
    } */
    return status;
}
export function AssignedStatusType(type){ 
    let status = "Not Assigned";
    if(type){
        status = "Assigned"
    }
    return status;
}
export function nullToStr(inp){
    return (inp==null || inp =='null') ? "" : inp;
}
export function LastDayMonth(inpDate){
    return moment(inpDate).endOf('month').format("YYYY-MM-DD")
}
export function CheckSame(date1,date2){
    return moment(date1).isSame(date2);
}
export function CheckBetween(date,start,end){
    return moment(date).isBetween(start, end)
}
export function UnChangeObj(InpObj){
    return  JSON.parse(JSON.stringify(InpObj));
}
export function LowerCase(InpObj){
    return  InpObj ? (InpObj.toString()).toLowerCase() : InpObj;
}
export function getScreenWH(req){
    const { innerWidth: width, innerHeight: height } = window;
    let retData = 0;
    if(req=='w'){
        retData = width;
    }else if(req=='h'){
        retData = height;
    }
    return retData;
  }
  export function GetFullMobileNo(no){
    let returnData = "";
    if(no){
        const removeSpace = no.replace(/\s/g,'');
        returnData = "+65"+''+removeSpace.slice(-8);
    }
    return returnData;

}
export function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
 }
export function AddTimeSelincill(date){    
    let Formated = date;
    let FormatedData = null;
    if(Formated){
        FormatedData = moment(Formated, 'DD-MM-YYYY').format("YYYY-MM-DDTHH:MM:SS");
    }
    return FormatedData;
}
export function SelincillYMD(date){    
    let Formated = date;
    let FormatedData = null;
    if(Formated){
        FormatedData = moment(Formated, 'DD-MM-YYYY').format("YYYY-MM-DD");
    }
    return FormatedData;
}