export function LoginReducer(state = {}, action) {  
    let sessionData = sessionStorage.getItem('DMSLoginResponse'); 
    sessionData = sessionData ? JSON.parse(sessionData) : null; 
    switch (action.type) {
        case 'LOGIN_SUCCESS': {
            return {
                ...state,
                Status: true,
                UserDetails: action.data
            }
        }
        case 'LOGIN_FAILURE': {
            return {
                ...state,
                Status: false,
                UserDetails:action.data
            }
        }
        default: {
            if(sessionData && !state.Status){
                return {
                    ...state,
                    Status: true,
                    UserDetails: sessionData
                }
            }else{
                return state;
            }
            
        }
    }
}

export function LogOutReducer(state = {}, action) {    
    switch (action.type) {
        case 'LOGOUT_SUCCESS': {
            return {
                ...state,
                Status: true,
                UserDetails:null
            }
        }
        case 'LOGOUT_FAILURE': {
            return {
                ...state,
                Status: false,
                UserDetails:null
            }
        }
        default: {
            return state;
        }
    }
}