import React, { useState, useEffect, Component } from "react";
import {
  Grid, TextField, Button, Switch, Tooltip,
  FormControlLabel, IconButton, DeleteIcon,
  EditIcon, MUIDataTable, Autocomplete, connect,
  _, swal, toast, Typography, Checkbox, withStyles,
  SRLWrapper, SimpleReactLightbox,
  Tab, AppBar, TabContext, TabList, AddCheckIcon
} from "../../utilities/PackagesList";
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay, ButtonIcon
} from "../../utilities/ComponentsList";
import { 
  DatePlusYMD, LowerCase, LastDayMonth, 
  convertDateDMY, todayDate, PriceFormat, dateTimeFormating, 
  capsFirstLetter, PartialSearch, UnChangeObj,
  GetFullMobileNo, AMPM, DMYD, CheckSame
 } from "../../utilities/AppFunctions";
import { GetService, GetJobTypes, GetServiceStatus, GetPaymentStatus, UpdateService, GetPaymentTypes } from '../../api/Services';
import { getTeams } from '../../api/Team';
import { SendSMS } from '../../api/Notification';
import { refreshReduxStates } from '../../api/Refresh';
import { LoaderStatus } from '../../api/Loading';
import AssignTeam from "./AssignTeam";
import ViewList from "./ViewList";
import EditService from "./EditService";
import EditTeam from "./EditTeam";
import UpdateDate from "./UpdateDate";
import EditTimeSlot from "./EditTimeSlot";
import ViewForm from "./ViewForm";
import styles from "../../utilities/StylesClass";
import BaseDetails from './../../utilities/BaseDetails.json';

class ServiceStatus extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sLoading: true,
      ViewModel: false,
      ViewID: null,
      EditID: null,
      SelectedIndex: null,
      EditModel: false,
      EditTeamState: false,
      AssignTeamState: false,
      EditSlotState: false,
      UpdateDateState: false,
      SelectedServiceID : null,
      CancelID: null,
      CancelModel: false,
      RevokeID: null,
      RevokeModel: false,
      FilteredServiceList: [],
      TeamsLists: [],
      PaymentStatusLists: [],
      PaymentStatusID: "",
      PaymentStatusSel:"",
      getCalls: [1, 1, 1, 1, 1],
      postCalls: [0],
      FilterJobType: "",
      FilterJobTypeID: "",
      FilterStatusID: "2",
      FilterStatus: "",
      FilterTeamID: "",
      FilterTeam: "",
      FilterSerialNo: "",
      FilterPhone: "",
      FilterCustomerName: "",
      FilterCustomerNo: "",
      FilterCustomerEmail: "",
      FilterPostal: "",
      DateStartSelected: todayDate(),
      DateEndSelected: DatePlusYMD(15),
      JobTypeList: [],
      ServiceStatusLists: [],
      SelectedServices: [],
      columnVisible: false,
      singleService : [],
      SelectedForm:[],
      ViewFormState : false,
      PaytmentTypesList : []
    }

    let filterObj = {
      StartDate: todayDate(),
      EndDate: DatePlusYMD(15),
      StatusID: 2,
      JobType: "",
      TeamID: ""
    }

    props.getTeams(1);
    props.GetPaymentTypes();
    props.GetPaymentStatus();
    props.GetJobTypes();
    props.GetServiceStatus();
    props.GetService(filterObj);
  }
  UNSAFE_componentWillReceiveProps = (props) => {
    if (this.state.getCalls[0]) {
      if (props.ServicesSuccess) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        let CustomerNo = this.state.FilterCustomerNo;
        let CustomerEmail = this.state.FilterCustomerEmail;
        let CustomerName = this.state.FilterCustomerName;
        let SerialNo = this.state.FilterSerialNo;
        let Phone = this.state.FilterPhone;
        let Postal = this.state.FilterPostal;
        let services = props.ServicesList;
        services = _.orderBy(services, ['createdAt'], ['asc']);
        let FilteredService = _.filter(services, function (item) {
          return (CustomerEmail ? PartialSearch(item.email, CustomerEmail) : true)
            && (CustomerName ? PartialSearch(item.customerName, CustomerName) : true)
            && (SerialNo ? PartialSearch(item.serialNumber, SerialNo) : true)
            && (Phone ? PartialSearch(item.phoneNumber, Phone) : true)
            && (Postal ? PartialSearch(item.postalCode, Postal) : true)
            && (CustomerNo ? PartialSearch(item.customerNumber, CustomerNo) : true);
        });

        this.setState({
          FilteredServiceList: FilteredService,
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.ServicesFailure) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          FilteredServiceList: [],
          isLoading: loaderStatus,
          getCalls: succed
        })
      }
    }
    if (this.state.getCalls[1]) {
      if (props.JobTypesSuccess) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        let Jobs = props.JobTypesList;
        Jobs = _.orderBy(Jobs, ['jobCode'], ['asc']);
        this.setState({
          JobTypeList: Jobs,
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.JobTypesFailure) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          JobTypeList: [],
          isLoading: loaderStatus,
          getCalls: succed
        })
      }
    }
    if (this.state.getCalls[2]) {
      if (props.TeamListSuccess) {
        let succed = this.state.getCalls;
        succed[2] = 0;
        let loaderStatus = succed.includes(1);
        let TeamListData = props.TeamLists;
        let newTeam = {
            "id": 0,
            "teamName": "All",
            "vehicleNumber": "",
            "vehicelId": "",
            "assignedStaff": [],
            "teamTypeId": 0,
            "teamTypeName": "all"
        };
        TeamListData.unshift(newTeam);
        this.setState({
          TeamsLists: TeamListData,
          isLoading: loaderStatus,
          FilterTeamID: TeamListData[0].id,
          FilterTeam: TeamListData[0],
          getCalls: succed
        })
      } else if (props.TeamListFailure) {
        let succed = this.state.getCalls;
        succed[2] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          TeamsLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.getCalls[3]) {
      if (props.PaymentStatusSuccess) {
          let succed = this.state.getCalls;
          succed[3] = 0;
          let loaderStatus = succed.includes(1);
          this.setState({
              PaymentStatusLists: props.PaymentStatusList,
              getCalls: succed,
              isLoading: loaderStatus
          })
      } else if (props.PaymentStatusFailure) {
          let succed = this.state.getCalls;
          succed[3] = 0;
          let loaderStatus = succed.includes(1);
          this.setState({
              PaymentStatusLists: [],
              getCalls: succed,
              isLoading: loaderStatus
          })
      }
    }
    if (this.state.getCalls[4]) {
      if (props.PaymentTypeSuccess) {
        let succed = this.state.getCalls;
        succed[4] = 0;
        let loaderStatus = succed.includes(1);
        let PaymentLists = _.orderBy(props.PaymentTypeList, ['id'], ['asc']);
        this.setState({
          PaytmentTypesList: PaymentLists,
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.PaymentTypeFailure) {
        let succed = this.state.getCalls;
        succed[4] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          PaytmentTypesList: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.postCalls[0]) {
      if (props.UpdateServiceSuccess) {
        let updatedList = this.state.FilteredServiceList;
        updatedList.splice(this.state.SelectedIndex, 1);
        let msg = this.state.CancelID ? "Cancelled" : "Revoked";
        this.setState({
          postCalls: [0],
          isLoading: false,
          CancelModel: false,
          CancelID: null,
          RevokeID: null,
          RevokeModel: false,
          SelectedIndex: null,
          FilteredServiceList: updatedList
        }, () => {
          this.props.refreshReduxStates();
          toast.success(msg+' Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      } else if (props.UpdateServiceFailure) {
        this.setState({
          postCalls: [0],
          isLoading: false,
          CancelModel: false,
          CancelID: null,
          RevokeID: null,
          RevokeModel: false,
          SelectedIndex: null,
        }, () => {
          toast.error(props.UpdateServicMsg.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      }
    }

  }


  //View ADHOC
  viewPress = (id, index) => {

    this.setState({
      ViewModel: true,
      ViewID: id
    })
  }
  searchClick = (type) => {
    if (!this.state.DateStartSelected && !this.state.DateEndSelected &&
      !this.state.FilterCustomerNo && !this.state.FilterCustomerName &&
      !this.state.FilterCustomerEmail && !this.state.FilterSerialNo && 
      !this.state.FilterPhone && !this.state.FilterPostal) {
      toast.warning('Please choose any one filter option!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      });
      return;
    } else if (!this.state.DateStartSelected || !this.state.DateEndSelected) {
      toast.warning("Please choose start and end date", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      });
      return;
    }
    let dateStartPicked = this.state.DateStartSelected ? this.state.DateStartSelected : null;
    let dateEndPicked = this.state.DateEndSelected ? this.state.DateEndSelected : null;
    let JobTypeID = this.state.FilterJobTypeID ? this.state.FilterJobTypeID : "";
    let StatusID = this.state.FilterStatusID ? this.state.FilterStatusID : "";
    let FTeamID = this.state.FilterTeamID ? this.state.FilterTeamID : "";
    let PayStatusID = this.state.PaymentStatusID ? this.state.PaymentStatusID : "";
    let filterObj = {
      StartDate: dateStartPicked,
      EndDate: dateEndPicked,
      StatusID: StatusID,
      JobType: JobTypeID,
      TeamID: FTeamID,
      PayStatus : PayStatusID
    }

    this.setState({
      isLoading: true,
      getCalls: [1, 0, 0, 0, 0]
    }, () => {
      this.props.GetService(filterObj);
    })
  }
  clearSearchClick = (type) => {
    let filterObj = {
      StartDate: todayDate(),
      EndDate: DatePlusYMD(15),
      StatusID: parseInt(this.state.FilterStatusID),
      JobType: ""
    }
    this.setState({
      DateStartSelected: todayDate(),
      DateEndSelected: DatePlusYMD(15),
      FilterCustomerNo: "",
      FilterCustomerName: "",
      FilterCustomerEmail: "",
      FilterJobType: null,
      FilterJobTypeID: "",
      FilterTeamID: "",
      FilterTeam: null,
      FilterSerialNo: "",
      FilterPhone: "",
      FilterPostal: "",
      PaymentStatusID: "",
      PaymentStatusSel:"",
      isLoading: true,
      getCalls: [1, 0, 0, 0, 0],
    }, () => {
      this.props.GetService(filterObj);
    })
  }
  checkedItemsChange = (e, value, tableMeta, updateValue) => {
    let selectedItems = this.state.SelectedServices;
    let Arrayindex = selectedItems.indexOf(value);
    if (selectedItems.includes(value)) {
      if (Arrayindex > -1) {
        selectedItems.splice(Arrayindex, 1);
      }
    } else {
      selectedItems.push(value);
    }
    this.setState({
      SelectedServices: selectedItems
    });
  }
  CloseList = () => {
    this.setState({
      ViewModel: false,
      ViewID: null
    })
  }
  //FormView
  ViewSelectedForm=(formObject, type)=>{
   
    this.setState({
      SelectedForm : formObject,
      ViewFormState : true
    })
  }
  CloseFormView=()=>{
    this.setState({
      SelectedForm : [],
      ViewFormState : false
    })
  }
  editServicePress = (id, index) => {
    this.setState({
      EditID: id,
      EditModel: true,
      SelectedIndex: index,
    })
  }
  CloseEdit = () => {
    this.setState({
      EditModel: false,
      EditID: null,
      SelectedIndex: null,
    })
  }
  EditedService = (EditedObject) => {
    let ListData = this.state.FilteredServiceList;
    let EditedID = this.state.EditID;

    let newList = ListData.filter(function (value, index) {
      let Exist = value.id === EditedID;
      if (Exist) {
        value.jobTypeId = EditedObject.jobTypeId;
        value.jobType = EditedObject.JobType;
        value.serviceDate = EditedObject.serviceDate;
        value.fee = EditedObject.fee;
        value.complementary = EditedObject.complementary;
        value.serviceRemarks = EditedObject.serviceRemarks;
      }
      return value;
    });
    this.setState({
      EditModel: false,
      EditID: null,
      SelectedIndex: null,
      FilteredServiceList: newList
    }, () => {
      this.props.refreshReduxStates();
    })
  }
  //EDIT END
  //ASSIGN TEAM
  AssignedTeam = () => {
    let FilterListData = this.state.FilteredServiceList;
    let selectedService = this.state.SelectedServices;
    let FilterList = FilterListData.filter(function (value, index) {
      let ItemExist = selectedService.includes(value.id);
      return !ItemExist;
    })

    this.setState({
      AssignTeamState: false,
      SelectedServices: [],
      FilteredServiceList: FilterList,
      getCalls: [0, 0, 0, 0, 0]
    }, () => {
      this.props.refreshReduxStates();
    })
  }
  CloseAssign = () => {
    this.setState({
      AssignTeamState: false,
      SelectedServices: []
    })
  }
  //ASSIGN TEAM END
  //ASSIGN TEAM
  editTeamPress = (id, index) => {
    this.setState({
      EditID: id,
      SelectedIndex: index,
      EditTeamState: true
    })
  }
  EditedTeam = (EditedObject) => {
    let FilterListData = this.state.FilteredServiceList;
    let EditedID = this.state.EditID;
    let newList = FilterListData.filter(function (value, index) {
      let orderExist = value.id === EditedID;
      if (orderExist) {
        value.teamId = EditedObject.teamId;
        value.teamName = EditedObject.teamName;
        value.teamDate = EditedObject.teamDate;
        value.timeSlots = EditedObject.timeSlots;
      }
      return value;
    })

    this.setState({
      EditID: null,
      EditTeamState: false,
      SelectedIndex: null,
      FilteredServiceList: newList,
      getCalls: [0, 0, 0, 0, 0]
    }, () => {
      this.props.refreshReduxStates();
    })
  }
  CloseEditTeam = () => {
    this.setState({
      EditID: null,
      EditTeamState: false,
      SelectedIndex: null,
    })
  }
  //ASSIGN TEAM END
  editDate = (id, index) => {
    this.setState({
      UpdateDateState: true,
      EditID: id,
      SelectedIndex: index
    })
  }
  CloseUpdateDate = () => {
    this.setState({
      UpdateDateState: false,
      EditID: null,
      SelectedIndex: null,
    })
  }
  UpdatedDate = (EditedObject) => {
    let FilterListData = this.state.FilteredServiceList;
    let EditedID = this.state.EditID;
    let newList = FilterListData.filter(function (value, index) {
      let orderExist = value.id === EditedID;
      if (orderExist) {
        value.balancePaidDate = EditedObject.balancePaidDate;
        value.paymentStatusName = EditedObject.paymentStatusName;
        value.paymentStatusId = EditedObject.paymentStatusId;
      }
      return value;
    })
    this.setState({
      EditID: null,
      SelectedIndex: null,
      UpdateDateState: false,
      FilteredServiceList: newList,
    }, () => {
      this.props.refreshReduxStates();
    })
  }
  revokePress = (id, index) => {
    this.setState({
      RevokeID: id,
      RevokeModel: true,
      SelectedIndex: index,
    })
  }
  RevokeClose = () => {
    this.setState({
      RevokeModel: false,
      RevokeID: null,
      SelectedIndex: null,
    })
  }

  RevokeConfirm = () => {
    this.setState({
      isLoading: true,
      postCalls:[1]
    }, () => {
      var formObj = new FormData();
      formObj.append('serviceStatusId', 1);
      formObj.append('teamId', 0);
      formObj.append('teamDate', 0);
      formObj.append('timeSlotId', 0);
      this.props.UpdateService(formObj,this.state.RevokeID);
    })
  }
  //Assign Slot
  editSchedulePress = (id, index) => {
    let FilterListData = UnChangeObj(this.state.FilteredServiceList);
    let ItemSelected = _.filter(FilterListData,{ 'id': id });
    this.setState({
      EditSlotState: true,
      SelectedServiceID: id,
      singleService : ItemSelected
    })
  }
  EditedSlot = (EditedObject, selectedSlots, SlotDate) => {
    let FilterListData = this.state.FilteredServiceList;
    let SelectedServiceID = this.state.SelectedServiceID;
    let MobileNo = "";
    let newList = FilterListData.filter(function (value, index) {
      let orderExist = value.id === SelectedServiceID;
      if (orderExist) {
        let existDate = value.teamDate;
        let existTeamID = value.teamId;
        value.teamId = EditedObject.teamId;
        value.teamName = EditedObject.teamName;
        value.teamDate = EditedObject.teamDate;
        value.serviceDate = EditedObject.serviceDate;
        value.timeSlots = EditedObject.timeSlots;
        if(value.phoneNumber && !CheckSame(existDate,EditedObject.teamDate)){
          MobileNo =  GetFullMobileNo(value.phoneNumber);
        }
      }
      return value;
    })
      let Slots = "";
      selectedSlots.map((row, index) => {
        const times = row.split("-");
        if(index>0){
          Slots += '/'+AMPM(times[0]);
        }else{
          Slots += AMPM(times[0]);
        }
      })
    let sortedTimes = selectedSlots.sort(function (a, b) {
        const atime = a.split("-");
        const btime = b.split("-");
        return new Date('1970/01/01 ' + atime[0]) - new Date('1970/01/01 ' + btime[0]);
      });
    if(sortedTimes.length>0){
      const tms = sortedTimes[0].split("-");
        Slots = AMPM(tms[0]);
    }
    let smsObj=[{
      "type": "sms",
      "body": "Your appointment for instrument tuning/servicing is confirmed on "+DMYD(SlotDate)+" "+Slots+". Any changes, pls call 62439555 in advance to reschedule. Thank you.",
      "toMobileNo": MobileNo
    }];
    this.setState({
      EditSlotState: false,
      SelectedServiceID : null,
      FilteredServiceList: newList,
      singleService : [],
      getCalls: [0, 0, 0, 0, 0]
    }, () => {
      if(MobileNo){
       // this.props.SendSMS(smsObj);
      }else{
        this.props.refreshReduxStates();
      }
      
    })
  }
  CloseEditSlot = () => {
    this.setState({
      EditSlotState: false,
      SelectedServiceID : null,
      singleService : []
    })
  }
  //End
  cancelPress = (id, index) => {
    this.setState({
      CancelID: id,
      CancelModel: true,
      SelectedIndex: index,
    })
  }
  CancelClose = () => {
    this.setState({
      CancelModel: false,
      CancelID: null,
      SelectedIndex: null,
    })
  }

  CancelConfirm = () => {
    this.setState({
      isLoading: true,
      postCalls:[1]
    }, () => {
      var formObj = new FormData();
      formObj.append('serviceStatusId', 5);
      this.props.UpdateService(formObj,this.state.CancelID);
    })
  }
  handleChange = (event, newValue) => {
    let filterObj = {
      StartDate: todayDate(),
      EndDate: DatePlusYMD(15),
      StatusID: parseInt(newValue),
      JobType: "",
      TeamID: ""
    }
    this.setState({
      FilterJobType: "",
      FilterJobTypeID: "",
      FilterStatusID: newValue,
      FilterStatus: "",
      FilterCustomerName: "",
      FilterCustomerNo: "",
      FilterCustomerEmail: "",
      FilterTeamID: "",
      FilterTeam: null,
      FilterSerialNo: "",
      FilterPhone: "",
      FilterPostal: "",
      DateStartSelected: todayDate(),
      DateEndSelected: DatePlusYMD(15),
      columnVisible: (newValue == "4" || newValue == "5") ? true : false,
      getCalls: [1, 0, 0, 0, 0],
      isLoading: true
    }, () => {
      this.props.GetService(filterObj);
    })

  }
  MoveToTSlotReport = () => {
    this.props.history.push('/app/timeslotreport')
  }
  render() {
    const { classes } = this.props;
    const Lightoptions = {
      settings: {
        overlayColor: "rgba(0, 0, 0, 0.9)",
        autoplaySpeed: 1500,
        transitionSpeed: 900,
        hideControlsAfter: true
      }

    };
    const columns = [
      {
        name: "id",
        label: "Select",
        options: {
          sort: false,
          filter: false,
          print: false,
          display: false,//(this.state.FilterStatusID=="5"),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                <Checkbox
                  checked={this.state.SelectedServices ? (this.state.SelectedServices).includes(value) : false}
                  onChange={(e) => this.checkedItemsChange(e, value, tableMeta, updateValue)}
                  name="checkedB"
                  color="primary"
                  size="small"
                />
              </Grid>
            )
          }
        }
      },
      {
        name: "customerNumber",
        label: "Cust Number",
        options: {
          filter: false,
          sort: true,
          display: true,
          print: true
        }
      },
      {
        name: "customerName",
        label: "Cust Name",
        options: {
          filter: false,
          sort: true,
          display: true,
          print: true
        }
      },
      {
        name: "address",
        label: "Address",
        options: {
          filter: false,
          sort: true,
          display: true,
          print: true
        }
      },
      {
        name: "postalCode",
        label: "Postal",
        options: {
          filter: false,
          sort: true,
          display: true,
          print: true
        }
      },
      {
        name: "phoneNumber",
        label: "Phone",
        options: {
          filter: false,
          sort: true,
          display: true,
          print: true
        }
      },
      {
        name: "email",
        label: "Email",
        options: {
          filter: false,
          sort: true,
          display: false,
          print: false
        }
      },
      {
        name: "items",
        label: "Product Items",
        options: {
          filter: false,
          sort: false,
          display: false,
          print: false
        }
      },
      {
        name: "brand",
        label: "Brand",
        options: {
          filter: false,
          sort: true,
          display: true
        }
      },
      {
        name: "model",
        label: "Model",
        options: {
          filter: false,
          sort: true,
          display: true
        }
      },
      {
        name: "serialNumber",
        label: "Serial No",
        options: {
          filter: false,
          sort: true,
          display: true
        }
      },
      {
        name: "serviceDate",
        label: "Service Date",
        options: {
          filter: false,
          sort: true,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item lg={12}>
                <Typography>{convertDateDMY(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "timeSlots",
        label: "Time Slots",
        options: {
          filter: false,
          sort: false,
          print: true,
          display: true,
          setCellProps: () => ({ style: { minWidth: "100px",maxWidth: "100px" } }),
          customBodyRender: (value, tableMeta, updateValue) => {
            let validateData = value ? value : [];
            return (
              <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                   {validateData.map((itemVal, index) => (
                      <Typography>{itemVal.startTime} - {itemVal.endTime}</Typography>
                    ))}
              </Grid>
            )
          }
        }
      },
      {
        name: "updatedAt",
        label: "Canceled Date",
        options: {
          filter: false,
          sort: true,
          print: this.state.FilterStatusID=='5',
          display: this.state.FilterStatusID=='5',
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item lg={12}>
                <Typography>{dateTimeFormating(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "completedAt",
        label: "Completed Date",
        options: {
          filter: false,
          sort: true,
          print: this.state.FilterStatusID=='4',
          display: this.state.FilterStatusID=='4',
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item lg={12}>
                <Typography>{dateTimeFormating(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "fee",
        label: "Fee",
        options: {
          filter: false,
          sort: true,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item lg={12}>
                <Typography>{PriceFormat(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "jobCode",
        label: "Job Code",
        options: {
          filter: false,
          sort: true,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item lg={12}>
                <Typography>{capsFirstLetter(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "teamName",
        label: "Team",
        options: {
          filter: false,
          sort: true,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item lg={12}>
                <Typography>{capsFirstLetter(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "serviceRemarks",
        label: "Remarks",
        options: {
          filter: false,
          sort: false,
          print: true,
          display: true,//!this.state.columnVisible,
        }
      },
      {
        name: "paymentStatusName",
        label: "Payment Status",
        options: {
          filter: false,
          sort: false,
          print: false,
          display: false,//this.state.columnVisible,
        }
      },
      {
        name: "images",
        label: "Images",
        options: {
          filter: false,
          sort: false,
          print: this.state.columnVisible,
          display: this.state.columnVisible,
          setCellProps: () => ({ style: { minWidth: "100px" } }),
          customBodyRender: (value, tableMeta, updateValue) => {
            let validateData = value ? value : [];
            let URL = BaseDetails.baseServiceURL;
            return (
              <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                <SimpleReactLightbox>
                  <SRLWrapper options={Lightoptions}>
                    {validateData.map((itemVal, index) => (
                      <a href={URL + '' + itemVal}>
                        <img
                          src={URL + '' + itemVal}
                          alt={itemVal}
                          style={{
                            position: 'relative',
                            marginLeft: index == 0 ? index : index - 15,
                            width: 20, height: 20,
                            borderRadius: 20
                          }}
                        />
                      </a>
                    ))}
                  </SRLWrapper>
                </SimpleReactLightbox>
              </Grid>
            )
          }
        }
      },
      {
        name: "signature",
        label: "Signature",
        options: {
          filter: false,
          sort: false,
          print: this.state.columnVisible,
          display: this.state.columnVisible,
          customBodyRender: (value, tableMeta, updateValue) => {
            let URL = BaseDetails.baseServiceURL + '' + value;
            return (
              <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                {value ?
                  <img src={URL} alt="Signature"
                    style={{
                      width: 100, height: 50
                    }}
                  />
                  : null}
              </Grid>
            )
          }
        }
      },
      {
        name: "",
        label: "Actions",
        options: {
          filter: false,
          sort: false,
          print: false,
          display: true,
          setCellProps: () => ({ style: { minWidth: "120px", maxWidth: "120px" } }),
          customBodyRender: (value, tableMeta, updateValue) => {
            let  ParialStatus = tableMeta.rowData[18]==='Partially Paid' ? true : false;
            return (
              <ButtonIcon
                //EditTitle={this.state.FilterStatusID == "2" ? "Edit Team" : null}
                EditTitle1={this.state.FilterStatusID == "2" ? "Edit Service" : null}
                CancelTitle={(this.state.FilterStatusID == "2" || this.state.FilterStatusID == "3") ? "Cancel Service" : null}
                ViewTitle={this.state.columnVisible ? "View Service" : null}
                EditDateTitle={ParialStatus ? "Edit Balance Paid Date" : null}
                RestoreTitle={this.state.FilterStatusID == "5" ? "Restore Service" : null}
                ScheduleTitle={this.state.FilterStatusID == "2" ? "Edit Team" : null}
                Schedule={() => this.editSchedulePress(tableMeta.rowData[0], tableMeta.rowIndex)}
               // Edit={() => this.editTeamPress(tableMeta.rowData[0], tableMeta.rowIndex)}
                Edit1={() => this.editServicePress(tableMeta.rowData[0], tableMeta.rowIndex)}
                View={() => this.viewPress(tableMeta.rowData[0], tableMeta.rowIndex)}
                EditDate={() => this.editDate(tableMeta.rowData[0], tableMeta.rowIndex)}
                Cancel={() => this.cancelPress(tableMeta.rowData[0], tableMeta.rowIndex)}
                Restore={() => this.revokePress(tableMeta.rowData[0], tableMeta.rowIndex)}
              />
            )
          }
        }
      }
    ];


    return (
      <>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <TabContext value={this.state.FilterStatusID}>
              <AppBar position="static" color="default">
                <TabList onChange={(event, newValue) => this.handleChange(event, newValue)} aria-label="">
                  <Tab label="Ready for Service" value="2" />
                  <Tab label="In Progress" value="3" />
                  <Tab label="Cancelled" value="5" />
                  <Tab label="Completed" value="4" />
                </TabList>
              </AppBar>
              <Grid container item spacing={1} lg={12} md={12} sm={12} xl={12} xs={12} direction={"row"} style={{ marginTop: 20 }}>
                <Grid item lg={2} md={2} sm={2} xl={2} xs={2}>
                  <InputField
                    label={"Start Date"}
                    type={"date"}
                    required={true}
                    value={this.state.DateStartSelected}
                    changeText={(data) => {
                      this.setState({
                        DateStartSelected: data ? data.target.value : null,
                        DateEndSelected: data ? LastDayMonth(data.target.value) : null
                      })
                    }}
                    // error={Createerrors.Date}
                    initialName={"Start Date"}
                    errorMsg={"Please enter date."}
                  />
                </Grid>
                <Grid item lg={2} md={2} sm={2} xl={2} xs={2}>
                  <InputField
                    label={"End Date"}
                    type={"date"}
                    required={true}
                    value={this.state.DateEndSelected}
                    changeText={(data) => { this.setState({ DateEndSelected: data ? data.target.value : null }) }}
                    // error={Createerrors.Date}
                    initialName={"End Date"}
                    errorMsg={"Please enter date."}
                  />
                </Grid>
                <Grid item lg={2} md={2} sm={2} xl={2} xs={2} >
                  <Dropdown
                    label={"Select Team"}
                    data={this.state.TeamsLists}
                    getOptionLabel={option => (option.teamName ? option.teamName : "")}
                    getOptionSelected={(option, value) => option.id === value.id}
                    value={this.state.FilterTeam}
                    ItemChange={(event, newValue) => {
                      this.setState({
                        FilterTeamID: newValue ? newValue.id : null,
                        FilterTeam: newValue ? newValue : null
                      })
                    }}
                    //error={Createerrors.TeamSelected}
                    initialName={"Team"}
                    errorMsg={"Please choose team."}
                  />
                </Grid>
                <Grid item lg={2} md={2} sm={2} xl={2} xs={2} >
                  <Dropdown
                    label={"Job Type"}
                    data={this.state.JobTypeList}
                    getOptionLabel={option => (option.jobCode ? option.jobCode : "")}
                    getOptionSelected={(option, value) => option.jobTypeId === value.jobTypeId}
                    value={this.state.FilterJobType}
                    ItemChange={(event, newValue) => {
                      this.setState({
                        FilterJobTypeID: newValue ? newValue.jobTypeId : null,
                        FilterJobType: newValue
                      })
                    }}
                    //error={this.state.FilterJobTypeID}
                    initialName={"Job Type"}
                    errorMsg={"Please choose job type."}
                  />
                </Grid>
                <Grid item lg={2} md={2} sm={2} xl={2} xs={2} >
                  <Dropdown
                    label={"Payment Status"}
                    data={this.state.PaymentStatusLists}
                    getOptionLabel={option => (option.paymentStatusName ? option.paymentStatusName : "")}
                    getOptionSelected={(option, value) => option.paymentStatusId === value.paymentStatusId}
                    value={this.state.PaymentStatusSel}
                    ItemChange={(event, newValue) => {
                      this.setState({
                        PaymentStatusID: newValue ? newValue.paymentStatusId : null,
                        PaymentStatusSel: newValue,
                      })
                    }}
                    //error={this.state.PaymentStatusID}
                    initialName={"Payment Status"}
                    errorMsg={"Please choose payment status."}
                  />
                </Grid>
                <Grid item lg={2} md={2} sm={2} xl={2} xs={2} >
                  <InputField
                    label={"Customer Name"}
                    type={"text"}
                    required={false}
                    value={this.state.FilterCustomerName}
                    changeText={(data) => this.setState({ FilterCustomerName: data ? data.target.value : null })}
                    // error={Createerrors.Date}
                    initialName={"Customer Name"}
                    errorMsg={"Please enter customer name."}
                  />
                </Grid>
                
              </Grid>
              <Grid container item spacing={1} lg={12} md={12} sm={12} xl={12} xs={12} direction={"row"} style={{ marginTop: 20 }}>
              <Grid item lg={2} md={2} sm={2} xl={2} xs={2} >
                  <InputField
                    label={"Customer Number"}
                    type={"text"}
                    required={false}
                    value={this.state.FilterCustomerNo}
                    changeText={(data) => this.setState({ FilterCustomerNo: data ? data.target.value : null })}
                    // error={Createerrors.Date}
                    initialName={"Customer Number"}
                    errorMsg={"Please enter customer number."}
                  />
                </Grid>
                <Grid item lg={2} md={2} sm={2} xl={2} xs={2} >
                  <InputField
                    label={"Customer Email"}
                    type={"text"}
                    required={false}
                    value={this.state.FilterCustomerEmail}
                    changeText={(data) => this.setState({ FilterCustomerEmail: data ? data.target.value : null })}
                    // error={Createerrors.Date}
                    initialName={"Customer Email"}
                    errorMsg={"Please enter customer email."}
                  />
                </Grid>
                <Grid item lg={2} md={2} sm={2} xl={2} xs={2} >
                  <InputField
                    label={"Serial Number"}
                    type={"text"}
                    required={false}
                    value={this.state.FilterSerialNo}
                    changeText={(data) => this.setState({ FilterSerialNo: data ? data.target.value : null })}
                    // error={Createerrors.Date}
                    initialName={"Serial Number"}
                    errorMsg={"Please enter serial number."}
                  />
                </Grid>
                <Grid item lg={2} md={2} sm={2} xl={2} xs={2} >
                  <InputField
                    label={"Phone Number"}
                    type={"text"}
                    required={false}
                    value={this.state.FilterPhone}
                    changeText={(data) => this.setState({ FilterPhone: data ? data.target.value : null })}
                    // error={Createerrors.Date}
                    initialName={"Phone Number"}
                    errorMsg={"Please enter phone number."}
                  />
                </Grid>
                <Grid item lg={2} md={2} sm={2} xl={2} xs={2} >
                  <InputField
                    label={"Postal Code"}
                    type={"text"}
                    required={false}
                    value={this.state.FilterPostal}
                    changeText={(data) => this.setState({ FilterPostal: data ? data.target.value : null })}
                    // error={Createerrors.Date}
                    initialName={"Postal Code"}
                    errorMsg={"Please enter postal code."}
                  />
                </Grid>
                <Grid item lg={1} md={1} sm={1} xl={1} xs={1}>
                  <Button
                    onClick={() => this.clearSearchClick(1)}
                    className={classes.buttonCancel}
                    variant="contained" color="primary">
                    Clear
                  </Button>
                </Grid>
                <Grid item lg={1} md={1} sm={1} xl={1} xs={1}>
                  <Button
                    onClick={() => this.searchClick(1)}
                    className={classes.buttonSuccess}
                    variant="contained" color="primary">
                    Search
                  </Button>
                </Grid>
              </Grid>
              {/* <Grid container item spacing={1} lg={12} md={12} sm={12} xl={12} xs={12} direction={"row"} style={{ marginTop: 10 }}>
                <Grid item md={1} >
                  <Button
                    onClick={() => this.clearSearchClick(1)}
                    className={classes.buttonCancel}
                    variant="contained" color="primary">
                    Clear
                  </Button>
                </Grid>
                <Grid item md={1} >
                  <Button
                    onClick={() => this.searchClick(1)}
                    className={classes.buttonSuccess}
                    variant="contained" color="primary">
                    Search
                  </Button>
                </Grid>
              </Grid> */}
             {/*  <Grid item lg={12} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={() => this.MoveToTSlotReport()}
                className={classes.buttonSuccess}
                variant="contained" color="primary">
                Schedule Delivery
              </Button>
            </Grid> */}
              <Grid item lg={12} sm={12} xl={12} xs={12} style={{ marginTop: 20 }}>
                <MUIDataTable
                  title=""
                  data={this.state.FilteredServiceList}
                  columns={columns}
                  options={{
                    downloadOptions: {
                      filename: "service_status.csv",
                      filterOptions:{
                        useDisplayedColumnsOnly : true,
                        useDisplayedRowsOnly : true
                      }
                    },
                    onDownload: (buildHead, buildBody, columns, data) => {
                      data.forEach(function (prow, pIndex) {
                        prow.data.forEach(function (crow, cindex) {
                          let check = Array.isArray(crow);
                          if (check) {
                            let items = "";
                            crow.forEach(function (val, index) {
                              items += val.startTime+' - '+val.endTime + ',';
                            });
                            data[pIndex].data[cindex] = items;
                          } else {
                            data[pIndex].data[cindex] = crow;
                          }
                        })
                      });
                      return buildHead(columns) + buildBody(data);
                    },
                    sort: true,
                    selectableRows: false,
                    print: false,
                    download: true,
                    print: true,
                    viewColumns: false,
                    responsive: true,
                    filter: false,
                    /* customToolbar: () => {
                      return (
                        <CustomToolbar icontype={"time"} title={"Time Slots"} iconOnPress={() => this.MoveToTSlotReport()} />
                      );
                    } */
                  }}
                />
              </Grid>

            </TabContext>
          </Grid>
          <AlertDialog
            Status={this.state.CancelModel}
            DialogConfirm={() => { this.CancelConfirm() }}
            DialogCancel={() => { this.CancelClose() }}
            Title={"Cancel Service"}
            Content={"Are you sure, you want to cancel the service ?"}
          />
          <AlertDialog
            Status={this.state.RevokeModel}
            DialogConfirm={() => { this.RevokeConfirm() }}
            DialogCancel={() => { this.RevokeClose() }}
            Title={"Restore Service"}
            Content={"Are you sure, you want to restore the selected service ?"}
          />
          {this.state.ViewModel ? <ViewList
            Title={"Service Details"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.ViewModel}
            ModelCancel={this.CloseList}
          /> : null}
          {this.state.AssignTeamState ? <AssignTeam
            Title={"Assign Team"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.AssignTeamState}
            ModelConfirm={this.AssignedTeam}
            ModelCancel={this.CloseAssign}
          /> : null}
           {this.state.EditSlotState ? <EditTimeSlot
            Title={"Edit Team"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.EditSlotState}
            ModelConfirm={this.EditedSlot}
            ModelCancel={this.CloseEditSlot}
          /> : null}
          {this.state.EditTeamState ? <EditTeam
            Title={"Edit Team"}
            parentClass={this}
            data={this.state.FilteredServiceList}
            parentState={this.state}
            Status={this.state.EditTeamState}
            ModelConfirm={this.EditedTeam}
            ModelCancel={this.CloseEditTeam}
          /> : null}
          {this.state.UpdateDateState ? <UpdateDate
            Title={"Update Balance Paid Date"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.UpdateDateState}
            ModelConfirm={this.UpdatedDate}
            ModelCancel={this.CloseUpdateDate}
          /> : null}
          {this.state.EditModel ? <EditService
            Title={"Edit Service"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.EditModel}
            ModelConfirm={this.EditedService}
            ModelCancel={this.CloseEdit}
          /> : null}
           {this.state.ViewFormState ? <ViewForm
            Title={"View Form"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.ViewFormState}
           // ModelConfirm={this.UpdatedDate}
            ModelCancel={this.CloseFormView}
          /> : null}
          <LoadingOverlay Status={this.state.isLoading} />
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ServicesSuccess: state.GetServiceReducer.Success,
    ServicesFailure: state.GetServiceReducer.Failure,
    ServicesList: state.GetServiceReducer.Services,

    JobTypesSuccess: state.GetJobTypesReducer.Success,
    JobTypesFailure: state.GetJobTypesReducer.Failure,
    JobTypesList: state.GetJobTypesReducer.JobTypes,

    ServiceStatusSuccess: state.GetServiceStatusReducer.Success,
    ServiceStatusFailure: state.GetServiceStatusReducer.Failure,
    ServiceStatusList: state.GetServiceStatusReducer.ServiceStatus,

    UpdateServiceSuccess: state.UpdateServiceReducer.Success,
    UpdateServiceFailure: state.UpdateServiceReducer.Failure,
    UpdateServicMsg: state.UpdateServiceReducer.UpdateResponse,

    TeamListSuccess: state.getTeamsReducer.Success,
    TeamListFailure: state.getTeamsReducer.Failure,
    TeamLists: state.getTeamsReducer.TeamList,

    PaymentStatusSuccess: state.GetPaymentStatusReducer.Success,
    PaymentStatusFailure: state.GetPaymentStatusReducer.Failure,
    PaymentStatusList: state.GetPaymentStatusReducer.PaymentStatus,

    PaymentTypeSuccess: state.GetPaymentTypesReducer.Success,
    PaymentTypeFailure: state.GetPaymentTypesReducer.Failure,
    PaymentTypeList: state.GetPaymentTypesReducer.PaymentTypes,

    UserDetails: state.LoginReducer.UserDetails,
    LoaderState: state.LoaderReducer.State
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetService: (filterObj) => dispatch(GetService(filterObj)),
    GetPaymentTypes: () => dispatch(GetPaymentTypes()),
    getTeams: (ID) => dispatch(getTeams(ID)),
    GetServiceStatus: () => dispatch(GetServiceStatus()),
    GetPaymentStatus: () => dispatch(GetPaymentStatus()),
    UpdateService: (reqObj, ID) => dispatch(UpdateService(reqObj, ID)),
    GetJobTypes: () => dispatch(GetJobTypes()),
    SendSMS: (requObj) => dispatch(SendSMS(requObj)),
    refreshReduxStates: () => dispatch(refreshReduxStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ServiceStatus));
