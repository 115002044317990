import React, { useState, useEffect, Component } from 'react';
import {
    Grid, Dialog, GridList, DialogContent,
    DialogActions, DialogTitle, makeStyles,
    Typography, Divider, IconButton, Button,
    EditIcon, _, swal, toast, useSelector, useDispatch,
    Table, TableBody, TableCell, TableHead,
    TableContainer, TableRow, Paper, withStyles,
    Tooltip, Checkbox
} from '../../utilities/PackagesList';
import {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown,
    Buttons, LoadingOverlay
} from "../../utilities/ComponentsList";
import { LastDayMonth, isEmptyObj, UnChangeObj, DDMY, todayDate, DatePlusYMD, AddTimeStamp2Date } from "../../utilities/AppFunctions";
import { UpdateService, GetTeamService, GetTimeSlotReport } from '../../api/Services';
import { refreshReduxStates } from '../../api/Refresh';
import useStyles from "../../utilities/Styles";

const EditTimeSlot = (props) => {
    let isLoading = true;
    let ParentState = props.parentState;
    let serviceID = ParentState.SelectedServiceID;
    let ServiceItemSeleted = ParentState.singleService;
    let TeamListData = ParentState.TeamsLists ? ParentState.TeamsLists : [];
    const dispatch = useDispatch();
    const classes = useStyles();
    const [EditValues, setEditValues] = React.useState({
        StartDate: todayDate(),
        EndDate: DatePlusYMD(7),
        TeamSelectedID: "",
        TeamSelected: "",
        selectedTimeSlots: [],
        SlotsSelected: [],
        DateSelected: "",
        teamNameSelected: "",
        teamIDSelected: ""
    });
    const [EditErrors, setEditErrors] = useState({});
    useEffect(() => {
        if (serviceID) {
            let slotsAssigned = ServiceItemSeleted[0].timeSlots;
            let existSlots = [];
            let selectedSlots = [];
            slotsAssigned.map((data, index) => {
                let Items = {
                    slots: slotsAssigned,
                    startTime: data.startTime,
                    endTime: data.endTime,
                    slotID: data.id,
                    teamName: ServiceItemSeleted[0].teamName,
                    Date: ServiceItemSeleted[0].teamDate
                };
                existSlots.push(Items);
                selectedSlots.push(data.startTime + '-' + data.endTime);
            });

            setEditValues({
                ...EditValues,
                selectedTimeSlots: existSlots,
                SlotsSelected: selectedSlots,
                DateSelected: ServiceItemSeleted[0].teamDate,
                teamNameSelected: ServiceItemSeleted[0].teamName,
                teamIDSelected: ServiceItemSeleted[0].teamId,
                TeamSelectedID: TeamListData.length > 0 ? TeamListData[0].id : "",
                TeamSelected: TeamListData.length > 0 ? TeamListData[0] : ""
            })
            let filterObj = {
                StartDate: todayDate(),
                EndDate: DatePlusYMD(7),
                TeamID: ""
            }
            dispatch(GetTimeSlotReport(filterObj));
        }
    }, [serviceID]);
    const UpdateState = useSelector((state) => state.UpdateServiceReducer);
    const TimeSlotsResponse = useSelector((state) => state.GetTimeSlotReportReducer);
    let TimeSlotsList = TimeSlotsResponse.TSlotReport ? TimeSlotsResponse.TSlotReport : [];
    if (TimeSlotsList.length > 0) {
        isLoading = false;
    }
    if (UpdateState.Success && !isEmptyObj(UpdateState.UpdateResponse)) {
        toast.success('Updated Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
        let StSlots = [];
        let submitData = EditValues.selectedTimeSlots;
        let selectedSlots = EditValues.SlotsSelected;
        submitData.map((item) => {
            StSlots.push({ id: item.slotID, startTime: item.startTime, endTime: item.endTime })
        });
        let EditedObject = {
            teamId: EditValues.teamIDSelected,
            teamName: EditValues.teamNameSelected,
            teamDate: EditValues.DateSelected,
            timeSlots: StSlots,
            serviceDate: AddTimeStamp2Date(EditValues.DateSelected),
        }
        
        props.ModelConfirm(EditedObject, selectedSlots, EditValues.DateSelected)
    } else if (UpdateState.Failure && !isEmptyObj(UpdateState.UpdateResponse)) {
        toast.error(UpdateState.UpdateResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
        dispatch(refreshReduxStates());
    }
    const submitForm = () => {
        let submitData = EditValues.selectedTimeSlots;
        if (submitData.length > 0) {

            let ids = submitData.map((item) => item.slotID);
            /*  let reqObj = {
                 serviceId: serviceID,
                 timeSlotId: ids.toString()
             } */
            /* let addObject = {
                teamId: EditValues.teamIDSelected,
                date: EditValues.DateSelected,
                serviceSlots: [reqObj]
            } */
            var updateObject = new FormData();
            updateObject.append('teamId', EditValues.teamIDSelected);
            updateObject.append('teamDate', EditValues.DateSelected);
            updateObject.append('timeSlotId', ids.toString());
            dispatch(UpdateService(updateObject, serviceID));
        } else {
            toast.warning("Please choose any one time slot", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: false,
            });
        }
    };
    const searchClick = () => {
        if (!EditValues.EndDate && !EditValues.StartDate) {
            toast.warning('Please choose date filter!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: false,
            });
            return;
        }
        let dateStartPicked = EditValues.StartDate ? EditValues.StartDate : null;
        let dateEndPicked = EditValues.EndDate ? EditValues.EndDate : null;
        let FTeamID = EditValues.TeamSelectedID ? EditValues.TeamSelectedID : "";
        let filterObj = {
            StartDate: dateStartPicked,
            EndDate: dateEndPicked,
            TeamID: FTeamID
        }
        dispatch(GetTimeSlotReport(filterObj));
    }
    const clearSearchClick = () => {
        setEditValues({
            ...EditValues,
            StartDate: todayDate(),
            EndDate: DatePlusYMD(7),
            TeamSelectedID: "",
            TeamSelected: ""
        });
        TimeSlotsList = [];
    }
    const getcolors = (status, leavestatus) => {
        if (leavestatus) {
            return classes.onLeave;
        } else if (status) {
            return classes.complete;
        } else {
            return classes.open;
        }
    }
    const getstatus = (status, VData, leavestatus) => {
        if (leavestatus) {
            return "On Leave";
        } else if (status && VData) {
            return "Selected";
        } if (status) {
            return "Not Available";
        } else if (VData) {
            return "Selected";
        } else {
            return "Available";
        }
    }
    const dataExist = (tName, date, slotid) => {
        let selectedItems = EditValues.selectedTimeSlots;
        let Aindex = selectedItems.findIndex(sList => sList.slotID === slotid && sList.Date === date && sList.teamName === tName);
        return Aindex >= 0 ? true : false;
    }
    const handleClick = (teamID, tName, tDate, sslot, VData, leavestatus) => {
        if (sslot.isBooked && !VData) {
            toast.warning('Slot already booked!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: false,
            });
            return;
        } else if (leavestatus) {
            toast.warning('The selected team is on leave', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: false,
            });
            return;
        }
        let Items = {
            //slots: sslot,
            startTime: sslot.startTime,
            endTime: sslot.endTime,
            slotID: sslot.id,
            teamName: tName,
            Date: tDate
        };
        let selectedItems = EditValues.selectedTimeSlots;
        let selectedSlots = EditValues.SlotsSelected;
        let Valid = selectedItems.findIndex(sList => sList.Date === tDate && sList.teamName === tName);
        if (Valid > -1 || selectedItems.length == 0) {
            let Aindex = selectedItems.findIndex(sList => sList.slotID === sslot.id && sList.Date === tDate && sList.teamName === tName);
            if (Aindex > -1) {
                selectedItems.splice(Aindex, 1);
                selectedSlots.splice(Aindex, 1);
            } else {
                selectedItems.push(Items);
                selectedSlots.push(sslot.startTime + '-' + sslot.endTime);
            }

            setEditValues({
                ...EditValues,
                selectedTimeSlots: selectedItems,
                SlotsSelected: selectedSlots,
                DateSelected: selectedSlots.length > 0 ? tDate : "",
                teamNameSelected: selectedSlots.length > 0 ? tName : "",
                teamIDSelected: selectedSlots.length > 0 ? teamID : ""
            });
        } else {
            toast.warning('You are choosing different team or date', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: false,
            });
            return;
        }

    }
    const renderColumn = (colData, pindex) => {
        let teamRows = colData.slots;
        let teamName = colData.teamName;
        let teamID = colData.teamId;
        return teamRows.map((row, cindex) => {
            let searchDate = row.date;
            let TeamLeave = row.leave;
            return (
                <>
                    <TableCell >
                        {
                            row.service.map((data, scindex) => {
                                let colors = getcolors(data.isBooked, TeamLeave);
                                let VData = dataExist(teamName, searchDate, data.id);
                                colors = VData ? classes.progress : colors;
                                let tipStatus = getstatus(data.isBooked, VData, TeamLeave);
                                let SecString = data.postalCode ? (data.postalCode).substring(0, 2) : "";
                                SecString = SecString ? " (" + SecString + ")" : "";
                                let printingText = data.jobCode ? data.jobCode + SecString : "";
                                if (VData) {
                                    TimeSlotsList[pindex].slots[cindex].service[scindex].isBooked = false;
                                    TimeSlotsList[pindex].slots[cindex].service[scindex].serviceStatus = "";
                                }
                                return (

                                    <Tooltip title={tipStatus} className={classes.pointerCourser}>
                                        <div
                                            style={{ display: "flex", height: "10%", paddingBottom: "2%", paddingTop: "2%", alignItems: 'center', margin: "3%", justifyContent: 'center' }}
                                            className={[colors]}>

                                            <TableRow style={{ height: "100%", width: '100%' }} onClick={() => handleClick(teamID, teamName, searchDate, data, VData, TeamLeave)}>
                                                <Grid container direction="row" lg={12} md={12} sm={12} xl={12} xs={12}>
                                                    <Grid item md={4}>
                                                        <Typography align="left" className={[classes.whiteText, classes.boldText7, classes.paddingLR5]}>
                                                            {data.startTime}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={8}>
                                                        <Typography align="right" className={[classes.whiteText, classes.boldText7, classes.paddingLR5]}>
                                                            {printingText}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </TableRow>

                                        </div>
                                    </Tooltip>

                                )
                            })
                        }
                    </TableCell>
                </>
            )
        })

    }
    const renderTable = (SlotData) => {

        return (
            <TableContainer component={Paper} className={[classes.paper]}>
                <Table aria-label="simple table" stickyHeader>
                    {/*  <TableBody> */}
                    {SlotData.length > 0 ?
                        <>
                            <TableHead >
                                <TableRow  >
                                    <TableCell style={{ minWidth: 100, position: "sticky", left: 0, background: "white" }}>
                                        <Typography align="center" className={[classes.boldText7]}>Teams</Typography>
                                    </TableCell>
                                    {
                                        SlotData[0].slots.map((row, index) => {
                                            let searchDate = row.date;
                                            return (
                                                <TableCell style={{ minWidth: 150 }}>
                                                    <Typography align="center" className={[classes.boldText7]}>{DDMY(searchDate)}</Typography>
                                                </TableCell>
                                            )

                                        })
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {SlotData.map((row, pindex) => {
                                    return (
                                        <TableRow >
                                            <TableCell className={classes.stickyItem}>
                                                <Typography align="center" className={[classes.boldText7]}>{row.teamName}</Typography>
                                            </TableCell>
                                            {renderColumn(row, pindex)}
                                        </TableRow>
                                    )
                                })
                                }
                            </TableBody>
                        </>
                        :
                        null
                    }


                    {/* </TableBody> */}
                </Table>
            </TableContainer>
        )
    }
    return (
        <Dialog
            open={props.Status}
            fullWidth={false}
            fullScreen={false}
            maxWidth="lg"
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            scroll={"paper"}
            PaperProps={{
                style: {
                  overflowY: 'hidden'
                },
              }}
        >
            <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
            <DialogContent dividers style={{ overflow: "hidden" }}>
                <Grid container spacing={4} >
                    <Grid container item spacing={4} lg={12} md={12} sm={12} xl={12} xs={12} direction={"row"} >
                        <Grid item lg={2} md={2} sm={2} xl={2} xs={2} >
                            <InputField
                                label={"Start Date"}
                                type={"date"}
                                required={true}
                                value={EditValues.StartDate}
                                changeText={(data) => {
                                    setEditValues({
                                        ...EditValues,
                                        StartDate: data ? data.target.value : null,
                                        EndDate: data ? LastDayMonth(data.target.value) : null
                                    });
                                }}
                                // error={EditErrors.Date}
                                errorMsg={"Please enter date."}
                            />
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xl={2} xs={2} >
                            <InputField
                                label={"End Date"}
                                type={"date"}
                                required={true}
                                value={EditValues.EndDate}
                                changeText={(data) => {
                                    setEditValues({
                                        ...EditValues,
                                        EndDate: data ? data.target.value : null
                                    });
                                }}
                                // error={EditErrors.Date}
                                errorMsg={"Please enter date."}
                            />
                        </Grid>
                        <Grid item lg={2} md={2} sm={2} xl={2} xs={2} >
                            <Dropdown
                                label={"Select Team"}
                                data={TeamListData}
                                getOptionLabel={option => (option.teamName ? option.teamName : "")}
                                getOptionSelected={(option, value) => option.id === value.id}
                                value={EditValues.TeamSelected}
                                ItemChange={(event, newValue) => {
                                    setEditValues({
                                        ...EditValues,
                                        TeamSelectedID: newValue ? newValue.id : null,
                                        TeamSelected: newValue ? newValue : null
                                    })
                                }}
                                //error={EditErrors.TeamSelectedID}
                                initialName={"Team"}
                                errorMsg={"Please choose team."}
                            />
                        </Grid>
                        {/*  <Grid item md={1} >
                            <Button
                                onClick={() => clearSearchClick()}
                                className={classes.buttonCancel}
                                variant="contained" color="primary">
                                Clear
                            </Button>
                        </Grid> */}
                        <Grid item lg={1} md={1} sm={1} xl={1} xs={1} >
                            <Button
                                onClick={() => searchClick()}
                                className={classes.buttonSuccess}
                                variant="contained" color="primary">
                                Search
                            </Button>
                        </Grid>
                        <Grid item lg={5} md={5} sm={5} xl={5} xs={5} >
                            {
                                EditValues.teamNameSelected &&
                                <Typography className={[classes.boldText7, classes.paddingLR]}>
                                    {EditValues.teamNameSelected}({DDMY(EditValues.DateSelected)}) : {EditValues.SlotsSelected.toString()}
                                </Typography>
                            }
                        </Grid>
                    </Grid>


                    <Grid item lg={12} md={12} sm={12} xl={12} xs={12} style={{marginTop:'-2%'}}>
                        {renderTable(TimeSlotsList)}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.paddingLR}>
                <Buttons
                    close={() => props.ModelCancel()}
                    closeText={"Cancel"}
                    save={() => submitForm()}
                    saveText={"Submit"}
                />
            </DialogActions>
            <LoadingOverlay Status={isLoading} />
        </Dialog>
    );
}

export default EditTimeSlot;