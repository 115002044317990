import React, { useState, useEffect, Component } from "react";
import {
  Grid, TextField, Button, Switch,
  FormControlLabel, IconButton, DeleteIcon,
  EditIcon, MUIDataTable, Autocomplete, connect,
  _, swal, toast, Typography
} from "../../utilities/PackagesList";
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay, ButtonIcon
} from "../../utilities/ComponentsList";
import { VechileItems } from "../../utilities/MockData";
import { isEmptyObj , UnChangeObj} from "../../utilities/AppFunctions";
import { GetTimeSlots, DeleteTimeSlots } from '../../api/Services';
import { refreshReduxStates } from '../../api/Refresh';
import Create from "./Create";
import Edit from "./Edit";

class TimeSlots extends Component {

  constructor(props) {
    super(props);
    this.state = {
      DialogState: false,
      DeleteID: null,
      EditID: null,
      SelectedIndex: null,
      EditState:false,
      DeleteState:false,
      AddState:false,
      TimeSlotLists:[],
      isLoading: true,
      getCalls: [1],
      postCalls: [0],
      SelectingList:[]
    }
    props.GetTimeSlots();
  }
  UNSAFE_componentWillReceiveProps = (props) => {
    if (this.state.getCalls[0]) {
      if (props.TimeSlotSuccess) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        let SlotLists = _.orderBy(props.TimeSlotList, ['id'], ['asc']);
        this.setState({
          TimeSlotLists: SlotLists,
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.TimeSlotFailure) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          TimeSlotLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.postCalls[0]) {
      if (props.DeleteTimeSlotSuccess) {
        let updatedList = this.state.TimeSlotLists;
        updatedList.splice(this.state.SelectedIndex, 1);
        this.setState({
          postCalls: [0],
          isLoading: false,
          DeleteState: false,
          DeleteID: null,
          SelectedIndex: null,
          TimeSlotLists: updatedList
        }, () => {
          this.props.refreshReduxStates();
          toast.success('Deleted Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      } else if (props.DeleteTimeSlotFailure) {
        this.setState({
          postCalls: [0],
          isLoading: false,
          DeleteState: false,
          DeleteID: null,
          SelectedIndex: null,
        }, () => {
          toast.error(props.DeleteTimeSlotResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      }
    }
  }
  editPress = (id, index) => {
    let ListData = UnChangeObj(this.state.TimeSlotLists);
    this.setState({
      EditState: true,
      EditID: id,
      SelectedIndex: index,
      SelectingList: ListData
    })
  }
  CloseEdit = () => {
    this.setState({
      EditState: false,
      EditID: null,
      SelectedIndex: null,
      SelectingList:[]
    })
  }
  Edited = (EditedObject) => {
    let ListData = this.state.TimeSlotLists;
    ListData[this.state.SelectedIndex] = EditedObject;
    this.setState({
      EditState: false,
      EditID: null,
      SelectedIndex: null,
      isLoading: false,
      TimeSlotLists: ListData,
      getCalls: [0],
      SelectingList:[]
    }, () => {
      this.props.refreshReduxStates();
    })
  }
  deletePress = (id, index) => {
    this.setState({
      DeleteState : true,
      DeleteID: id,
      SelectedIndex: index
    })
  }
  DeleteClose = () => {
    this.setState({
      DeleteState: false,
      DeleteID: null,
      SelectedIndex: null,
    })
  }

  DeleteConfirm = () => {
    this.setState({
      isLoading: true,
      postCalls:[1]
    }, () => {
      this.props.DeleteTimeSlots(this.state.DeleteID);
    })
  }
  AddPress = () => {
    this.setState({
      AddState: true
    })
  }
  CloseAdd = () => {
    this.setState({
      AddState : false
    })
  }
  Added = () => {
    this.setState({
      AddState : false,
      isLoading: true,
      getCalls: [1],
    }, () => {
      this.props.GetTimeSlots();
    })
  }
  render() {
    const columns = [
      {
        name: "id",
        label: "ID",
        options: {
          filter: false,
          sort: false,
          display:false
        }
      },
      {
        name: "",
        label: "SNO",
        options: {
          filter: false,
          sort: true,
          display:true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (tableMeta.rowIndex+1)
          }
        }
      },
      {
        name: "name",
        label: "Name",
        options: {
          filter: false,
          sort: true,
          display:true,
        }
      },
      {
        name: "startTime",
        label: "Start Time",
        options: {
          filter: false,
          sort: true,
          display:true,
        }
      },
      {
        name: "endTime",
        label: "End Time",
        options: {
          filter: false,
          sort: true,
          display:true,
        }
      },
      {
        name: "",
        label: "Actions",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <ButtonIcon
                EditTitle={"Edit Time Slot"}
                DeleteTitle={"Delete Time Slot"}
                Edit={() => this.editPress(tableMeta.rowData[0], tableMeta.rowIndex)}
                Delete={() => this.deletePress(tableMeta.rowData[0], tableMeta.rowIndex)}
              />
            )
          }
        }
      }
    ];

    return (
      <>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <MUIDataTable
              title="Time Slots"
              data={this.state.TimeSlotLists}
              columns={columns}
              options={{
                sort: true,
                selectableRows: false,
                print: false,
                download: false,
                viewColumns: false,
                filter: false,
                responsive:true,
                customToolbar: () => {
                  return (
                    <CustomToolbar title={"Add Time Slot"} iconOnPress={() => { this.AddPress() }} />
                  );
                }
              }}
            />
          </Grid>
          <AlertDialog
            Status={this.state.DeleteState}
            DialogConfirm={() => { this.DeleteConfirm() }}
            DialogCancel={() => { this.DeleteClose() }}
            Title={"Delete Time Slot"}
            Content={"Are you sure, you want to delete the time slot ?"}
          />
          {this.state.AddState ? <Create
            Title={"Add Time Slot"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.AddState}
            ModelConfirm={this.Added}
            ModelCancel={this.CloseAdd}
          /> : null}
          {this.state.EditState ? <Edit
            Title={"Edit Time Slot"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.EditState}
            ModelConfirm={this.Edited}
            ModelCancel={this.CloseEdit}
          /> : null}
          <LoadingOverlay Status={this.state.isLoading} />
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    TimeSlotSuccess: state.GetTimeSlotsReducer.Success,
    TimeSlotFailure: state.GetTimeSlotsReducer.Failure,
    TimeSlotList: state.GetTimeSlotsReducer.TimeSlots,

    DeleteTimeSlotSuccess: state.DeleteTimeSlotsReducer.Success,
    DeleteTimeSlotFailure: state.DeleteTimeSlotsReducer.Failure,
    DeleteTimeSlotResponse: state.DeleteTimeSlotsReducer.DeleteResponse,

    UserDetails: state.LoginReducer.UserDetails,
    LoaderState: state.LoaderReducer.State
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetTimeSlots: () => dispatch(GetTimeSlots()),
    DeleteTimeSlots: (ID) => dispatch(DeleteTimeSlots(ID)),
    refreshReduxStates: () => dispatch(refreshReduxStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeSlots);
