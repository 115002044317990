import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { DecimalFormat, capsFirstLetter, GetBooleanStatus, convertDateDMY } from './../../utilities/AppFunctions';
import RF from "./Poppins/Poppins-Regular.ttf"
import BF from "./Poppins/Poppins-Bold.ttf"
Font.register({
    family: 'Poppins', format: "truetype", fonts: [
        { src: RF },
        { src: BF, fontWeight: 'bold' },
    ]
});
const styles = StyleSheet.create({
    page: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        backgroundColor: "#ffffff"
    },
    section: {
        margin: 10,
        padding: 10,
        flex: 1,
        flexGrow: 1
    },
    RowDirection: {
        flexDirection: 'row',
        display: "flex",
        width: '100%'
    },
    HeaderText: {
        fontSize: 10,
        fontFamily: "Poppins",
        fontWeight: "bold",
    },
    ContentText: {
        fontSize: 10,
        fontFamily: "Poppins"
    },
    borders: {
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderBottomWidth: 1,
        borderColor: "#000"
    },
    cellBorder: {
        borderRightWidth: 1,
        borderColor: "#000",
        padding: 10
    }
});
const getTitles = (props) => {
    let rowLength = parseInt(props.length);
    const titleDefault = rowLength > 1 ? "50%" : "100%";
    let fieldWidth = rowLength > 1 ? (parseInt(50 / (rowLength - 1)) + "%").toString() : "100%";
    return (
        <View style={[styles.RowDirection, styles.borders,]}>
            {props.map((data, index) => {
                let flexWidth = index == 0 ? titleDefault : fieldWidth;
                let lastItem = rowLength == index + 1 ? "flex-end" : "flex-start";
                let lastText = rowLength == (index + 1) ? "right" : "left";
                return (
                    <View style={[{ width: flexWidth }, styles.cellBorder]}>
                        <Text style={[styles.HeaderText, { textAlign: lastText }]}>{data}</Text>
                    </View>
                )
            })
            }
        </View>
    )
}
const getSubFields = (fieldValue, fieldtype, changeFunction, SubChildIndex, ParentIndex, ChildIndex) => {
    switch (fieldtype) {
        case 'string':
            return (
                <Text style={styles.ContentText}>{fieldValue}</Text>
            );
        case 'boolean':
            return (
                <Text style={styles.ContentText}>{GetBooleanStatus(fieldValue)}</Text>
            );
        case 'numeric':
            return (
                <Text style={styles.ContentText}>{fieldValue}</Text>
            );
        default:
            return null;
    }
}
const getFields = (singleItem, itemsLength, changeFunction, SubChildIndex, ParentIndex, ChildIndex, HeadSupport = false) => {
    let rowLength = parseInt(itemsLength);
    const titleDefault = rowLength > 1 ? "50%" : "100%";
    let fieldWidth = rowLength > 1 ? (parseInt(50 / (rowLength - 1)) + "%").toString() : "100%";
    let lastItem = rowLength == SubChildIndex + 1 ? "flex-end" : "flex-start";
    lastItem = rowLength > 1 ? lastItem : "flex-start";
    lastItem = HeadSupport ? "flex-start" : lastItem;
    let lastText = rowLength == SubChildIndex + 1 ? "right" : "left";
    lastText = HeadSupport ? "left" : lastText;
    let arrangeLabel = rowLength > 1 ? lastItem : "flex-start";
    let arrangeLabelText = rowLength > 1 ? lastText : "left";
    switch (singleItem.field) {
        case 'label':
            return (
                <View style={[{ width: titleDefault, alignItems: arrangeLabel }, styles.cellBorder]}>
                    <Text style={[styles.ContentText, { textAlign: arrangeLabelText }]}>{singleItem.title}</Text>
                </View>
            );
        case 'text':
            return (
                <View style={[{ width: fieldWidth, alignItems: lastItem }, styles.cellBorder]}>
                    <Text style={[styles.ContentText, { textAlign: lastText }]}>{singleItem.title}</Text>
                </View>
            );
        case 'textinput':
            return (
                <View style={[{ width: fieldWidth, alignItems: lastItem }, styles.cellBorder]} >
                    {getSubFields(singleItem.value, singleItem.type, changeFunction, SubChildIndex, ParentIndex, ChildIndex)}
                </View>
            );
        case 'radio':
            return (
                <View style={[{ width: fieldWidth, alignItems: lastItem }, styles.cellBorder]}>
                    {getSubFields(singleItem.value, singleItem.type, changeFunction, SubChildIndex, ParentIndex, ChildIndex)}
                </View>
            );
        case 'checkbox':
            return (
                <View style={[{ width: fieldWidth, alignItems: lastItem }, styles.cellBorder]}>
                    {getSubFields(singleItem.value, singleItem.type, changeFunction, SubChildIndex, ParentIndex, ChildIndex)}
                </View>
            );
        default:
            return null;
    }
}
const getHFields = (singleItem, itemsLength, changeFunction, SubChildIndex, ParentIndex, ChildIndex, HeadSupport = false) => {
    let rowLength = parseInt(itemsLength);
    const titleDefault = rowLength > 1 ? "50%" : "100%";
    let fieldWidth = rowLength > 1 ? (parseInt(50 / (rowLength - 1)) + "%").toString() : "100%";
    let lastItem = rowLength == SubChildIndex + 1 ? "flex-end" : "flex-start";
    lastItem = rowLength > 1 ? lastItem : "flex-start";
    lastItem = HeadSupport ? "flex-start" : lastItem;
    let lastText = rowLength == SubChildIndex + 1 ? "right" : "left";
    lastText = HeadSupport ? "left" : lastText;
    switch (singleItem.field) {
        case 'label':
            return (
                <View style={{ width: titleDefault }}>
                    <Text style={[styles.HeaderText, { textAlign: lastText }]}>{singleItem.title}</Text>
                </View>
            );
        case 'textinput':
            return (
                <View style={{ width: fieldWidth }} >
                    {getSubFields(singleItem.value, singleItem.type, changeFunction, SubChildIndex, ParentIndex, ChildIndex)}
                </View>
            );
        case 'radio':
            return (
                <View style={{ width: fieldWidth }} >
                    {getSubFields(singleItem.value, singleItem.type, changeFunction, SubChildIndex, ParentIndex, ChildIndex)}
                </View>
            );
        case 'checkbox':
            return (
                <View style={{ width: fieldWidth }} >
                    {getSubFields(singleItem.value, singleItem.type, changeFunction, SubChildIndex, ParentIndex, ChildIndex)}
                </View>
            );
        default:

            return null;
    }
}
const HeaderRender = (parentProps, HeadProps) => {
    return (
        <View style={{ flexDirection: 'column', }}>
            {HeadProps.map((pData, pIndex) => {
                return (
                    <View style={[styles.RowDirection, styles.borders, { flexWrap: 'wrap' }]}>
                        {
                            pData.body.map((childdata, childindex) => {
                                let ChildIndex = childindex;
                                let childLength = childdata.items.length;
                                return (
                                    <View style={[{ flexDirection: 'column', width: "50%" }, styles.cellBorder]} >
                                        {childdata.items.map((subdata, subindex) => {
                                            let SubChildIndex = subindex;
                                            return (
                                                <View style={[{ flexDirection: 'column' }]} >
                                                    {getHFields(subdata, childLength, parentProps.onDataChange, SubChildIndex, pIndex, ChildIndex, true)}
                                                </View>
                                            )

                                        })
                                        }
                                    </View>
                                )
                            })
                        }
                    </View>
                )
            })
            }
        </View>
    )


}
const TopPortion = (customerProps,serviceProps) => {
    return (
        <View style={{ flexDirection: 'column', }}>
            <View style={[styles.RowDirection, styles.borders, { flexWrap: 'wrap' }]}>
                <View style={[styles.cellBorder, { width: "34%", }]}>
                    <Text style={[styles.HeaderText]}>Customer Name</Text>
                    <Text style={styles.ContentText}>{customerProps.CustomerName}</Text>
                </View>
                <View style={[styles.cellBorder, { width: "33%", }]}>
                    <Text style={[styles.HeaderText]}>Customer Number</Text>
                    <Text style={styles.ContentText}>{customerProps.CustomerNo}</Text>
                </View>
                <View style={[styles.cellBorder, { width: "33%", }]}>
                    <Text style={[styles.HeaderText]}>Date of Service</Text>
                    <Text style={styles.ContentText}>{convertDateDMY(serviceProps[0].serviceDate)}</Text>
                </View>
            </View>
            <View style={[styles.RowDirection, styles.borders, { flexWrap: 'wrap' }]}>
                <View style={[styles.cellBorder, { width: "34%", }]}>
                    <Text style={[styles.HeaderText]}>Product Brand</Text>
                    <Text style={styles.ContentText}>{customerProps.Brand}</Text>
                </View>
                <View style={[styles.cellBorder, { width: "33%", }]}>
                    <Text style={[styles.HeaderText]}>Product Model</Text>
                    <Text style={styles.ContentText}>{customerProps.ProdModel}</Text>
                </View>
                <View style={[styles.cellBorder, { width: "33%", }]}>
                    <Text style={[styles.HeaderText]}>Product Serial Number</Text>
                    <Text style={styles.ContentText}>{customerProps.SerialNo}</Text>
                </View>
            </View>
            <View style={[styles.RowDirection, styles.borders, { flexWrap: 'wrap' }]}>
                <View style={[styles.cellBorder,{ width: "100%" }]}>
                    <Text style={[styles.HeaderText]}>Technician Name</Text>
                    <Text style={styles.ContentText}>{serviceProps[0].technicianName}</Text>
                </View>
            </View>
        </View>
    )
}
export const PDF = (props) => {
    let FormList = props.input;
    let CustomerDetails = props.productInfo;
    let ServiceDetails = props.services ? props.services : [];
    let formtypeSelected = props.Type;
    
    let formName = "";
    if (FormList) {
        let SelecFormData = FormList.metadata;
        formtypeSelected = FormList.type;
        formtypeSelected = formtypeSelected=='inspection' ? "DP/Drum Inspection" : formtypeSelected;
        formName = capsFirstLetter(formtypeSelected) + ' Form';
        let inputData = SelecFormData;
        let totalAmount = 0.0;
        return (
            <Document>
                <Page style={styles.page} size="A4" wrap style={{
                    flexDirection: "column",
                    backgroundColor: "white",
                    padding: 48,
                    fontSize: 12
                }}>
                    <View >
                        <View style={{ paddingBottom: 15 }}>
                            <Text style={[styles.boldText8]}>{formName}</Text>
                        </View>
                        <View >
                            {ServiceDetails.length > 0 ?
                                <View style={{ paddingBottom: 5 }}>
                                    {TopPortion(CustomerDetails, ServiceDetails)}
                                </View>
                                : null
                            }
                        </View>
                        <View >
                            {inputData.hasOwnProperty("header") && inputData.header.length > 0 ?
                                <View >
                                    {HeaderRender(props, inputData.header)}
                                </View>
                                : null
                            }
                        </View>
                        {
                            inputData.body.map((item, index) => {
                                let ParentIndex = index;
                                let bodyLength = item.body.length;
                                return (
                                    <View >
                                        {index === 0 ? getTitles(item.title) : null}
                                        {item.body.map((childdata, childindex) => {
                                            let ChildIndex = childindex;
                                            let childLength = childdata.items.length;
                                            return (
                                                <View style={[styles.RowDirection, styles.borders]}>
                                                    {childdata.items.map((subdata, subindex) => {
                                                        let SubChildIndex = subindex;
                                                        //totalAmount = subdata.type=='numeric' ? (totalAmount + parseFloat(DecimalFormat(subdata.value))) : 0;
                                                        totalAmount += subdata.type == 'numeric' ? parseFloat(DecimalFormat(subdata.value)) : 0.0;
                                                        return getFields(subdata, childLength, props.onDataChange, SubChildIndex, ParentIndex, ChildIndex)
                                                    })
                                                    }
                                                </View>
                                            )
                                        })
                                        }
                                        {formtypeSelected == 'repair' &&
                                            <View style={[styles.RowDirection, styles.borders]}>
                                                <View style={[{ width: "100%" }, styles.cellBorder]}>
                                                    <Text style={[styles.ContentText, { textAlign: "right" }]}>Repair Total : {totalAmount}</Text>
                                                </View>
                                            </View>
                                        }
                                    </View>
                                )
                            })
                        }
                    </View>
                </Page>
            </Document>
        )
    } else {
        return (
            <View />
        )
    }
};

export default PDF;