import React, { useState, useEffect, Component } from "react";
import {
  Grid, TextField, Button, Switch,
  FormControlLabel, IconButton, DeleteIcon,
  EditIcon, MUIDataTable, Autocomplete, connect,
  _, swal, toast, Typography, Checkbox, Tab,
  TabPanel, AppBar, TabContext, TabList, withStyles,
  CardMedia, SRLWrapper, SimpleReactLightbox
} from "../../utilities/PackagesList";
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay, ButtonIcon
} from "../../utilities/ComponentsList";
import {
  isEmptyObj, dateTimeFormating, todayDate,
  LastDayMonth, DatePlusYMD, AMPM,
  convertDateDMY, nullToStr, PriceFormat
} from "../../utilities/AppFunctions";
import { getTeams } from '../../api/Team';
import { refreshReduxStates } from '../../api/Refresh';
import { GetServiceReport } from '../../api/Services';
import styles from "../../utilities/StylesClass";
import BaseDetails from './../../utilities/BaseDetails.json';
import {collectionReport, jobtypeReport, paymentReport} from './../../utilities/MockData';

class PaymentReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ReportList:[],
      StartDate: todayDate(),
      EndDate: DatePlusYMD(10),
      TeamSelectedID: "",
      TeamSelected: "",
      isLoading: true,
      getCalls: [1, 1],
      TeamsLists: []

    }
    let filterObj = {
      Type : "balancePaymentList",
      TeamID : "",
      StartDate : todayDate(),
      EndDate : DatePlusYMD(10)
    };
    props.getTeams(1);
    props.GetServiceReport(filterObj);
  }

  UNSAFE_componentWillReceiveProps = (props) => {
    if (this.state.getCalls[0]) {
      if (props.TeamListSuccess) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        let newTeam = {
          "id": 0,
          "teamName": "All",
          "vehicleNumber": "",
          "vehicelId": "",
          "assignedStaff": [],
          "teamTypeId": 0,
          "teamTypeName": "all"
        };
        let Teams = props.TeamLists;
        Teams.unshift(newTeam);
        this.setState({
          TeamsLists: props.TeamLists,
          isLoading: loaderStatus,
          TeamSelectedID: Teams[0].id,
          TeamSelected: Teams[0],
          getCalls: succed
        })
      } else if (props.TeamListFailure) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          TeamsLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.getCalls[1]) {
      if (props.SReportSuccess) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          ReportList: props.SReportLists,
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.SReportFailure) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          ReportList: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
  }

  searchClick = () => {
    if (!this.state.StartDate && !this.state.EndDate&& !this.state.TeamSelectedID) {
      toast.warning('Please choose any one filter option!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      });
      return;
    }
    let Start = this.state.StartDate ? this.state.StartDate : "";
    let End = this.state.EndDate ? this.state.EndDate : "";
    let TeamSelectedID = this.state.TeamSelectedID ? this.state.TeamSelectedID : "";
    let filterObj = {
      Type : "balancePaymentList",
      TeamID : TeamSelectedID,
      StartDate : Start,
      EndDate : End
    };
    this.setState({
      isLoading : true,
      getCalls: [0, 1]
    })
    this.props.GetServiceReport(filterObj);
  }

  clearSearchClick = () => {
    this.setState({
      CollectionList: [],
      StartDate: "",//DateMinusYMD(5),
      EndDate: "",
      TeamSelectedID: "",
      TeamSelected: ""
    });

  }

  render() {
    const { classes } = this.props;

    const columns = [
      {
        name: "serviceDate",
        label: "Date",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (convertDateDMY(value))
          }
        }
      },
      {
        name: "customerNumber",
        label: "Customer Number",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true
        }
      },
      {
        name: "customerName",
        label: "Customer Name",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true
        }
      },
      {
        name: "customerPhone",
        label: "Customer Contact",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true
        }
      },
      {
        name: "jobType",
        label: "Job Type",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true
        }
      },
      {
        name: "technicianName",
        label: "Tech. Name",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true
        }
      },
      {
        name: "balancePaidDate",
        label: "Payment Date",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (convertDateDMY(value))
          }
        }
      },
      {
        name: "paymentCollection",
        label: "Payment Collection",
        options: {
          filter: false,
          sort: false,
          display: false,
          print: false
        }
      },
      {
        name: "paymentCollected",
        label: "Payment Collected",
        options: {
          filter: false,
          sort: false,
          display: false,
          print: false
        }
      },
      {
        name: "paymentCollection",
        label: "Balance Amount",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            let collection = tableMeta.rowData[7];
            let collected = tableMeta.rowData[8];
            let balance = parseFloat(collection) - parseFloat(collected);
            return (PriceFormat(balance))
          }
        }
      },
      {
        name: "balancePaymentType",
        label: "Payment Mode",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true
        }
      }
    ];

    return (
      <>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <Grid container spacing={1} lg={12} md={12} sm={12} xl={12} xs={12} direction={"row"}>
              <Grid item lg={2} md={2} sm={2} xl={2} xs={2}>
                <InputField
                  label={"Start Date"}
                  type={"date"}
                  required={false}
                  value={this.state.StartDate}
                  changeText={(data) => {
                    this.setState({
                      StartDate: data ? data.target.value : null,
                      EndDate: data ? LastDayMonth(data.target.value) : null
                    })
                  }}
                  initialName={"Start Date"}
                  errorMsg={"Please enter start date."}
                />
              </Grid>
              <Grid item lg={2} md={2} sm={2} xl={2} xs={2}>
                <InputField
                  label={"End Date"}
                  type={"date"}
                  required={false}
                  value={this.state.EndDate}
                  changeText={(data) => { this.setState({ EndDate: data ? data.target.value : null }) }}
                  // error={Createerrors.Date}
                  initialName={"End Date"}
                  errorMsg={"Please enter end date."}
                />
              </Grid>
              <Grid item lg={2} md={2} sm={2} xl={2} xs={2}>
                <Dropdown
                  label={"Select Team"}
                  data={this.state.TeamsLists}
                  getOptionLabel={option => (option.teamName ? option.teamName : "")}
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={this.state.TeamSelected}
                  ItemChange={(event, newValue) => {
                    this.setState({
                      TeamSelectedID: newValue ? newValue.id : null,
                      TeamSelected: newValue ? newValue : null
                    })
                  }}
                  //error={Createerrors.TeamSelectedID}
                  initialName={"Team"}
                  errorMsg={"Please choose team."}
                />
              </Grid>
              <Grid item lg={1} md={1} sm={1} xl={1} xs={1}>
                <Button
                  onClick={() => this.clearSearchClick()}
                  className={classes.buttonCancel}
                  variant="contained" color="primary">
                  Clear
                  </Button>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xl={1} xs={1}>
                <Button
                  onClick={() => this.searchClick()}
                  className={classes.buttonSuccess}
                  variant="contained" color="primary">
                  Search
                  </Button>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xl={12} xs={12} style={{ marginTop: 10 }}>
              <MUIDataTable
                title=""
                data={this.state.ReportList}
                columns={columns}
                options={{
                  downloadOptions: {
                    filename: "Job_Type_Report.csv",
                    filterOptions: {
                      useDisplayedColumnsOnly: true,
                      useDisplayedRowsOnly: true
                    }
                  },
                  sort: true,
                  selectableRows: false,
                  selectableRowsOnClick: false,
                  responsive: true,
                  print: true,
                  download: true,
                  viewColumns: false,
                  filter: false,
                }}
              />
            </Grid>

          </Grid>
          <LoadingOverlay Status={this.state.isLoading} />
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    TeamListSuccess: state.getTeamsReducer.Success,
    TeamListFailure: state.getTeamsReducer.Failure,
    TeamLists: state.getTeamsReducer.TeamList,

    SReportSuccess: state.GetServiceReportReducer.Success,
    SReportFailure: state.GetServiceReportReducer.Failure,
    SReportLists: state.GetServiceReportReducer.SReport,

    UserDetails: state.LoginReducer.UserDetails,
    LoaderState: state.LoaderReducer.State
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTeams: (ID) => dispatch(getTeams(ID)),
    GetServiceReport: (filterObj) => dispatch(GetServiceReport(filterObj)),
    refreshReduxStates: () => dispatch(refreshReduxStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PaymentReport));
