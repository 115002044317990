import React, { useState, useEffect, Component } from "react";
import {
  Grid, TextField, Button, Switch,
  FormControlLabel, IconButton, DeleteIcon,
  EditIcon, MUIDataTable, Autocomplete, connect,
  _, swal, toast
} from "./../../utilities/PackagesList";
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay, ButtonIcon
} from "./../../utilities/ComponentsList";
import { VechileItems } from "./../../utilities/MockData";
import { isEmptyObj, UnChangeObj } from "./../../utilities/AppFunctions";
import { getVechileTypes, getVechiles, getVechileSize, UpdateVechile, DeleteVechile, AddVechile } from './../../api/Vechile';
import { refreshReduxStates } from './../../api/Refresh';
import { LoaderStatus } from './../../api/Loading';
import Create from "./CreateVechile";
import EditVechile from "./EditVechile";

let getVechileState = false;
class Vehicles extends Component {

  constructor(props) {
    super(props);
    this.state = {
      DialogState: false,
      DeleteID: null,
      EditID: null,
      SelectedIndex: null,
      ModelType: null,
      ModelState: false,
      EditModelState: false,
      VechileTypesList: [],
      VechileLists: [],
      VechileSizeList: [],
      isLoading: true,
      getCalls: [1, 1, 1],
      postCalls: [0, 0, 0],
      SelectingList : []
    }
    props.getVechiles();
    props.getVechileTypes();
    props.getVechileSize();
  }
  UNSAFE_componentWillReceiveProps = (props) => {
    if (this.state.getCalls[0]) {

      if (props.VechileListSuccess) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = getVechileState ? false : succed.includes(1);
        getVechileState = false;
        this.setState({
          VechileLists: props.VechileList,
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.VechileListFailure) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = getVechileState ? false : succed.includes(1);
        getVechileState = false;
        this.setState({
          VechileLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.getCalls[1]) {

      if (props.VechileTypeSuccess) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          VechileTypesList: props.VechileTypes,
          getCalls: succed,
          isLoading: loaderStatus
        })
      } else if (props.VechileTypeFailure) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          VechileTypesList: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.getCalls[2]) {

      if (props.VechileSizeSuccess) {
        let succed = this.state.getCalls;
        succed[2] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          VechileSizeList: props.VechileSize,
          getCalls: succed,
          isLoading: loaderStatus
        })
      } else if (props.VechileSizeFailure) {
        let succed = this.state.getCalls;
        succed[2] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          VechileSizeList: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.postCalls[2]) {
      if (props.DeleteVechileSuccess) {
        let updatedList = this.state.VechileLists;
        updatedList.splice(this.state.SelectedIndex, 1);
        this.setState({
          postCalls: [0, 0, 0],
          isLoading: false,
          DialogState: false,
          DeleteID: null,
          SelectedIndex: null,
          VechileLists: updatedList,
          //getCalls:[1,0,0],
        }, () => {
          this.props.refreshReduxStates();
          /*  getVechileState = true;
           this.props.getVechiles(); */
          toast.success('Deleted Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      } else if (props.DeleteVechileFailure) {
        this.setState({
          postCalls: [0, 0, 0],
          isLoading: false,
          DialogState: false,
          DeleteID: null,
          SelectedIndex: null,
        }, () => {
          toast.error(props.DeleteVechileResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      }
    }
  }
  editPress = (id, index) => {
    let vechileList = UnChangeObj(this.state.VechileLists);
    this.setState({
      EditModelState: true,
      EditID: id,
      SelectingList : vechileList,
      ModelType: "Edit",
      SelectedIndex: index
    })
  }
  CloseEdit = () => {
    this.setState({
      EditModelState: false,
      ModelType: null,
      EditID: null,
      SelectingList:[],
      SelectedIndex: null,
    })
  }
  EditedVechile = (EditedObject) => {
    let ListData = this.state.VechileLists;
    let EditingID = this.state.EditID;
    let ItemIndex = ListData.findIndex(itm => itm.id === EditingID);
    ListData[ItemIndex] = EditedObject;
    this.setState({
      EditModelState: false,
      ModelType: null,
      EditID: null,
      SelectedIndex: null,
      isLoading: false,
      VechileLists: ListData,
      SelectingList:[],
      getCalls: [0, 0, 0],
    }, () => {
      this.props.refreshReduxStates();
      /* getVechileState = true;
      this.props.getVechiles(); */
    })
  }
  deletePress = (id, index) => {
    //let vechileList = this.state.VechileLists;
    //let vechiles = vechileList;
    //let VechileSelected = vechiles[index].id;
    this.setState({
      DialogState: !this.state.DialogState,
      DeleteID: id,
      SelectedIndex: index
    })
  }

  AddVehicle = () => {
    this.setState({
      ModelState: !this.state.ModelState,
      ModelType: "Add",
    })
  }
  DeleteClose = () => {
    this.setState({
      DialogState: !this.state.DialogState,
      DeleteID: null,
      SelectedIndex: null,
    })
  }

  DeleteConfirm = () => {
    this.setState({
      isLoading: true,
      postCalls: [0, 0, 1]
    }, () => {
      this.props.DeleteVechile(this.state.DeleteID);
    })
  }
  CloseCreate = () => {
    this.setState({
      ModelState: !this.state.ModelState,
      ModelType: null,
      EditID: null,
      SelectedIndex: null,
    })
  }
  AddedVechile = () => {
    this.setState({
      ModelState: !this.state.ModelState,
      ModelType: null,
      EditID: null,
      SelectedIndex: null,
      isLoading: true,
      getCalls: [1, 0, 0],
    }, () => {
      getVechileState = true;
      this.props.getVechiles();
    })
  }
  render() {
    const columns = [
      {
        name: "id",
        label: "ID",
        options: {
          filter: false,
          sort: false,
          display: false,
        }
      },
      {
        name: "vechicelType",
        label: "Vehicle Type",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "name",
        label: "Vehicle Name",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "vechicelNumber",
        label: "Vehicle Number",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "vechicelSize",
        label: "Vehicle Size",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "",
        label: "Actions",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <ButtonIcon
                EditTitle={"Edit Vehicle"}
                DeleteTitle={"Delete Vehicle"}
                Edit={() => this.editPress(tableMeta.rowData[0], tableMeta.rowIndex)}
                Delete={() => this.deletePress(tableMeta.rowData[0], tableMeta.rowIndex)}
              />
            )
          }
        }
      }
    ];

    return (
      <>
        {/*  <PageTitle title="Vehicles" /> */}
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <MUIDataTable
              title="Vehicles List"
              data={this.state.VechileLists}
              columns={columns}
              options={{
                sort: true,
                selectableRows: false,
                print: false,
                download: false,
                viewColumns: false,
                filter: false,
                responsive:true,
                customToolbar: () => {
                  return (
                    <CustomToolbar title={"Add Vehicle"} iconOnPress={() => { this.AddVehicle() }} />
                  );
                }
              }}
            />
          </Grid>
          <AlertDialog
            Status={this.state.DialogState}
            DialogConfirm={() => { this.DeleteConfirm() }}
            DialogCancel={() => { this.DeleteClose() }}
            Title={"Delete Vehicle"}
            Content={"Are you sure, you want to delete the vehicle ?"}
          />
          {this.state.ModelState ? <Create
            Title={"Add Vechile"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.ModelState}
            ModelConfirm={this.AddedVechile}
            ModelCancel={this.CloseCreate}
          /> : null}
          {this.state.EditModelState ? <EditVechile
            Title={"Edit Vechile"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.EditModelState}
            ModelConfirm={this.EditedVechile}
            ModelCancel={this.CloseEdit}
          /> : null}
          {/*<CustomModel
            Title={"Add Vechile"}
            //Content={CreateVehicle(this)}
            parentClass={this}
            Status={this.state.ModelState}
            ModelConfirm={()=>{this.CreateConfirm()}}
            ModelCancel={()=>{this.CloseCreate()}} />
  
         <CustomModel
            Title={"Edit Vechile"}
            Content={EditVechile({ EditModelCancel, UpdateSuccess }, Values, props)}
            Status={Values.EditModelState}
            ModelConfirm={EditModelConfirm}
            //ModelType={Values.ModelType}
            ModelCancel={EditModelCancel} />*/}
          <LoadingOverlay Status={this.state.isLoading} />
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    VechileTypeSuccess: state.getVechileTypesReducer.Success,
    VechileTypeFailure: state.getVechileTypesReducer.Failure,
    VechileTypes: state.getVechileTypesReducer.VechileTypes,

    VechileListSuccess: state.getVechilesReducer.Success,
    VechileListFailure: state.getVechilesReducer.Failure,
    VechileList: state.getVechilesReducer.Vechiles,

    VechileSizeSuccess: state.getVechileSizeReducer.Success,
    VechileSizeFailure: state.getVechileSizeReducer.Failure,
    VechileSize: state.getVechileSizeReducer.VechileSize,

    AddVechileSuccess: state.AddVechileReducer.Success,
    AddVechileFailure: state.AddVechileReducer.Failure,
    AddingVechileResponse: state.AddVechileReducer.AddingResponse,

    EditVechileSuccess: state.UpdateVechileReducer.Success,
    EditVechileFailure: state.UpdateVechileReducer.Failure,
    EditVechileResponse: state.UpdateVechileReducer.UpdateResponse,

    DeleteVechileSuccess: state.DeleteVechileReducer.Success,
    DeleteVechileFailure: state.DeleteVechileReducer.Failure,
    DeleteVechileResponse: state.DeleteVechileReducer.DeleteResponse,

    UserDetails: state.LoginReducer.UserDetails,
    LoaderState: state.LoaderReducer.State
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVechiles: () => dispatch(getVechiles()),
    getVechileTypes: () => dispatch(getVechileTypes()),
    getVechileSize: () => dispatch(getVechileSize()),
    UpdateVechile: (updateObject, VechileID) => dispatch(UpdateVechile(updateObject, VechileID)),
    DeleteVechile: (VechileID) => dispatch(DeleteVechile(VechileID)),
    AddVechile: (addObject) => dispatch(AddVechile(addObject)),
    refreshReduxStates: () => dispatch(refreshReduxStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Vehicles);
