import React, { useState, useEffect, Component, useRef } from 'react';
import {
    Grid, Dialog, GridList, DialogContent,
    GridListTile, DialogTitle, makeStyles,
    Typography, Divider, IconButton, DeleteIcon,
    EditIcon, _, swal, toast, useSelector, useDispatch,
    Card, axios, MUIDataTable, DialogActions, XLSX, moment
} from '../../utilities/PackagesList';
import {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown,
    Buttons, LoadingOverlay
} from "../../utilities/ComponentsList";
import { isEmptyObj, bytesToSize, convertDateDMY, PriceFormat, ReformatDate, ExportFileData } from "../../utilities/AppFunctions";
import { ExportCustomers } from '../../api/Services';
import { refreshReduxStates } from './../../api/Refresh';
import useStyles from "./../../utilities/Styles";
const staticObject = {
    "CustomerName": "customerName",
    "CustomerNumber": "customerNumber",
    "Address": "address",
    "PostalCode": "postalCode",
    "Email": "email",
    "Phone": "phoneNumber",
    "ProductDescription": "productDescription",
    "Brand": "brand",
    "Model": "model",
    "SerialNumber": "serialNumber",
    "GuaranteeTrade": "guaranteeTrade",
    "Grade": "grade",
    "Remarks": "remark",
    "DateofPurchase": "purchaseDate",
    "GuaranteeDueDate": "warrantyDate",
    "PurchasePrice": "purchasePrice",
    "Inactive": "inActive"
};
const SheetJSFT = ["csv"].map(function (x) { return "." + x; }).join(",");
const make_cols = refstr => {
    let o = [], C = XLSX.utils.decode_range(refstr).e.c + 1;

    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i }
    return o;
};
const ImportCustomers = (props) => {
    const dispatch = useDispatch();
    const createState = useSelector((state) => state.ExportCustomersReducer);
    const ExcelFileRef = React.useRef();
    const classes = useStyles();
    const [AddValues, setAddValues] = React.useState({
        file: {},
        data: []
    });
    const [EditErrors, setEditErrors] = useState({});
    /* useEffect(() => {
            dispatch(refreshReduxStates());
    }, []) */
    
    if (createState.Success && !isEmptyObj(createState.ExportResponse)) {
        toast.success('Added Successfully!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: false,
        });
        props.ModelConfirm()
      } else if (createState.Failure && !isEmptyObj(createState.ExportResponse)) {
        toast.error(createState.ExportResponse.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: false,
        });
        dispatch(refreshReduxStates());
      }
    const handleChange = (e) => {
        const files = e.target.files;
        if (files && files[0]) {
            //var filesize = bytesToSize(files[0].size);
            if(parseFloat(files[0].size) <= 2097152){
                //setAddValues({ ...AddValues, file: files[0] });
                buildTable(files[0]);
            }else{
                toast.warning('The upload file size is more than 2MB', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: false,
                });
            }
        }
    };
    const buildTable = (uploadFile)=>{
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true, cellDates: true });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const newData = XLSX.utils.sheet_to_json(ws).map(row =>
                Object.keys(row).reduce((obj, key) => {
                    let objData = key.replace(/\s/g, "");
                    let keyVal = staticObject[objData];
                    let parm = row[key];
                    if (keyVal == "purchaseDate" || keyVal == "warrantyDate") {
                        parm = row[key];
                    }
                    obj[keyVal] = parm;//row[key];
                    return obj;
                }, {})
            );

            setAddValues({ ...AddValues, data: newData, file: uploadFile })
            //setAddValues({ ...AddValues, data: newData, file: {} })
            //ExcelFileRef.current.value = "";
        };

        if (rABS) {
            reader.readAsBinaryString(uploadFile);
        } else {
            reader.readAsArrayBuffer(uploadFile);
        };
    }
    const submitForm = () => {
       // alert(AddValues.data.length)
        if(AddValues.data.length>0){
            let uploadData = new FormData();
            //uploadData.append('file', new File(AddValues.file, { type: 'text/csv' }));
            let uploadFile = AddValues.file;
            var file = new File([uploadFile], uploadFile.name, {type: "text/csv"});
            uploadData.append( 'file', file);
            dispatch(ExportCustomers(uploadData));
        }else{
            toast.warning("Please upload a file", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: false,
              });
        }
        
    };
    const columns = [
        {
            name: "customerNumber",
            label: "Customer Number",
            options: {
                sort: true,
                filter: false,
                display: true
            }
        },
        {
            name: "customerName",
            label: "Name",
            options: {
                sort: true,
                filter: false,
                display: true
            }
        },
        {
            name: "address",
            label: "Address",
            options: {
                sort: false,
                filter: false,
                display: true
            }
        },
        {
            name: "postalCode",
            label: "Postal Code",
            options: {
                sort: false,
                filter: false,
                display: true
            }
        },
        {
            name: "email",
            label: "Email",
            options: {
                sort: true,
                filter: false,
                display: true
            }
        },
        {
            name: "phoneNumber",
            label: "Phone",
            options: {
                sort: false,
                filter: false,
                display: true
            }
        },
        {
            name: "productDescription",
            label: "Description",
            options: {
                sort: false,
                filter: false,
                display: true
            }
        },
        {
            name: "brand",
            label: "Brand",
            options: {
                sort: true,
                filter: false,
                display: true
            }
        },
        {
            name: "model",
            label: "Model",
            options: {
                sort: true,
                filter: false,
                display: true
            }
        },
        {
            name: "serialNumber",
            label: "Serial",
            options: {
                sort: true,
                filter: false,
                display: true
            }
        },
        {
            name: "guaranteeTrade",
            label: "Guarantee Trade",
            options: {
                sort: true,
                filter: false,
                display: true
            }
        },
        {
            name: "grade",
            label: "Grade",
            options: {
                sort: true,
                filter: false,
                display: true
            }
        },
        {
            name: "remark",
            label: "Remarks",
            options: {
                sort: true,
                filter: false,
                display: true
            }
        },
        {
            name: "purchasePrice",
            label: "Purchase Price",
            options: {
                sort: false,
                filter: false,
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Grid item md={12}>
                            <Typography>{PriceFormat(value)}</Typography>
                        </Grid>
                    )
                }
            }
        },
        {
            name: "purchaseDate",
            label: "Purchase Date",
            options: {
                sort: true,
                filter: false,
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Grid item md={12}>
                            <Typography>{ExportFileData(value)}</Typography>
                        </Grid>
                    )
                }
            }
        },
        {
            name: "warrantyDate",
            label: "Warranty Due Data",
            options: {
                sort: false,
                filter: false,
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Grid item md={12}>
                            <Typography>{ExportFileData(value)}</Typography>
                        </Grid>
                    )
                }
            }
        },
        {
            name: "inActive",
            label: "Status",
            options: {
                sort: false,
                filter: false,
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const status = !value ? "Active" : "InActive";
                    return (
                        <Grid item md={12}>
                            <Typography>{status}</Typography>
                        </Grid>
                    )
                }
            }
        }
    ];

    return (

        <Dialog
            open={props.Status}
            fullWidth={true}
            fullScreen={false}
            maxWidth="lg"
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            scroll={"paper"}
            PaperProps={{
                style: {
                    overflow: 'hidden'
                },
            }}
        >
            <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={4}>
                    <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                        <input
                            ref={ExcelFileRef}
                            type="file"
                            //data-max-size="2048"
                            accept={SheetJSFT}
                            onChange={(e) => {
                                handleChange(e);
                            }}
                            onClick={(e)=>{
                                e.target.value = null
                                setAddValues({ ...AddValues, data: [] });
                            }}
                        />
                        <Typography >
                          Maximum upload size is 2MB
                        </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xl={12} xs={12} spacing={4} >
                        <MUIDataTable
                            title=""
                            data={AddValues.data}
                            columns={columns}
                            options={{
                                sort: true,
                                selectableRows: false,
                                print: false,
                                search: false,
                                download: false,
                                viewColumns: false,
                                responsive: true,
                                filter: false
                            }}
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Buttons
                    close={() => props.ModelCancel()}
                    closeText={"Cancel"}
                    save={() => submitForm()}
                    saveText={"Submit"}
                />
            </DialogActions>
        </Dialog>
    );
}

export default ImportCustomers;