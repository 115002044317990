import React, { useState, useEffect, Component } from 'react';
import {
  Grid, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, makeStyles,
  Switch, FormControlLabel, IconButton, DeleteIcon,
  EditIcon, _, swal, toast, useSelector, useDispatch,
  Checkbox
} from '../../utilities/PackagesList';
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay
} from "../../utilities/ComponentsList";
import { isEmptyObj, AddTime2Date } from "../../utilities/AppFunctions";
const useStyles = makeStyles(theme => ({
  dialogTitle: {

  }
}))
const NewService = (props) => {
  const classes = useStyles();
  const [Addvalues, setAddValues] = React.useState({
    JobType: '',
    JobTypeID: '',
    ServiceDate: '',
    Fee: '',
    Complementry: false
  });
  const [Createerrors, setCreateErrors] = useState({});
  let ParentState = props.parentState;
  let JobTypeList = ParentState.JobTypeList;
  const handleChange = name => event => {
    let targetValue = event.target.value;
    setAddValues({ ...Addvalues, [name]: targetValue });
    setCreateErrors({ ...Createerrors, [name]: targetValue ? false : true })

  };

  const submitForm = () => {
    let srrorObj = {};
    let errorStatus = false;
    Object.keys(Addvalues).map(function (k) {
      if (k === 'Complementry') {
        srrorObj[k] = false;
        errorStatus = errorStatus ? true : false;
      } else if (k === 'Fee') {
        let compStatus = Addvalues.Complementry;
        let dataStatus = Addvalues[k] ? false : true;
        srrorObj[k] = Addvalues[k] ? false : (compStatus ? false : dataStatus);
        errorStatus = errorStatus ? true : (compStatus ? false : dataStatus);
      } else {
        srrorObj[k] = Addvalues[k] ? false : true;
        errorStatus = errorStatus ? true : !Addvalues[k];
      }

    })
    setCreateErrors(srrorObj);
    if (!errorStatus) {
      let Fees = Addvalues.Fee ? Addvalues.Fee : 0;
      let addedService = {
        jobType: Addvalues.JobType.jobType,
        jobTypeId: Addvalues.JobTypeID,
        serviceStatusName: "New",
        serviceStatusId: 1,
        serviceDate: AddTime2Date(Addvalues.ServiceDate),
        fee: parseFloat(Fees).toFixed(2),
        complementary: Addvalues.Complementry
      }
      //console.log(JSON.stringify(addedService))
      props.parentClass.AddedService(addedService)
      //dispatch(AddAdhoc(addObject));
    }
  };

  return (
    <Dialog
      open={props.Status}
      //onClose={()=>this.closeDialog()}
      fullWidth
      maxWidth="sm"
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
    >
      <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <Grid
              container
              spacing={6}
              direction="column"
            >
              <Grid
                item
                lg={12} md={12} sm={12} xl={12} xs={12}
                spacing={6}
              >
                <Dropdown
                  label={"Job Type"}
                  data={JobTypeList}
                  getOptionLabel={option => (option.jobType ? option.jobType : "")}
                  getOptionSelected={(option, value) => option.jobTypeId === value.jobTypeId}
                  value={Addvalues.JobType}
                  ItemChange={(event, newValue) => {
                    setAddValues({
                      ...Addvalues,
                      JobTypeID: newValue ? newValue.jobTypeId : null,
                      JobType: newValue ? newValue : null
                    });
                    setCreateErrors({
                      ...Createerrors,
                      JobTypeID: newValue ? false : true,
                      JobType: newValue ? false : true
                    })
                  }}
                  error={Createerrors.JobTypeID}
                  initialName={"Job Type"}
                  errorMsg={"Please choose job type."}
                />
              </Grid>
              <Grid
                item
                lg={12} md={12} sm={12} xl={12} xs={12}
                spacing={6}
              >
                <InputField
                  label={"Select Date"}
                  type={"date"}
                  required={true}
                  value={Addvalues.ServiceDate}
                  changeText={handleChange('ServiceDate')}
                  error={Createerrors.ServiceDate}
                  initialName={"Select Date"}
                  errorMsg={"Please enter date."}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={6}
              direction="column"
            >
              <Grid
                item
                lg={12} md={12} sm={12} xl={12} xs={12}
                spacing={6}
              >
                <InputField
                  label={"Fee"}
                  type={"number"}
                  required={true}
                  disabled={Addvalues.Complementry}
                  value={Addvalues.Fee}
                  changeText={handleChange('Fee')}
                  error={!Addvalues.Complementry && Createerrors.Fee}
                  initialName={"Fee"}
                  errorMsg={"Please enter fee."}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={6}
              direction="column"
            >
              <Grid
                item
                lg={12} md={12} sm={12} xl={12} xs={12}
                spacing={6}
              >
                <FormControlLabel
                  control={<Checkbox checked={Addvalues.Complementry} onChange={(e) => {
                    let newStatus = !Addvalues.Complementry;
                    setAddValues({
                      ...Addvalues,
                      Complementry: newStatus,
                      Fee: newStatus ? 0 : Addvalues.Fee
                    });
                  }} name="Complementry" color="primary" />}
                  label="Complementry"
                />
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item>
          <Buttons
            close={() => props.parentClass.CloseAddService()}
            closeText={"Cancel"}
            save={() => submitForm()}
            saveText={"Add"}
          />
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default NewService;