import React from "react";
import {
    Grid, TextField, Checkbox, Radio,
    Table, TableBody, TableCell, TableHead,
    TableContainer, TableRow, Paper, withStyles,
    Typography, Tooltip, ReactToPrint
} from '../../utilities/PackagesList';
import {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown,
    Buttons, LoadingOverlay, PasswordField
} from "../../utilities/ComponentsList";
import { capsFirstLetter } from "../../utilities/AppFunctions";
import styles from "../../utilities/StylesClass";


class FormTable extends React.Component {
    getTitles = (props, cls) => {
        let rowLength = parseInt(props.length);
        const titleDefault = 50;
        let fieldWidth = rowLength > 1 ? parseInt(50 / (rowLength - 1)) : 50;
        return (
            <TableRow width="100%" className={[cls.TableRow]}>
                {props.map((data, index) => {
                    let flexWidth = index == 0 ? titleDefault + "%" : fieldWidth + "%";
                    return (
                        <TableCell width={flexWidth.toString()} className={[cls.TableCell]}>
                            <Grid md={12}>
                            <Typography className={[cls.boldText7]}>{data}</Typography>
                            </Grid>
                        </TableCell>
                    )
                })
                }
    
            </TableRow>
        )
    }
    getSubFields = (fieldValue, fieldtype, changeFunction, SubChildIndex, ParentIndex, ChildIndex, cls) => {
        fieldValue = fieldValue ? fieldValue : "NA";
        switch (fieldtype) {
            case 'string':
                return (
                    <Typography >{fieldValue}</Typography>
                );
            case 'checkbox':
                return (
                    <Checkbox
                        checked={fieldValue}
                        disabled={true}
                        color="primary"
                    />
                );
            case 'radio':
                return (
                    <Radio
                        checked={fieldValue}
                        disabled={true}
                        color="primary"
                    />
                );
            case 'numeric':
                return (
                    <Typography >{fieldValue}</Typography>
                );
            default:
                return null;
        }
    }
    getFields = (singleItem, itemsLength, changeFunction, SubChildIndex, ParentIndex, ChildIndex, cls, HeadSupport = false) => {
        let rowLength = parseInt(itemsLength);
        const titleDefault = "50%";
        let fieldWidth = rowLength > 1 ? parseInt(50 / (rowLength - 1)) : "50%";
        fieldWidth = HeadSupport ? "50%" : fieldWidth+"%";
        switch (singleItem.field) {
            case 'label':
                return (
                    <TableCell className={[cls.TableCell]} width={titleDefault.toString()} >
                        <Typography >{singleItem.title}</Typography>
                    </TableCell >
                );
            case 'text':
                return (
                    <TableCell className={[cls.TableCell]} width={fieldWidth.toString()}>
                        <Typography >{singleItem.value}</Typography>
                    </TableCell>
                );
            case 'textinput':
                return (
                    <TableCell className={[cls.TableCell]} width={fieldWidth.toString()}>
                        {this.getSubFields(singleItem.value, singleItem.type, changeFunction, SubChildIndex, ParentIndex, ChildIndex, cls)}
                    </TableCell>
                );
            case 'boolean':
                return (
                    <TableCell className={[cls.TableCell]} width={fieldWidth.toString()}>
                        {this.getSubFields(singleItem.value, singleItem.type, changeFunction, SubChildIndex, ParentIndex, ChildIndex, cls)}
                    </TableCell>
                );
            default:
                return null;
        }
    }
    getHFields = (singleItem, itemsLength, changeFunction, SubChildIndex, ParentIndex, ChildIndex, cls, HeadSupport = false) => {
        let rowLength = parseInt(itemsLength);
        const titleDefault = 4;
        let fieldWidth = rowLength > 1 ? parseInt(8 / (rowLength - 1)) : 1;
        fieldWidth = HeadSupport ? 4 : fieldWidth;
        switch (singleItem.field) {
            case 'label':
                return (
                    <Typography className={[cls.boldText]}>{singleItem.title}</Typography>
                );
            case 'textinput':
                return (
                    this.getSubFields(singleItem.value, singleItem.type, changeFunction, SubChildIndex, ParentIndex, ChildIndex, cls)
                );
            default:
    
                return null;
        }
    }
    isOdd = (n) => {
        return Math.abs(n % 2) == 1;
    }
    HeaderRender = (parentProps, HeadProps, cls) => {
        return HeadProps.map((pData, pIndex) => {
            return (
                <Grid md={12}>
                    {
                        pData.body.map((childdata, childindex) => {
                            let ChildIndex = childindex;
                            let childLength = childdata.items.length;
                            return (
                                <TableRow className={[cls.TableRow]}>
                                    {childdata.items.map((subdata, subindex) => {
                                        let SubChildIndex = subindex;
                                        return (
                                            <TableCell width="50%" className={[cls.TableCell]}>
                                                {this.getHFields(subdata, childLength, parentProps.onDataChange, SubChildIndex, pIndex, ChildIndex, cls, true)}
                                            </TableCell>
                                        )
                                    })
                                    }
                                </TableRow>
                            )
                        })
                    }
                </Grid>
            )
        })
    
    }
    render() {
        const { classes, input, Type } = this.props;
        let FormList = input;
        let formtypeSelected = Type;
        let SelecFormData = [];
        let inputData = [];
        if (formtypeSelected && FormList.length > 0) {
            let Formindex = FormList.findIndex(x => x.formtype === formtypeSelected);
            SelecFormData = FormList[Formindex];
            inputData = SelecFormData;
        }
        return (
            <TableContainer >
                <Table size="small" style={{tableLayout: 'auto'}} >
                    {formtypeSelected && FormList.length > 0 ?
                        <TableBody >
                            {this.HeaderRender(this.props, inputData.header, classes)}
                            {
                                inputData.body.map((item, index) => {
                                    let ParentIndex = index;
                                    let bodyLength = item.body.length;
                                    return (
                                        <div >
                                            {index === 0 ? this.getTitles(item.title, classes) : null}
                                            {item.body.map((childdata, childindex) => {
                                                let ChildIndex = childindex;
                                                let childLength = childdata.items.length;
                                                return (
                                                    <TableRow width="100%" className={[classes.TableRow]} >
                                                        {childdata.items.map((subdata, subindex) => {
                                                            let SubChildIndex = subindex;
                                                            return this.getFields(subdata, childLength, null, SubChildIndex, ParentIndex, ChildIndex, classes)
                                                        })
                                                        }
                                                    </TableRow>
                                                )
                                            })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </TableBody>
                        :
                        <TableBody />
                    }

                </Table>
            </TableContainer>
        )
    }
    /* const classes = useStyles();
    let FormList = props.input;
    let formtypeSelected = props.Type;
    if (formtypeSelected && FormList.length > 0) {
        let Formindex = FormList.findIndex(x => x.formtype === formtypeSelected);
        let SelecFormData = FormList[Formindex];
        let inputData = SelecFormData;
        return (
            <TableContainer component={Paper} >
                <Table size="small">
                    <TableBody >
                        {HeaderRender(props, inputData.header, classes)}
                        {
                            inputData.body.map((item, index) => {
                                let ParentIndex = index;
                                let bodyLength = item.body.length;
                                return (
                                    <div >
                                        {index === 0 ? getTitles(item.title, classes) : null}
                                        {item.body.map((childdata, childindex) => {
                                            let ChildIndex = childindex;
                                            let childLength = childdata.items.length;
                                            return (
                                                <TableRow width="100%">
                                                    {childdata.items.map((subdata, subindex) => {
                                                        let SubChildIndex = subindex;
                                                        return getFields(subdata, childLength, props.onDataChange, SubChildIndex, ParentIndex, ChildIndex, classes)
                                                    })
                                                    }
                                                </TableRow>
                                            )
                                        })
                                        }
                                    </div>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        );
    } else {
        return (
            <Grid />
        )
    } */

}
export default withStyles(styles)(FormTable);