import React, { useState, useEffect, Component } from 'react';
import {
  Grid, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, makeStyles,
  Switch, FormControlLabel, IconButton, DeleteIcon,
  EditIcon, _, swal, toast, useSelector, useDispatch
} from '../../utilities/PackagesList';
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay
} from "../../utilities/ComponentsList";
import { isEmptyObj } from "../../utilities/AppFunctions";
import { AddAdhocService } from '../../api/Services';
const useStyles = makeStyles(theme => ({
  dialogTitle: {

  }
}))
const CreateAdhoc = (props) => {
  const dispatch = useDispatch();
  const createState = useSelector((state) => state.AddAdhocServiceReducer);
  const classes = useStyles();
  const [Addvalues, setAddValues] = React.useState({
    Product: '',
    Cost: '',
    SKU: '',
  });
  const [Createerrors, setCreateErrors] = useState({});

  if (createState.Success && !isEmptyObj(createState.AddingResponse)) {
    toast.success('Added Successfully!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: false,
    });
    props.parentClass.AddedAdhoc()
  } else if (createState.Failure && !isEmptyObj(createState.AddingResponse)) {
    toast.error(createState.AddingResponse.message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: false,
    });
  }
  const handleChange = name => event => {
    let targetValue = event.target.value;
    setAddValues({ ...Addvalues, [name]: targetValue });
    setCreateErrors({ ...Createerrors, [name]: targetValue ? false : true })

  };

  const submitForm = () => {
    let srrorObj = {};
    let errorStatus = false;
    Object.keys(Addvalues).map(function (k) {
      srrorObj[k] = Addvalues[k] ? false : true;
      errorStatus = errorStatus ? true : !Addvalues[k];
    })
    setCreateErrors(srrorObj);
    if (!errorStatus) {
      let addObject = {
        product: Addvalues.Product,
        cost: Addvalues.Cost,
        sku: Addvalues.SKU
      }
      console.log(JSON.stringify(addObject))
      dispatch(AddAdhocService(addObject));
    }
  };

  return (
    <Dialog
      open={props.Status}
      fullWidth
      maxWidth="sm"
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
    >
      <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <Grid
              container
              spacing={4}
              direction="column">
              <Grid
                item
                lg={12} md={12} sm={12} xl={12} xs={12}
                spacing={4}
              >
                <InputField
                  label={"Product/Service"}
                  type={"text"}
                  required={true}
                  value={Addvalues.Product}
                  changeText={handleChange('Product')}
                  error={Createerrors.Product}
                  initialName={"Product/Service"}
                  errorMsg={"Please enter product/service."}
                />
              </Grid>
              <Grid
                item
                lg={12} md={12} sm={12} xl={12} xs={12}
                spacing={4}
              >
                <InputField
                  label={"SKU"}
                  type={"text"}
                  required={true}
                  value={Addvalues.SKU}
                  changeText={handleChange('SKU')}
                  error={Createerrors.Product}
                  initialName={"SKU"}
                  errorMsg={"Please enter sku."}
                />
              </Grid>
              <Grid
                item
                lg={12} md={12} sm={12} xl={12} xs={12}
                spacing={4}
              >
                <InputField
                  label={"Cost"}
                  type={"number"}
                  required={true}
                  value={Addvalues.Cost}
                  changeText={handleChange('Cost')}
                  error={Createerrors.Cost}
                  initialName={"Cost"}
                  errorMsg={"Please enter cost."}
                />
              </Grid>
            </Grid>

          </Grid>
          <Buttons
            close={() => props.parentClass.CloseAdd()}
            closeText={"Cancel"}
            save={() => submitForm()}
            saveText={"Create"}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default CreateAdhoc;