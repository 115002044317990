import React, { useState, useEffect, Component } from "react";
import {
  Grid, TextField, Button, Switch,
  FormControlLabel, IconButton, DeleteIcon,
  EditIcon, MUIDataTable, Autocomplete, connect,
  _, swal, toast, Typography
} from "./../../utilities/PackagesList";
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay, ButtonIcon
} from "./../../utilities/ComponentsList";
import { isEmptyObj, UnChangeObj } from "./../../utilities/AppFunctions";
import { getTeams, getUnAssigned, DeleteTeam, getTeamTypes } from './../../api/Team';
import { refreshReduxStates } from './../../api/Refresh';
import { LoaderStatus } from './../../api/Loading';
import CreateTeams from "./CreateTeams";
import EditTeams from "./EditTeams";

let getTeamState = false;
class Teams extends Component {

  constructor(props) {
    super(props);
    this.state = {
      DialogState: false,
      DeleteID: null,
      EditID: null,
      SelectedIndex: null,
      ModelType: null,
      ModelState: false,
      EditModelState: false,
      TeamsLists: [],
      UnAssignedSV: [],
      TeamTypeLists:[],
      isLoading: true,
      getCalls: [1, 1, 1],
      postCalls: [0, 0, 0],
      SelectingList:[],
    }
    props.getTeamTypes();
    props.getTeams();
    props.getUnAssigned();
  }
  UNSAFE_componentWillReceiveProps = (props) => {
    if (this.state.getCalls[0]) {

      if (props.TeamListSuccess) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = getTeamState ? false : succed.includes(1);
        getTeamState = false;
        this.setState({
          TeamsLists: props.TeamLists,
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.TeamListFailure) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = getTeamState ? false : succed.includes(1);
        getTeamState = false;
        this.setState({
          TeamsLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.getCalls[1]) {

      if (props.UnAssignedSuccess) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          UnAssignedSV: props.UnAssignedSVList,
          getCalls: succed,
          isLoading: loaderStatus
        })
      } else if (props.UnAssignedFailure) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          UnAssignedSV: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.getCalls[2]) {
      if (props.TeamTypeSuccess) {
        let succed = this.state.getCalls;
        succed[2] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          TeamTypeLists: props.TeamTypeList,
          getCalls: succed,
          isLoading: loaderStatus
        })
      } else if (props.TeamTypeFailure) {
        let succed = this.state.getCalls;
        succed[2] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          TeamTypeLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.postCalls[2]) {
      if (props.DeleteTeamSuccess) {
        let updatedList = this.state.TeamsLists;
        updatedList.splice(this.state.SelectedIndex, 1);
        this.setState({
          postCalls: [0, 0, 0],
          isLoading: false,
          DialogState: false,
          DeleteID: null,
          SelectedIndex: null,
          TeamsLists: updatedList,
          getCalls: [1, 1, 0],
        }, () => {
          this.props.getTeams();
          this.props.getUnAssigned();
          //this.props.refreshReduxStates();
          toast.success('Deleted Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      } else if (props.DeleteTeamFailure) {
        this.setState({
          postCalls: [0, 0, 0],
          isLoading: false,
          DialogState: false,
          DeleteID: null,
          SelectedIndex: null,
          getCalls: [0, 0, 0],
        }, () => {
          toast.error(props.DeleteTeamResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      }
    }
  }
  editPress = (id, index) => {
    let TeamsData = UnChangeObj(this.state.TeamsLists);
    //let Selected = TeamsData[index];
    this.setState({
      EditModelState: true,
      EditID: id,
      ModelType: "Edit",
      SelectingList: TeamsData,
      SelectedIndex: index
    })
  }
  CloseEdit = () => {
    this.setState({
      EditModelState: false,
      ModelType: null,
      EditID: null,
      SelectingList:[],
      SelectedIndex: null,
    })
  }
  EditedTeam = (EditedObject) => {
    let ListData = this.state.TeamsLists;
    let EditingID = this.state.EditID;
    let ItemIndex = ListData.findIndex(itm => itm.id === EditingID);
    this.setState({
      EditModelState: false,
      ModelType: null,
      EditID: null,
      SelectedIndex: null,
      isLoading: true,
      SelectingList:[],
      getCalls: [1, 1, 0],
    }, () => {
      //this.props.refreshReduxStates();
      this.props.getTeams();
      this.props.getUnAssigned();
    })
  }
  deletePress = (id, index) => {
    //let TeamsDatas = this.state.TeamsLists;
   // let Selected = TeamsDatas[index];
    this.setState({
      DialogState: !this.state.DialogState,
      DeleteID: id,
      SelectedIndex: index
    })
  }
  DeleteClose = () => {
    this.setState({
      DialogState: !this.state.DialogState,
      DeleteID: null,
      SelectedIndex: null,
    })
  }

  DeleteConfirm = () => {
    this.setState({
      isLoading: true,
      postCalls: [0, 0, 1]
    }, () => {
      this.props.DeleteTeam(this.state.DeleteID);
    })
  }
  AddingTeam = () => {
    this.setState({
      ModelState: true,
      ModelType: "Add",
    })
  }
  
  CloseCreate = () => {
    this.setState({
      ModelState: !this.state.ModelState,
      ModelType: null,
      EditID: null,
      SelectedIndex: null,
    })
  }
  AddedTeam = () => {
    this.setState({
      ModelState: !this.state.ModelState,
      ModelType: null,
      EditID: null,
      SelectedIndex: null,
      isLoading: true,
      getCalls: [1, 1, 0],
    }, () => {
      this.props.getTeams();
      this.props.getUnAssigned();
    })
  }
  
  render() {
    const columns = [
      {
        name: "id",
        label: "ID",
        options: {
          filter: false,
          display: false
        }
      },
      {
        name: "",
        label: "SNO",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                  <Typography>{tableMeta.rowIndex+1}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "teamName",
        label: "Team Name",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "teamTypeName",
        label: "Team Type",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "vehicleNumber",
        label: "Vehicle Number",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "assignedStaff",
        label: "Staff Name",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                {value.map((staffs, index) => (
                  <Typography>{staffs.name}</Typography>
                ))}

              </Grid>
            )
          }
        }
      },
      {
        name: "",
        label: "Actions",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <ButtonIcon
                EditTitle={"Edit Team"}
                DeleteTitle={"Delete Team"}
                Edit={() => this.editPress(tableMeta.rowData[0], tableMeta.rowIndex)}
                Delete={() => this.deletePress(tableMeta.rowData[0], tableMeta.rowIndex)}
              />
            )
            return (
              <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                <IconButton
                  onClick={() => this.editPress(tableMeta.rowData[0], tableMeta.rowIndex)}
                  aria-label="edit"
                  size="small"
                  color="primary">
                  <EditIcon />
                </IconButton>&nbsp;&nbsp;
                    <IconButton
                  onClick={() => this.deletePress(tableMeta.rowData[0], tableMeta.rowIndex)}
                  aria-label="delete"
                  size="small"
                  color="secondary">
                  <DeleteIcon />
                </IconButton>
              </Grid>
            )
          }
        }
      }
    ];


    return (
      <>
        {/*  <PageTitle title="Teams" /> */}
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <MUIDataTable
              title="Teams List"
              data={this.state.TeamsLists}
              columns={columns}
              options={{
                sort: true,
                selectableRows: false,
                print: false,
                download: false,
                viewColumns: false,
                filter: false,
                responsive:true,
                customToolbar: () => {
                  return (
                    <CustomToolbar title={"Add Team"} iconOnPress={() => this.AddingTeam()} />
                  );
                }
              }}
            />
          </Grid>
          <AlertDialog
            Status={this.state.DialogState}
            DialogConfirm={() => { this.DeleteConfirm() }}
            DialogCancel={() => { this.DeleteClose() }}
            Title={"Delete Team"}
            Content={"Are you sure, you want to delete the team ?"}
          />
          {this.state.ModelState ? <CreateTeams
            Title={"Add Team"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.ModelState}
            ModelConfirm={this.AddedTeam}
            ModelCancel={this.CloseCreate}
          /> : null}
          {this.state.EditModelState ? <EditTeams
            Title={"Edit Team"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.EditModelState}
            ModelConfirm={this.EditedTeam}
            ModelCancel={this.CloseEdit}
          /> : null}
          <LoadingOverlay Status={this.state.isLoading} />
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    TeamTypeSuccess: state.getTeamTypesReducer.Success,
    TeamTypeFailure: state.getTeamTypesReducer.Failure,
    TeamTypeList: state.getTeamTypesReducer.TeamTypes,

    TeamListSuccess: state.getTeamsReducer.Success,
    TeamListFailure: state.getTeamsReducer.Failure,
    TeamLists: state.getTeamsReducer.TeamList,

    UnAssignedSuccess: state.getUnAssignedReducer.Success,
    UnAssignedFailure: state.getUnAssignedReducer.Failure,
    UnAssignedSVList: state.getUnAssignedReducer.UnAssignedList,

    DeleteTeamSuccess: state.DeleteTeamReducer.Success,
    DeleteTeamFailure: state.DeleteTeamReducer.Failure,
    DeleteTeamResponse: state.DeleteTeamReducer.DeleteResponse,

    UserDetails: state.LoginReducer.UserDetails,
    LoaderState: state.LoaderReducer.State
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTeamTypes: () => dispatch(getTeamTypes()),
    getUnAssigned: () => dispatch(getUnAssigned()),
    getTeams: () => dispatch(getTeams()),
    DeleteTeam: (TeamID) => dispatch(DeleteTeam(TeamID)),
    refreshReduxStates: () => dispatch(refreshReduxStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
