import React, { useState, useEffect, Component } from 'react';
import {
    Grid, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, makeStyles,
    Switch, FormControlLabel, IconButton, DeleteIcon,
    EditIcon, _, swal, toast, useSelector, useDispatch
} from '../../utilities/PackagesList';
import {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown,
    Buttons, LoadingOverlay
} from "../../utilities/ComponentsList";
import { isEmptyObj } from "../../utilities/AppFunctions";
import { UpdateTimeSlots } from '../../api/Services';
const useStyles = makeStyles(theme => ({
    dialogTitle: {

    }
}))
const Edit = (props) => {
    const dispatch = useDispatch();
    const EditState = useSelector((state) => state.UpdateTimeSlotsReducer);
    const classes = useStyles();
    const [Editvalues, setEditValues] = useState({
        SlotName: '',
        SlotStart: '',
        SlotEnd: ''
    });
    const [errors, setErrors] = useState({});
    let userSelection = props.parentState.SelectedIndex;
    let EditingID = props.parentState.EditID;
    let Lists = props.parentState.TimeSlotLists ? props.parentState.TimeSlotLists : [];
    let editDetails = props.parentState.EditID ? Lists[userSelection] : null;
    useEffect(() => {
        if (EditingID) {
            setEditValues({
                ...Editvalues,
                SlotName: editDetails.name,
                SlotStart: editDetails.startTime,
                SlotEnd: editDetails.endTime
            })
        }

    }, [EditingID])
   
    if (EditState.Success && !isEmptyObj(EditState.UpdateResponse)) {
        toast.success('Updated Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
        let EditedObject = {
            "id": parseInt(EditingID),
            "name": Editvalues.SlotName,
            "startTime": Editvalues.SlotStart,
            "endTime": Editvalues.SlotEnd
        }
        props.ModelConfirm(EditedObject)
    } else if (EditState.Failure && !isEmptyObj(EditState.UpdateResponse)) {
        toast.error(EditState.UpdateResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
    }
    const handleChange = name => event => {
        let targetValue = event.target.value;
        setEditValues({ ...Editvalues, [name]: targetValue });
        setErrors({ ...errors, [name]: targetValue ? false : true })
    };
    const saveEdit = () => {
        let srrorObj = {};
        let errorStatus = false;
        Object.keys(Editvalues).map(function (k) {
            srrorObj[k] = Editvalues[k] ? false : true;
            errorStatus = errorStatus ? true : !Editvalues[k];
        })

        setErrors(srrorObj);

        if (!errorStatus) {
            let updateObject = {
                name: Editvalues.SlotName,
                startTime: Editvalues.SlotStart,
                endTime: Editvalues.SlotEnd
            }

            dispatch(UpdateTimeSlots(updateObject, EditingID));
        }
    };

    return (
        <Dialog
            open={props.Status}
            fullWidth
            maxWidth="sm"
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
        >
            <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={4}>
                    <Grid item md={12}>
                        <Grid
                            container
                            spacing={4}
                            direction="column"
                        >
                            <Grid item md={12} spacing={4}>
                                <InputField
                                    label="Name"
                                    type={"text"}
                                    required={true}
                                    value={Editvalues.SlotName}
                                    changeText={handleChange('SlotName')}
                                    error={errors.SlotName}
                                    initialName={"Name"}
                                    errorMsg={"Please enter name."}
                                />
                            </Grid>
                            <Grid item md={12} spacing={4}>
                                <InputField
                                    label={"Start Time"}
                                    type={"time"}
                                    required={true}
                                    value={Editvalues.SlotStart}
                                    changeText={handleChange('SlotStart')}
                                    error={errors.SlotStart}
                                    initialName={"Start Start"}
                                    errorMsg={"Please enter start time."}
                                />
                            </Grid>
                            <Grid item md={12} spacing={4}>
                                <InputField
                                    label={"Start End"}
                                    type={"time"}
                                    required={true}
                                    value={Editvalues.SlotEnd}
                                    changeText={handleChange('SlotEnd')}
                                    error={errors.SlotEnd}
                                    initialName={"Start End"}
                                    errorMsg={"Please enter end time."}
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Buttons
                        close={() => props.ModelCancel()}
                        closeText={"Cancel"}
                        save={() => saveEdit()}
                        saveText={"Save"}
                    />
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default Edit;