import React, { useState, useEffect, Component } from 'react';
import {
    Grid, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, makeStyles,
    Switch, FormControlLabel, IconButton, DeleteIcon,
    EditIcon, _, swal, toast, useSelector, useDispatch
} from './../../utilities/PackagesList';
import {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown,
    Buttons, LoadingOverlay
} from "./../../utilities/ComponentsList";
import { isEmptyObj } from "./../../utilities/AppFunctions";
import { UpdatePaymentTypes } from './../../api/Services';
const useStyles = makeStyles(theme => ({
    dialogTitle: {

    }
}))
const Edit = (props) => {
    const dispatch = useDispatch();
    const EditState = useSelector((state) => state.UpdatePaymentTypesReducer);
    const classes = useStyles();
    const [Editvalues, setEditValues] = useState({
        Name: '',
        Code: ''
    });
    const [errors, setErrors] = useState({});
    let ParentState = props.parentState;
    let EditingID = ParentState.EditID;
    let ItemIndex = ParentState.SelectedIndex;
    let Types = ParentState.SelectingList ? ParentState.SelectingList : [];
    Types = _.filter(Types, { 'id': EditingID });  
    let editDetails = Types.length>0 ? Types[0] : null;
    //let editDetails = EditingID ? Types[ItemIndex] : null;
    useEffect(() => {
        if (EditingID) {
            setEditValues({
                ...Editvalues,
                Name: editDetails.name,
                Code: editDetails.code
            })
        }

    }, [EditingID])

    if (EditState.Success && !isEmptyObj(EditState.UpdateResponse)) {
        toast.success('Updated Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
        let EditedObject = {
            "id": parseInt(EditingID),
            "name": Editvalues.Name,
            "code": Editvalues.Code
        }
        props.ModelConfirm(EditedObject)
    } else if (EditState.Failure && !isEmptyObj(EditState.UpdateResponse)) {
        toast.error(EditState.UpdateResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
    }
    const handleChange = name => event => {
        let targetValue = event.target.value;
        setEditValues({ ...Editvalues, [name]: targetValue });
        setErrors({ ...errors, [name]: targetValue ? false : true })
    };
    const saveEdit = () => {
        let srrorObj = {};
        let errorStatus = false;
        Object.keys(Editvalues).map(function (k) {
            srrorObj[k] = Editvalues[k] ? false : true;
            errorStatus = errorStatus ? true : !Editvalues[k];
        })

        setErrors(srrorObj);

        if (Editvalues.Name && Editvalues.Code) {
            let updateObject = {
                name: Editvalues.Name,
                code: Editvalues.Code
            }
            dispatch(UpdatePaymentTypes(updateObject, EditingID));
        }
    };

    return (
        <Dialog
            open={props.Status}
            fullWidth
            maxWidth="sm"
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
        >
            <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={4}>
                    <Grid item lg={12} md={12} sm={12} xl={12} xs={12} spacing={4}>
                        <Grid
                            container
                            spacing={4}
                            direction="column"
                        >
                            <Grid item lg={12} md={12} sm={12} xl={12} xs={12} spacing={4}>
                                <InputField
                                    label="Payment Type Name"
                                    type={"text"}
                                    required={true}
                                    value={Editvalues.Name}
                                    changeText={handleChange('Name')}
                                    error={errors.Name}
                                    initialName={"Payment Type Name"}
                                    errorMsg={"Please enter payment type name."}
                                />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xl={12} xs={12} spacing={4}>
                                <InputField
                                    label="Payment Type Code"
                                    type={"text"}
                                    required={true}
                                    value={Editvalues.Code}
                                    changeText={handleChange('Code')}
                                    error={errors.Code}
                                    initialName={"Payment Type Code"}
                                    errorMsg={"Please enter payment type code."}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Buttons
                        close={() => props.parentClass.CloseEdit()}
                        closeText={"Cancel"}
                        save={() => saveEdit()}
                        saveText={"Save"}
                    />
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default Edit;