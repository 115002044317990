import React, { useState, useEffect, Component } from "react";
import {
  Grid, TableFooter, Button, TableRow,
  TableCell, IconButton, DeleteIcon,
  EditIcon, MUIDataTable, Autocomplete, connect,
  _, swal, toast, Typography, Checkbox, Tab,
  TabPanel, AppBar, TabContext, TabList, withStyles,
  CardMedia, SRLWrapper, SimpleReactLightbox
} from "../../utilities/PackagesList";
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay, ButtonIcon
} from "../../utilities/ComponentsList";
import {
  isEmptyObj, dateTimeFormating, todayDate,
  LastDayMonth, DatePlusYMD, AMPM,
  convertDateDMY, DecimalFormat
} from "../../utilities/AppFunctions";
import { getTeams } from '../../api/Team';
import { GetServiceReport, GetJobTypes } from '../../api/Services';
import { refreshReduxStates } from '../../api/Refresh';
import styles from "../../utilities/StylesClass";
import BaseDetails from './../../utilities/BaseDetails.json';
import { collectionReport, jobtypeReport, paymentReport } from '../../utilities/MockData';

class TechReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ReportList: [],
      JobTypesLists: [],
      StartDate: todayDate(),
      EndDate: DatePlusYMD(10),
      TeamSelectedID: "",
      TeamSelected: "",
      isLoading: true,
      getCalls: [1, 0, 1],
      TeamsLists: []
    }
    props.getTeams(1);
    props.GetJobTypes();
  }

  UNSAFE_componentWillReceiveProps = (props) => {
    if (this.state.getCalls[0]) {
      if (props.TeamListSuccess) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        let newTeam = {
          "id": 0,
          "teamName": "All",
          "vehicleNumber": "",
          "vehicelId": "",
          "assignedStaff": [],
          "teamTypeId": 0,
          "teamTypeName": "all"
        };
        let Teams = props.TeamLists;
        Teams.unshift(newTeam);
        this.setState({
          TeamsLists: props.TeamLists,
          isLoading: loaderStatus,
          TeamSelectedID: Teams[0].id,
          TeamSelected: Teams[0],
          getCalls: succed
        })
      } else if (props.TeamListFailure) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          TeamsLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.getCalls[1]) {
      if (props.SReportSuccess) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          ReportList: props.SReportLists,
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.SReportFailure) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          ReportList: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.getCalls[2]) {
      if (props.JobTypeSuccess) {
        let succed = this.state.getCalls;
        succed[2] = 0;
        succed[1] = 1;
        let loaderStatus = succed.includes(1);
        let JobLists = _.orderBy(props.JobTypesList, ['jobTypeId'], ['asc']);
        this.setState({
          JobTypesLists: JobLists,
          isLoading: loaderStatus,
          getCalls: succed
        }, () => {
          let filterObj = {
            Type: "techProdSummary",
            TeamID: "",
            StartDate: todayDate(),
            EndDate: DatePlusYMD(10)
          };
          props.GetServiceReport(filterObj);
        })
      } else if (props.JobTypeFailure) {
        let succed = this.state.getCalls;
        succed[2] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          JobTypesLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
  }

  searchClick = () => {
    if (!this.state.StartDate && !this.state.EndDate && !this.state.TeamSelectedID) {
      toast.warning('Please choose any one filter option!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      });
      return;
    }
    let Start = this.state.StartDate ? this.state.StartDate : "";
    let End = this.state.EndDate ? this.state.EndDate : "";
    let TeamSelectedID = this.state.TeamSelectedID ? this.state.TeamSelectedID : "";
    let filterObj = {
      Type: "techProdSummary",
      TeamID: TeamSelectedID,
      StartDate: Start,
      EndDate: End
    };
    this.setState({
      isLoading: true,
      getCalls: [0, 1, 0]
    })
    this.props.GetServiceReport(filterObj);
  }

  clearSearchClick = () => {
    this.setState({
      ReportList: [],
      StartDate: "",//DateMinusYMD(5),
      EndDate: "",
      TeamSelectedID: "",
      TeamSelected: ""
    });

  }
 /*  renderFooter=(jobList,reportData,opts,classes)=>{
    let tableFooter = [];
    jobList.forEach(function (val, index) {
      let RJobs = reportData.jobTypes;
      RJobs.forEach(function (rval, rindex) {
        let Completed = _.sumBy(crow, function (o) { return o.completed ? parseInt(o.completed) : 0; })
        tableFooter.push(<TableCell>{Completed}</TableCell>)
      })
    });
    RJobs.forEach(function (rval, rindex) {
      let Cancelled = _.sumBy(crow, function (o) { return o.cancelled ? parseInt(o.cancelled) : 0; })
    })

    
  } */
  render() {
    const { classes } = this.props;
    const jobList = this.state.JobTypesLists;
    const reportData = this.state.ReportList;
    let columnList = [
      {
        name: "serviceDate",
        label: "Date",
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true,
          setCellProps: () => ({ style: { minWidth: "100px" } }),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (convertDateDMY(value))
          }
        }
      }
    ];
    jobList.forEach(function (val, index) {
      columnList.push({
        name: "jobTypes",
        label: val.jobCode,
        options: {
          filter: true,
          sort: true,
          display: true,
          print: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            let Jobs = _.filter(value, { "jobTypeCode": val.jobCode });
            let total = 0;
            if (Jobs.length > 0) {
              total = Jobs[0].completed;
            }
            return (total)
          }
        }
      })
    });
    columnList.push({
      name: "jobTypes",
      label: "Cancelled",
      options: {
        filter: true,
        sort: true,
        display: true,
        print: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          let Cancelled = _.sumBy(value, function (o) { return o.cancelled ? parseInt(o.cancelled) : 0; })
          return (Cancelled)
        }
      }
    })

    return (
      <>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <Grid container spacing={1} lg={12} md={12} sm={12} xl={12} xs={12} direction={"row"}>
              <Grid item lg={2} md={2} sm={2} xl={2} xs={2}>
                <InputField
                  label={"Start Date"}
                  type={"date"}
                  required={false}
                  value={this.state.StartDate}
                  changeText={(data) => {
                    this.setState({
                      StartDate: data ? data.target.value : null,
                      EndDate: data ? LastDayMonth(data.target.value) : null
                    })
                  }}
                  initialName={"Start Date"}
                  errorMsg={"Please enter start date."}
                />
              </Grid>
              <Grid item lg={2} md={2} sm={2} xl={2} xs={2}>
                <InputField
                  label={"End Date"}
                  type={"date"}
                  required={false}
                  value={this.state.EndDate}
                  changeText={(data) => { this.setState({ EndDate: data ? data.target.value : null }) }}
                  // error={Createerrors.Date}
                  initialName={"End Date"}
                  errorMsg={"Please enter end date."}
                />
              </Grid>
              <Grid item lg={2} md={2} sm={2} xl={2} xs={2}>
                <Dropdown
                  label={"Select Team"}
                  data={this.state.TeamsLists}
                  getOptionLabel={option => (option.teamName ? option.teamName : "")}
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={this.state.TeamSelected}
                  ItemChange={(event, newValue) => {
                    this.setState({
                      TeamSelectedID: newValue ? newValue.id : null,
                      TeamSelected: newValue ? newValue : null
                    })
                  }}
                  //error={Createerrors.TeamSelectedID}
                  initialName={"Team"}
                  errorMsg={"Please choose team."}
                />
              </Grid>
              <Grid item lg={1} md={1} sm={1} xl={1} xs={1}>
                <Button
                  onClick={() => this.clearSearchClick()}
                  className={classes.buttonCancel}
                  variant="contained" color="primary">
                  Clear
                  </Button>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xl={1} xs={1}>
                <Button
                  onClick={() => this.searchClick()}
                  className={classes.buttonSuccess}
                  variant="contained" color="primary">
                  Search
                  </Button>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xl={12} xs={12} style={{ marginTop: 10 }}>
              <MUIDataTable
                title=""
                data={this.state.ReportList}
                columns={columnList}
                options={{
                  downloadOptions: {
                    filename: "Tech_Report.csv",
                    filterOptions: {
                      useDisplayedColumnsOnly: true,
                      useDisplayedRowsOnly: true
                    }
                  },
                  sort: true,
                  selectableRows: false,
                  selectableRowsOnClick: false,
                  responsive: true,
                  print: true,
                  download: true,
                  viewColumns: false,
                  filter: false,
                  onDownload: (buildHead, buildBody, columns, data) => {
                    data.forEach(function (prow, pIndex){
                      prow.data.forEach(function (crow, cindex) {
                        let check = Array.isArray(crow);
                        if(check){
                          let totalJobs = 1;
                          jobList.forEach(function (val, index) {
                            let Jobs = _.filter(crow, { "jobTypeCode": val.jobCode });
                            let total = 0;
                            if (Jobs.length > 0) {
                              total = Jobs[0].completed;
                            }
                            data[pIndex].data[index+1] = total;
                            totalJobs++;
                          });
                          let Cancelled = _.sumBy(crow, function (o) { return o.cancelled ? parseInt(o.cancelled) : 0; })
                          data[pIndex].data[totalJobs] = Cancelled;
                        }else{
                          data[pIndex].data[cindex] = crow;
                        }
                      })
                  });
                    
                    /* let downloadData = [];
                    data.forEach(function (prow, pIndex){
                        prow.data.forEach(function (crow, cindex) {
                          let check = Array.isArray(crow);
                          if(check){
                            crow.forEach(function (csrow, csindex) {
                              data[pIndex].data[csindex+1] = csrow.completed;
                              console.log(csindex,csrow.jobTypeId)
                            })
                          }else{
                            data[pIndex].data[cindex] = crow;
                          }
                        })
                    }); */
                    return buildHead(columns) + buildBody(data);
                  },
                  /* customTableBodyFooterRender: (opts) => {
                    return (
                      <TableFooter>
                        <TableRow>
                          {this.renderFooter(jobList,reportData,opts, classes)}
                        </TableRow>
                      </TableFooter>
                    )
                  } */
                }}
              />
            </Grid>

          </Grid>
          <LoadingOverlay Status={this.state.isLoading} />
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    JobTypeSuccess: state.GetJobTypesReducer.Success,
    JobTypeFailure: state.GetJobTypesReducer.Failure,
    JobTypesList: state.GetJobTypesReducer.JobTypes,

    TeamListSuccess: state.getTeamsReducer.Success,
    TeamListFailure: state.getTeamsReducer.Failure,
    TeamLists: state.getTeamsReducer.TeamList,

    SReportSuccess: state.GetServiceReportReducer.Success,
    SReportFailure: state.GetServiceReportReducer.Failure,
    SReportLists: state.GetServiceReportReducer.SReport,

    UserDetails: state.LoginReducer.UserDetails,
    LoaderState: state.LoaderReducer.State
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetJobTypes: () => dispatch(GetJobTypes()),
    getTeams: (ID) => dispatch(getTeams(ID)),
    GetServiceReport: (filterObj) => dispatch(GetServiceReport(filterObj)),
    refreshReduxStates: () => dispatch(refreshReduxStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TechReport));
