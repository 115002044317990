import React, { useState, useEffect, Component } from "react";
import {
  Grid, TextField, Button, Switch, Tooltip,
  FormControlLabel, IconButton, VisibilityIcon,
  EditIcon, GridListTileBar, Autocomplete, connect,
  _, swal, toast, Typography, Checkbox, withStyles,
  SearchIcon, GridList, GridListTile, Divider,
  AddCheckIcon, Paper, moment, axios
} from "../../utilities/PackagesList";
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay, ButtonIcon,
  InputFieldIcon
} from "../../utilities/ComponentsList";
import {
  DateMinusYMD, isEmptyObj, convertDateDMY,
  dateTimetoDate, AddTime2Date, DMY, RemoveTime, AddTimeSelincill,
  capsFirstLetter, ServiceStatusType, getScreenWH, UnChangeObj, SelincillYMD
} from "../../utilities/AppFunctions";
import { returnAllApiCallPromise } from './../../api/ApiContent';
import { refreshReduxStates } from '../../api/Refresh';
import { GetJobTypes, AddService, GetCustomerProduct, AddServiceDetail, GetPaymentTypes, GetCustomerOrders, GetSellnchillToken, GetCustomerOrderDetails } from '../../api/Services';
import { LoaderStatus } from '../../api/Loading';
import ViewList from "./ViewList";
import ViewDetails from "./ViewDetails";
import styles from "../../utilities/StylesClass";
import NewService from "./NewService";
import UpdateDate from "./UpdateDate";
import ImportCustomers from "./ImportCustomers";
import ViewForm from "./ViewForm";
var qs = require('qs');
let InitialData = { CustomerNo: "", CustomerName: "", Address: "", Postal: "", Email: "", PhoneNo: "", SerialNo: "", Brand: "", ProdModel: "", Remarks: "", PurchaseDate: "", PurchasePrice: "", WarrantyDate: "", Inactive: false, Grade: "", Trade: "", ProdDesc: "" };
let InitialError = { CustomerNo: false, CustomerName: false, Address: false, Postal: false, Email: false, PhoneNo: false, SerialNo: false, Brand: false, ProdModel: false, Remarks: false, PurchaseDate: false, PurchasePrice: false, WarrantyDate: false, Inactive: false, Grade: false, Trade: false, ProdDesc: false };

class CreateService extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      ListModel: false,
      fields: InitialData,
      errors: {},
      ServiceList: [],
      NewServiceOnly: [],
      JobTypeList: [],
      AddServiceModel: false,
      getCalls: [1, 0, 1, 0, 0, 0],
      postCalls: [0],
      ProductList: [],
      SelectedProdID: 0,
      AddedProd: 0,
      AddType: "S",
      ProdType: "E",
      DetailsState: false,
      DetailsID: null,
      UpdateDateState: false,
      EditID: null,
      SelectedForm: [],
      ViewFormState: false,
      serviceSelected: [],
      PaytmentTypesList: [],
      ImportModel: false,
      SelecitalStatus: false,
      SellnchillObj: {}
    }
    props.GetPaymentTypes();
    props.GetJobTypes();
  }
  UNSAFE_componentWillReceiveProps = (props) => {
    if (this.state.getCalls[0]) {
      if (props.JobTypesSuccess) {
        let Existfields = {
          CustomerNo: "", CustomerName: "", Address: "",
          Postal: "", Email: "", PhoneNo: "",
          SerialNo: "", Brand: "", ProdModel: "", Remarks: "", PurchaseDate: "",
          PurchasePrice: "", WarrantyDate: "", Inactive: false, Grade: "", Trade: "", ProdDesc: ""
        };
        let services = props.JobTypesList;
        //let Existfields = this.state.fields;
        /*  if (services.length > 0) {
           Existfields.JobType = services[0];
           Existfields.JobTypeID = services[0].jobTypeId;
         } */
        this.setState({
          JobTypeList: services,
          fields: Existfields,
          isLoading: false,
          getCalls: [0, 0]
        })
      } else if (props.JobTypesFailure) {
        this.setState({
          JobTypeList: [],
          getCalls: [0, 0],
          isLoading: false
        })
      }
    }
    if (this.state.getCalls[1]) {
      if (props.CustProductSuccess) {
        let Products = props.CustProductList;
        let selProdID = this.state.AddedProd;
        let selProdType = this.state.ProdType;
        let selStatus = this.state.SelecitalStatus;
        if (Products.length > 0) {
          this.setState({
            ProductList: Products,
            AddedProd: 0,
            isLoading: false,
            SelecitalStatus: false,
            ListModel: (selProdID > 0 || selProdType == "N") ? (selStatus ? true : false) : true,
            getCalls: [0, 0]
          }, () => {
            if (selProdID > 0) { this.ProductChosen(selProdID, "exist"); }
          })
        } else {
          this.setState({
            ProductList: [],
            isLoading: false,
            ListModel: false,
            ProdType: "N",
            SelecitalStatus: false,
            getCalls: [0, 0]
          }, () => {
            toast.warning('Customer not found', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: false,
            });
          })
        }

      } else if (props.CustProductFailure) {
        this.setState({
          ProductList: [],
          getCalls: [0, 0],
          isLoading: false,
          ListModel: false
        }, () => {
          toast.warning('No products found', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      }
    }
    if (this.state.getCalls[2]) {
      if (props.PaymentTypeSuccess) {
        let succed = this.state.getCalls;
        succed[2] = 0;
        let loaderStatus = succed.includes(1);
        let PaymentLists = _.orderBy(props.PaymentTypeList, ['id'], ['asc']);
        this.setState({
          PaytmentTypesList: PaymentLists,
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.PaymentTypeFailure) {
        let succed = this.state.getCalls;
        succed[2] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          PaytmentTypesList: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.getCalls[3]) {
      if (props.SCTokenSuccess) {
        let succed = this.state.getCalls;
        succed[3] = 0;
        succed[4] = 1;
        let loaderStatus = succed.includes(1);
        //console.log("Token Success :", JSON.stringify(props.SCToken))
        const SclResp = props.SCToken;
        this.setState({
          isLoading: loaderStatus,
          SellnchillObj: SclResp,
          SelecitalStatus: false,
          getCalls: succed
        }, () => {
          let searchByPhone = this.state.fields.PhoneNo;
          let searchObj = {
            "companyID": 21,
            "criteria": "PhoneNumber",
            "keyWord": searchByPhone
          }
          global.SToken = SclResp.access_token;
          this.props.GetCustomerOrders(searchObj);
        })
      } else if (props.SCTokenFailure) {
        let succed = this.state.getCalls;
        succed[3] = 0;
        let loaderStatus = succed.includes(1);
        //console.log("Token Error :", JSON.stringify(props.SCToken))
        this.setState({
          getCalls: succed,
          SelecitalStatus: false,
          SellnchillObj: {},
          isLoading: loaderStatus
        }, () => {
          this.props.refreshReduxStates();
          toast.warning('Failed to connect sellnchill', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      }
    }
    if (this.state.getCalls[4]) {
      if (props.SCOrdersSuccess) {
        let succed = this.state.getCalls;
        succed[4] = 0;
        let loaderStatus = succed.includes(1);
        let details = props.SCOrders.customerOrders;
        if (parseInt(details.ordersCount) > 0) {
          let NewProduct = [];
          let billingAddress = "";
          if (details.billing) {
            let billAddress1 = details.billing.billAddress1 ? details.billing.billAddress1 + "," : "";
            let billAddress2 = details.billing.billAddress2 ? details.billing.billAddress2 + "," : "";
            let billSuburb = details.billing.billSuburb ? details.billing.billSuburb + "," : "";
            let billCity = details.billing.billCity ? details.billing.billCity + "," : "";
            let billRegion = details.billing.billRegion ? details.billing.billRegion + "," : "";
            let billCountry = details.billing.billCountry ? details.billing.billCountry + "," : "";
            let billPostalCode = details.billing.billPostalCode ? details.billing.billPostalCode + "," : "";
            billingAddress = billAddress1 + '' + billAddress2 + '' + billSuburb + '' + billCity + '' + billRegion + '' + billCountry + '' + billPostalCode;
            billingAddress = billingAddress.replace(/,\s*$/, "");
          }

          let arrObject = [];
          let arrayURL = [];
          let arrayMethod = [];
          details.orders.map((item) => {
            let searchObj = {
              "companyID": 21,
              "platform": item.platform,//"Shopee",
              "sellerID": item.sellerID,
              "orderID": item.orderID,
              "orderNumber": item.orderNumber
            }
            arrObject.push(searchObj);
            arrayURL.push("Lookup/GetCustomerOrderDetails");
            arrayMethod.push("POST");
            
            if (details.orders.length == arrObject.length) {
              returnAllApiCallPromise(arrayMethod, arrayURL, arrObject, null, "sellnchill")
                .then(axios.spread((...responses) => {
                  
                  let OrderCount = 0;
                  responses.map((OrderItem, index) => {
                    OrderCount++;
                    let ProdCount = 0;
                    const singleItem = OrderItem.data.orderItems;
                    //console.log("singleItem :", JSON.stringify(singleItem))
                    singleItem.map((OrderDetails) => {
                      ProdCount++;
                      let serialsCount = OrderDetails.serialNumbers ? OrderDetails.serialNumbers : [];
                      details.orders[index].productName = OrderDetails.productName;
                      details.orders[index].productSKU = OrderDetails.productSKU;
                      details.orders[index].price = OrderDetails.price;
                      if (serialsCount.length > 0) {
                        if (serialsCount.length > 1) {
                          let multipleSerial = UnChangeObj(details.orders[index]);
                          details.orders = [];
                          for (let i = 0; i < serialsCount.length; i++) {
                            multipleSerial.serialNumbers = serialsCount[i].serialNumber;
                            details.orders.push(multipleSerial);
                            let NewItem = {};
                            NewItem.id = OrderCount + i;
                            NewItem.customerName = details.customerName;
                            NewItem.customerNumber = details.customerCode;//details.orders[index].orderNumber;
                            NewItem.address = billingAddress;
                            NewItem.postalCode = details.billing ? details.billing.billPostalCode : "";
                            NewItem.email = details.customerEmail;
                            NewItem.phoneNumber = details.customerPhone;
                            NewItem.productDescription = OrderDetails.productName;
                            NewItem.brand = "brand"; NewItem.model = "model";
                            NewItem.guaranteeTrade = ""; NewItem.grade = "";
                            NewItem.serialNumber = serialsCount[i].serialNumber;
                            NewItem.items = ""; NewItem.remark = ""; NewItem.inactive = false;
                            NewItem.purchaseDate = SelincillYMD(details.orders[index].orderedOn);
                            NewItem.purchasePrice = OrderDetails.price;
                            NewItem.warrantyDate = "";
                            NewItem.createdAt = AddTimeSelincill(details.orders[index].orderedOn);
                            NewItem.updatedAt = AddTimeSelincill(details.orders[index].orderedOn);
                            NewItem.guaranteeTrade = "";
                            NewItem.grade = "";
                            NewItem.serviceDetails = [];
                            NewProduct.push(NewItem)
                          }
                        } else {
                          details.orders[index].serialNumbers = OrderDetails.serialNumbers[0].serialNumber;
                          let NewItem = {};
                          NewItem.id = OrderCount;//1;
                          NewItem.customerName = details.customerName;
                          NewItem.customerNumber = details.customerCode;//details.orders[index].orderNumber;
                          NewItem.address = billingAddress;
                          NewItem.postalCode = details.billing ? details.billing.billPostalCode : "";
                          NewItem.email = details.customerEmail;
                          NewItem.phoneNumber = details.customerPhone;
                          NewItem.productDescription = OrderDetails.productName;
                          NewItem.brand = "brand"; NewItem.model = "model";
                          NewItem.guaranteeTrade = ""; NewItem.grade = "";
                          NewItem.serialNumber = OrderDetails.serialNumbers[0].serialNumber;
                          NewItem.items = ""; NewItem.remark = ""; NewItem.inactive = false;
                          NewItem.purchaseDate = SelincillYMD(details.orders[index].orderedOn);
                          NewItem.purchasePrice = OrderDetails.price;
                          NewItem.warrantyDate = "";
                          NewItem.createdAt = AddTimeSelincill(details.orders[index].orderedOn);
                          NewItem.updatedAt = AddTimeSelincill(details.orders[index].orderedOn);
                          NewItem.guaranteeTrade = "";
                          NewItem.grade = "";
                          NewItem.serviceDetails = [];
                          NewProduct.push(NewItem)
                        }
                      } else {
                        details.orders[index].serialNumbers = OrderDetails.serialNumbers;
                        let NewItem = {};
                        NewItem.id = OrderCount;//1;
                        NewItem.customerName = details.customerName;
                        NewItem.customerNumber = details.customerCode;//details.orders[index].orderNumber;
                        NewItem.address = billingAddress;
                        NewItem.postalCode = details.billing ? details.billing.billPostalCode : "";
                        NewItem.email = details.customerEmail;
                        NewItem.phoneNumber = details.customerPhone;
                        NewItem.productDescription = OrderDetails.productName;
                        NewItem.brand = "brand"; NewItem.model = "model";
                        NewItem.guaranteeTrade = ""; NewItem.grade = "";
                        NewItem.serialNumber = OrderDetails.serialNumbers;
                        NewItem.items = ""; NewItem.remark = ""; NewItem.inactive = false;
                        NewItem.purchaseDate = SelincillYMD(details.orders[index].orderedOn);
                        NewItem.purchasePrice = OrderDetails.price;
                        NewItem.warrantyDate = "";
                        NewItem.createdAt = AddTimeSelincill(details.orders[index].orderedOn);
                        NewItem.updatedAt = AddTimeSelincill(details.orders[index].orderedOn);
                        NewItem.guaranteeTrade = "";
                        NewItem.grade = "";
                        NewItem.serviceDetails = [];
                        NewProduct.push(NewItem)
                      }
                      //console.log('OC'+OrderCount+'-RL-'+responses.length+'-SL-'+singleItem.length+'-PC-'+ProdCount)
                      if (OrderCount == responses.length && singleItem.length == ProdCount) {
                        //console.log("details:", JSON.stringify(NewProduct))
                        this.setState({
                          isLoading: loaderStatus,
                          getCalls: succed,
                          ListModel: true,
                          SelecitalStatus: true,
                          ProductList: NewProduct
                        });
                      }
                    })
                  })
                })).catch(error => {
                  toast.warning('Sorry!, Failed to get orders', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: false,
                  });
                })
            }
          })
        } else {
          this.setState({
            isLoading: loaderStatus,
            getCalls: succed,
            SelecitalStatus: false
          }, () => {
            toast.warning('No orders found', {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: false,
            });
          });

        }

        /*this.setState({
         isLoading: loaderStatus,
         getCalls: succed
       },()=>{
         if(details.orders.length>0){
           let searchObj = {
             "companyID": 21,
             "platform": details.orders[0].platform,//"Shopee",
             "sellerID": details.orders[0].sellerID,
             "orderID": details.orders[0].orderID,
             "orderNumber": details.orders[0].orderNumber
           }
           this.props.GetCustomerOrderDetails(searchObj); 
         }
       })*/
      } else if (props.SCOrdersFailure) {
        let succed = this.state.getCalls;
        succed[4] = 0;
        let loaderStatus = succed.includes(1);
        //console.log("SCOrders Error :", JSON.stringify(props.SCOrders))
        this.setState({
          getCalls: succed,
          SelecitalStatus: false,
          isLoading: loaderStatus
        }, () => {
          this.props.refreshReduxStates();
          toast.warning('Failed to get orders sellnchill', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      }
    }
    if (this.state.getCalls[5]) {
      if (props.SCOrdersDetailSuccess) {
        let succed = this.state.getCalls;
        succed[5] = 0;
        let loaderStatus = succed.includes(1);
        //console.log("SCOrdersDetail Success :", JSON.stringify(props.SCOrdersDetail))
        this.setState({
          isLoading: loaderStatus,
          getCalls: succed
        }, () => {
          this.props.refreshReduxStates()
        })
      } else if (props.SCOrdersDetailFailure) {
        let succed = this.state.getCalls;
        succed[5] = 0;
        let loaderStatus = succed.includes(1);
        //console.log("SCOrdersDetail Error :", JSON.stringify(props.SCOrders))
        this.setState({
          getCalls: succed,
          isLoading: loaderStatus
        }, () => {
          this.props.refreshReduxStates()
        })
      }
    }
    if (this.state.postCalls[0]) {
      if (props.AddServiceDetailSuccess) {
        let Existfields = {
          CustomerNo: "", CustomerName: "", Address: "",
          Postal: "", Email: "", PhoneNo: "",
          SerialNo: "", Brand: "", ProdModel: "", Remarks: "", PurchaseDate: "",
          PurchasePrice: "", WarrantyDate: "", Inactive: false, Grade: "", Trade: "", ProdDesc: ""
        };
        /*  let JobList = this.state.JobTypeList;
         if (JobList.length > 0) {
           Existfields.JobType = JobList[0];
           Existfields.JobTypeID = JobList[0].jobTypeId;
         } */
        let filterObj = {
          Email: this.state.fields.Email,
          Name: this.state.fields.CustomerName,
          Serail: this.state.fields.SerialNo,
          Postal: this.state.fields.Postal,
          Phone: this.state.fields.PhoneNo,
          CustomerNo: this.state.fields.CustomerNo
        };
        let selStatus = this.state.SelecitalStatus;
        this.setState({
          postCalls: [0],
          isLoading: true,
          fields: Existfields,
          getCalls: [0, 1, 0, 0, 0, 0],
          SelectedProdID: 0,
          errors: {},
          ServiceList: [],
          NewServiceOnly: [],
        }, () => {
          //this.props.refreshReduxStates();
          if (selStatus) {
            let thisMain = this;
            setTimeout(function () { thisMain.props.GetCustomerProduct(filterObj); }, 3000);
          } else {
            this.props.GetCustomerProduct(filterObj);
          }

          let alertText = this.state.AddType == 'S' ? 'Service Created Successfully' : 'Customer Info Added/Updated Successfully';
          toast.success(alertText, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      } else if (props.AddServicecDetailFailure) {
        this.setState({
          postCalls: [0],
          isLoading: false,
        }, () => {
          this.props.refreshReduxStates();
          toast.error(props.AddServiceDetailResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })

      }
    }
  }
  handleChange = (event, name) => {
    let fields = this.state.fields;
    let errors = this.state.errors;
    if (name == 'JobType') {
      let targetValue = event;
      fields[name] = targetValue;
      fields["JobTypeID"] = targetValue ? targetValue.jobTypeId : null;
      errors[name] = targetValue ? false : true;
      errors["JobTypeID"] = targetValue ? false : true;
    } else if (name == 'Inactive') {
      let targetValue = event;
      fields[name] = targetValue;
      errors[name] = false;
    } else {
      let targetValue = event.target.value;
      fields[name] = targetValue;
      errors[name] = targetValue ? false : true;
    }
    this.setState({
      fields,
      errors
    })


  };
  //FormView
  ViewSelectedForm = (formObject, type, SelectedService) => {

    this.setState({
      SelectedForm: formObject,
      ViewFormState: true,
      serviceSelected: SelectedService
    })
  }
  CloseFormView = () => {
    this.setState({
      SelectedForm: [],
      ViewFormState: false,
      serviceSelected: []
    })
  }
  CloseList = () => {
    this.setState({
      ProductList: [],
      ListModel: false
    })
  }
  OpenImportModel = () => {
    this.setState({
      ImportModel: true
    })
  }
  CloseImportModel = () => {
    this.setState({
      ImportModel: false
    }, () => {
      this.props.refreshReduxStates();
    })
  }
  //ASSIGN TEAM END
  editDate = (id) => {
    this.setState({
      UpdateDateState: true,
      EditID: id
    })
  }
  CloseUpdateDate = () => {
    this.setState({
      UpdateDateState: false,
      EditID: null
    })
  }
  UpdatedDate = (EditedObject) => {
    let FilterListData = this.state.ServiceList;
    let EditedID = this.state.EditID;
    let newList = FilterListData.filter(function (value, index) {
      let orderExist = value.id === EditedID;
      if (orderExist) {
        value.balancePaidDate = EditedObject.balancePaidDate;
        value.paymentStatusName = EditedObject.paymentStatusName;
        value.paymentStatusId = EditedObject.paymentStatusId;
      }
      return value;
    })
    this.setState({
      EditID: null,
      UpdateDateState: false,
      ServiceList: newList,
    }, () => {
      this.props.refreshReduxStates();
    })
  }
  searchClick = (input, type) => {
    if (input.length > 0) {
      let searchByEmail = type == 'CEM' ? input : "";
      let searchByName = type == 'CNA' ? input : "";
      let searchByPhone = type == 'CPH' ? input : "";
      let searchByPostal = type == 'CPO' ? input : "";
      let searchBySNo = type == 'CSN' ? input : "";
      let searchByCNo = type == 'CNO' ? input : "";
      let Existfields = {
        CustomerNo: searchByCNo, Address: "", CustomerName: searchByName, Email: searchByEmail,
        Postal: searchByPostal, PhoneNo: searchByPhone, SerialNo: searchBySNo, Brand: "", ProdModel: "", Remarks: "", PurchaseDate: "",
        PurchasePrice: "", WarrantyDate: "", Inactive: false, Grade: "", Trade: "", ProdDesc: ""
      };
      let filterObj = {
        Email: searchByEmail,
        Name: searchByName,
        Serail: searchBySNo,
        Postal: searchByPostal,
        Phone: searchByPhone,
        CustomerNo: searchByCNo
      };

      this.setState({
        isLoading: true,
        SelecitalStatus: false,
        getCalls: [0, 1, 0, 0, 0, 0],
        fields: Existfields,
        ProdType: "E",
        errors: {},
        ServiceList: [],
        NewServiceOnly: [],
        ProductList: [],
        SelectedProdID: 0,
        AddedProd: 0,
      }, () => {
        this.props.GetCustomerProduct(filterObj);
      })
    }
  }
  ProductChosen = (ProdID, PType) => {
    let FilteredProduct = _.filter(this.state.ProductList, { id: parseInt(ProdID) });
    if (FilteredProduct.length > 0) {
      let ProductDetails = FilteredProduct[0];
      let ServicesDetails = FilteredProduct[0].serviceDetails;
      if (PType == 'exist') {
        let Fillingfields = {
          CustomerNo: ProductDetails.customerNumber, CustomerName: ProductDetails.customerName, Address: ProductDetails.address,
          Postal: ProductDetails.postalCode, Email: ProductDetails.email, PhoneNo: ProductDetails.phoneNumber, SerialNo: ProductDetails.serialNumber,
          Brand: ProductDetails.brand, ProdModel: ProductDetails.model, Remarks: ProductDetails.remark, PurchaseDate: dateTimetoDate(ProductDetails.purchaseDate),
          PurchasePrice: ProductDetails.purchasePrice, WarrantyDate: dateTimetoDate(ProductDetails.warrantyDate), Grade: ProductDetails.grade,
          Trade: ProductDetails.guaranteeTrade, ProdDesc: ProductDetails.productDescription, Inactive: ProductDetails.inactive ? ProductDetails.inactive : false
        };
        ServicesDetails = _.orderBy(ServicesDetails, ['serviceDate'], ['desc']);
        this.setState({
          fields: Fillingfields,
          ProductList: [],
          ServiceList: ServicesDetails,
          NewServiceOnly: [],
          SelectedProdID: ProdID,
          AddedProd: ProdID,
          ListModel: false,
          ProdType: "E",
        })
      } else if (PType == 'snew') {
        let Fillingfields = {
          CustomerNo: ProductDetails.customerNumber, CustomerName: ProductDetails.customerName, Address: ProductDetails.address,
          Postal: ProductDetails.postalCode, Email: ProductDetails.email, PhoneNo: ProductDetails.phoneNumber, SerialNo: ProductDetails.serialNumber,
          Brand: ProductDetails.brand, ProdModel: ProductDetails.model, Remarks: ProductDetails.remark, PurchaseDate: dateTimetoDate(ProductDetails.purchaseDate),
          PurchasePrice: ProductDetails.purchasePrice, WarrantyDate: dateTimetoDate(ProductDetails.warrantyDate), Grade: ProductDetails.grade,
          Trade: ProductDetails.guaranteeTrade, ProdDesc: ProductDetails.productDescription, Inactive: ProductDetails.inactive ? ProductDetails.inactive : false
        };
        this.setState({
          fields: Fillingfields,
          ProductList: [],
          ServiceList: [],
          NewServiceOnly: [],
          SelectedProdID: 0,
          AddedProd: 0,
          ListModel: false,
          ProdType: "N",
        })
      } else if (PType == 'new') {
        let Fillingfields = {
          CustomerNo: ProductDetails.customerNumber, CustomerName: ProductDetails.customerName, Address: ProductDetails.address,
          Postal: ProductDetails.postalCode, Email: ProductDetails.email, PhoneNo: ProductDetails.phoneNumber, SerialNo: "",
          Brand: "", ProdModel: "", Remarks: "", PurchaseDate: "",
          PurchasePrice: "", WarrantyDate: "", Grade: "",
          Trade: "", ProdDesc: "", Inactive: false
        };
        this.setState({
          fields: Fillingfields,
          ProductList: [],
          ServiceList: [],
          NewServiceOnly: [],
          SelectedProdID: 0,
          AddedProd: 0,
          ListModel: false,
          ProdType: "N",
        })
      }

    }

  }
  CancelClick = () => {
    let Existfields = {
      CustomerNo: "", CustomerName: "", Address: "",
      Postal: "", Email: "", PhoneNo: "", SerialNo: "",
      Brand: "", ProdModel: "", Remarks: "", PurchaseDate: "",
      PurchasePrice: "", WarrantyDate: "", Inactive: false, Grade: "",
      Trade: "", ProdDesc: ""
    };
    this.setState({
      fields: Existfields,
      errors: {},
      ServiceList: [],
      NewServiceOnly: [],
      ProductList: [],
      SelectedProdID: 0,
      AddedProd: 0
    })
  }
  SubmitClick = () => {
    let fields = this.state.fields;
    let errors = this.state.errors;
    let srrorObj = {};
    let errorStatus = false;
    Object.keys(fields).map(function (k) {
      if (k === 'Email' || k === 'SerialNo' || k === 'PurchaseDate') {
        srrorObj[k] = false;
        errorStatus = errorStatus ? true : false;
      } else if (k == 'Inactive' || k === 'Grade' || k === 'PurchasePrice') {
        srrorObj[k] = false;
        errorStatus = errorStatus ? true : false;
      } else if (k == 'Remarks' || k === 'Trade' || k === 'WarrantyDate') {
        srrorObj[k] = false;
        errorStatus = errorStatus ? true : false;
      } else {
        srrorObj[k] = fields[k] ? false : true;
        errorStatus = errorStatus ? true : !fields[k];
      }
    });

    if (errorStatus) {
      this.setState({
        errors: srrorObj
      })
    } else {
      if (this.state.NewServiceOnly.length > 0) {
        let ProdIDChosen = this.state.SelectedProdID;
        let ProductItems = {
          customerName: fields.CustomerName,
          customerNumber: fields.CustomerNo,
          phoneNumber: fields.PhoneNo,
          address: fields.Address,
          postalCode: fields.Postal,
          email: fields.Email,
          serialNumber: fields.SerialNo,
          brand: fields.Brand,
          model: fields.ProdModel,
          //items: fields.ItemDetails,
          remark: fields.Remarks,
          purchaseDate: AddTime2Date(fields.PurchaseDate),
          purchasePrice: fields.PurchasePrice,
          warrantyDate: AddTime2Date(fields.WarrantyDate),
          guaranteeTrade: fields.Trade,
          grade: fields.Grade,
          productDescription: fields.ProdDesc,
          inActive: fields.Inactive ? fields.Inactive : false
        };
        let ProductExist = parseInt(ProdIDChosen) === 0 ? ProductItems : {};
        let createObj = {
          customerProductId: ProdIDChosen,
          seviceDetails: this.state.NewServiceOnly,
          adHocServiceItemsIds: [],
          customerProductDetails: ProductItems
        }
        this.setState({
          isLoading: true,
          postCalls: [1],
          AddType: "S"
        }, () => {
          this.props.AddServiceDetail(createObj);
        })
      } else {
        let ProductItems = {
          customerName: fields.CustomerName,
          customerNumber: fields.CustomerNo,
          phoneNumber: fields.PhoneNo,
          address: fields.Address,
          postalCode: fields.Postal,
          email: fields.Email,
          serialNumber: fields.SerialNo,
          //items: fields.ItemDetails,
          brand: fields.Brand,
          model: fields.ProdModel,
          remark: fields.Remarks,
          purchaseDate: AddTime2Date(fields.PurchaseDate),
          purchasePrice: fields.PurchasePrice,
          warrantyDate: AddTime2Date(fields.WarrantyDate),
          guaranteeTrade: fields.Trade,
          grade: fields.Grade,
          productDescription: fields.ProdDesc,
          inActive: fields.Inactive ? fields.Inactive : false
        };

        let createObj = {
          customerProductId: this.state.SelectedProdID,
          seviceDetails: [],
          adHocServiceItemsIds: [],
          customerProductDetails: ProductItems
        }
        this.setState({
          isLoading: true,
          postCalls: [1],
          AddType: "P"
        }, () => {
          this.props.AddServiceDetail(createObj);
        })
      }
    }
  }
  ConnectSelential = () => {
    let searchByPhone = this.state.fields.PhoneNo;
    let Existfields = {
      CustomerNo: "", Address: "", CustomerName: "", Email: "",
      Postal: "", PhoneNo: searchByPhone, SerialNo: "", Brand: "", ProdModel: "", Remarks: "", PurchaseDate: "",
      PurchasePrice: "", WarrantyDate: "", Inactive: false, Grade: "", Trade: "", ProdDesc: ""
    };
    if (searchByPhone) {
      const SclResp = this.state.SellnchillObj;
      const emptyStatus = _.isEmpty(SclResp);
      let tokenExpired = true;
      if (!emptyStatus) {
        if (SclResp.hasOwnProperty(".expires")) {
          var expiretime = moment.utc(SclResp[".expires"]);
          expiretime = expiretime.format('YYYY-MM-DD HH:MM:SS');
          var currenttime = moment.utc();
          currenttime = currenttime.format('YYYY-MM-DD HH:MM:SS');
          let timeDiff = moment(expiretime).diff(moment(currenttime), 'minutes');
          tokenExpired = timeDiff > 0 ? false : true;
        }
      }
      if (tokenExpired) {
        this.setState({
          getCalls: [0, 0, 0, 1, 0, 0],
          SelecitalStatus: false,
          fields : Existfields,
          isLoading: true
        }, () => {
          global.SToken = null;
          const tokenQueryData = qs.stringify({ "Password": "Wong@2021", "UserName": "Petshop", "grant_type": "password" });
          this.props.GetSellnchillToken(tokenQueryData);
        })
      } else {
        this.setState({
          getCalls: [0, 0, 0, 0, 1, 0],
          SelecitalStatus: false,
          fields : Existfields,
          isLoading: true
        }, () => {

          let searchObj = {
            "companyID": 21,
            "criteria": "PhoneNumber",
            "keyWord": searchByPhone
          }
          global.SToken = SclResp.access_token;
          this.props.GetCustomerOrders(searchObj);
        })
      }
    } else {
      toast.warning("Please enter mobile number to search", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      });
    }
  }
  AddingService = async () => {
    this.setState({
      AddServiceModel: true
    })
  }
  AddedService = (newService) => {
    let existService = this.state.ServiceList;
    existService.push(newService);
    let existNewService = this.state.NewServiceOnly;
    existNewService.push(newService);
    existService = _.orderBy(existService, ['serviceDate'], ['desc']);
    this.setState({
      AddServiceModel: false,
      ServiceList: existService,
      NewServiceOnly: existNewService,
    })
  }
  CloseAddService = () => {
    this.setState({
      AddServiceModel: false
    })
  }
  OpenDetails = (serviceID) => {
    this.setState({
      DetailsState: true,
      DetailsID: serviceID
    })
  }
  CloseDetails = () => {
    this.setState({
      DetailsState: false,
      DetailsID: null
    })
  }
  render() {
    const { classes } = this.props;
    const screenHeight = getScreenWH('h') / 2;
    const completedService = _.filter(this.state.ServiceList, function (o) { return parseInt(o.serviceStatusId) === 4; });
    const NCService = _.filter(this.state.ServiceList, function (o) { return parseInt(o.serviceStatusId) != 4; });
    const NotCompletedService = _.orderBy(NCService, ['serviceDate'], ['asc']);
    return (
      <>
        <PageTitle title="Customer Service Card" />
        <Grid container spacing={4} lg={12} md={12} sm={12} xl={12} xs={12}>
          <Grid container item direction={"row-reverse"} md={6} style={{ position: 'absolute', right: "3%", top: "15%" }} >
            <Grid item lg={3} md={3} sm={3} xl={3} xs={3}>
              <Button
                onClick={() => this.OpenImportModel()}
                className={[classes.buttonSuccess]}
                variant="contained" color="primary">
                Add Customer CSV
            </Button>
            </Grid>
            <Grid item lg={3} md={3} sm={3} xl={3} xs={3}>
              <Button
                onClick={() => this.ConnectSelential()}
                className={[classes.buttonSuccess]}
                variant="contained" color="primary">
                Search In Sellnchill
            </Button>
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xl={12} xs={12} direction="row">
            <Grid item lg={8} md={8} sm={8} xl={8} xs={8}>
              <Grid container spacing={4} lg={12} md={12} sm={12} xl={12} xs={12} direction="row">
                <Grid item lg={6} md={6} sm={6} xl={6} xs={6} spacing={4} >
                  {/* <InputField
                    label={"Customer Number"}
                    type={"text"}
                    required={true}
                    value={this.state.fields.CustomerNo}
                    changeText={(text) => this.handleChange(text, 'CustomerNo')}
                    error={this.state.errors.CustomerNo}
                    initialName={"Customer Number"}
                    errorMsg={"Please enter customer number."}
                  /> */}
                  <InputFieldIcon
                    label={"Customer Number"}
                    type={"text"}
                    required={true}
                    value={this.state.fields.CustomerNo}
                    changeText={(text) => this.handleChange(text, 'CustomerNo')}
                    error={this.state.errors.CustomerNo}
                    initialName={"Customer Number"}
                    Icon={<SearchIcon fontSize={"small"} />}
                    iconClick={() => this.searchClick(this.state.fields.CustomerNo, "CNO")}
                    errorMsg={"Please enter customer number."}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xl={6} xs={6} spacing={4} >
                  <InputFieldIcon
                    label={"Customer Name"}
                    type={"text"}
                    required={true}
                    value={this.state.fields.CustomerName}
                    changeText={(text) => this.handleChange(text, 'CustomerName')}
                    error={this.state.errors.CustomerName}
                    initialName={"Customer Name"}
                    Icon={<SearchIcon fontSize={"small"} />}
                    iconClick={() => this.searchClick(this.state.fields.CustomerName, "CNA")}
                    errorMsg={"Please enter customer name."}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} lg={12} md={12} sm={12} xl={12} xs={12} direction="row">
                <Grid item lg={6} md={6} sm={6} xl={6} xs={6} spacing={4} >
                  <InputField
                    label={"Address"}
                    type={"text"}
                    required={true}
                    value={this.state.fields.Address}
                    changeText={(text) => this.handleChange(text, 'Address')}
                    error={this.state.errors.Address}
                    initialName={"Address"}
                    errorMsg={"Please enter customer address."}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xl={6} xs={6} spacing={4} >
                  <InputFieldIcon
                    label={"Postal Code"}
                    type={"number"}
                    required={true}
                    value={this.state.fields.Postal}
                    changeText={(text) => this.handleChange(text, 'Postal')}
                    error={this.state.errors.Postal}
                    initialName={"Postal Code"}
                    Icon={<SearchIcon fontSize={"small"} />}
                    iconClick={() => this.searchClick(this.state.fields.Postal, "CPO")}
                    errorMsg={"Please enter postal code."}
                  />
                  {/* <InputField
                    label={"Postal"}
                    type={"number"}
                    required={true}
                    value={this.state.fields.Postal}
                    changeText={(text) => this.handleChange(text, 'Postal')}
                    error={this.state.errors.Postal}
                    initialName={"Postal Code"}
                    errorMsg={"Please enter customer postal."}
                  /> */}
                </Grid>
              </Grid>
              <Grid container spacing={4} lg={12} md={12} sm={12} xl={12} xs={12} direction="row">
                <Grid item lg={6} md={6} sm={6} xl={6} xs={6} spacing={4} >
                  <InputFieldIcon
                    label={"Email"}
                    type={"text"}
                    required={false}
                    value={this.state.fields.Email}
                    changeText={(text) => this.handleChange(text, 'Email')}
                    //error={this.state.errors.Email}
                    initialName={"Email"}
                    Icon={<SearchIcon fontSize={"small"} />}
                    iconClick={() => this.searchClick(this.state.fields.Email, "CEM")}
                    errorMsg={"Please enter email."}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xl={6} xs={6} spacing={4} >
                  {/* <InputField
                    label={"Phone Number"}
                    type={"number"}
                    required={true}
                    value={this.state.fields.PhoneNo}
                    changeText={(text) => this.handleChange(text, 'PhoneNo')}
                    error={this.state.errors.PhoneNo}
                    initialName={"Phone Number"}
                    errorMsg={"Please enter phone number."}
                  /> */}
                  <InputFieldIcon
                    label={"Phone Number"}
                    type={"number"}
                    required={true}
                    value={this.state.fields.PhoneNo}
                    changeText={(text) => this.handleChange(text, 'PhoneNo')}
                    error={this.state.errors.PhoneNo}
                    initialName={"Phone Number"}
                    Icon={<SearchIcon fontSize={"small"} />}
                    iconClick={() => this.searchClick(this.state.fields.PhoneNo, "CPH")}
                    errorMsg={"Please enter phone number."}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} lg={12} md={12} sm={12} xl={12} xs={12} direction="row">
                <Grid item lg={6} md={6} sm={6} xl={6} xs={6} spacing={4} >
                  <InputField
                    label={"Product Description"}
                    type={"text"}
                    required={true}
                    value={this.state.fields.ProdDesc}
                    changeText={(text) => this.handleChange(text, 'ProdDesc')}
                    error={this.state.errors.ProdDesc}
                    initialName={"Product Description"}
                    errorMsg={"Please enter product description."}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xl={6} xs={6} spacing={4} >
                  <InputField
                    label={"Product Brand"}
                    type={"text"}
                    required={true}
                    value={this.state.fields.Brand}
                    changeText={(text) => this.handleChange(text, 'Brand')}
                    error={this.state.errors.Brand}
                    initialName={"Product Brand"}
                    errorMsg={"Please enter product brand."}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} lg={12} md={12} sm={12} xl={12} xs={12} direction="row">
                <Grid item lg={6} md={6} sm={6} xl={6} xs={6} spacing={4} >
                  <InputField
                    label={"Product Model"}
                    type={"text"}
                    required={true}
                    value={this.state.fields.ProdModel}
                    changeText={(text) => this.handleChange(text, 'ProdModel')}
                    error={this.state.errors.ProdModel}
                    initialName={"Product Model"}
                    errorMsg={"Please enter product model."}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xl={6} xs={6} spacing={4} >
                  <InputFieldIcon
                    label={"Product Serial Number"}
                    type={"text"}
                    required={false}
                    value={this.state.fields.SerialNo}
                    changeText={(text) => this.handleChange(text, 'SerialNo')}
                    error={this.state.errors.SerialNo}
                    initialName={"Product Serial Number"}
                    Icon={<SearchIcon fontSize={"small"} />}
                    iconClick={() => this.searchClick(this.state.fields.SerialNo, "CSN")}
                    errorMsg={"Please enter product serial number."}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} lg={12} md={12} sm={12} xl={12} xs={12} direction="row">
                <Grid item lg={6} md={6} sm={6} xl={6} xs={6} spacing={4} >
                  <InputField
                    label={"Guarantee Trade/In Buy Back"}
                    type={"text"}
                    required={false}
                    value={this.state.fields.Trade}
                    changeText={(text) => this.handleChange(text, 'Trade')}
                    error={this.state.errors.Trade}
                    initialName={"Guarantee Trade/In Buy Back"}
                    errorMsg={"Please enter guarantee trade/In buy back."}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xl={6} xs={6} spacing={4} >
                  <InputField
                    label={"Grade/Follow up"}
                    type={"text"}
                    required={false}
                    multiline={true}
                    rows={4}
                    maxLength={600}
                    value={this.state.fields.Grade}
                    changeText={(text) => this.handleChange(text, 'Grade')}
                    error={this.state.errors.Grade}
                    initialName={"Grade/Follow up"}
                    errorMsg={"Please enter grade/follow up."}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} lg={12} md={12} sm={12} xl={12} xs={12} direction="row">
                <Grid item lg={6} md={6} sm={6} xl={6} xs={6} spacing={4} >
                  <InputField
                    label={"Remarks"}
                    type={"text"}
                    multiline={true}
                    rows={4}
                    maxLength={600}
                    required={false}
                    value={this.state.fields.Remarks}
                    changeText={(text) => this.handleChange(text, 'Remarks')}
                    error={this.state.errors.Remarks}
                    initialName={"Remarks"}
                    errorMsg={"Please enter remarks."}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xl={6} xs={6} spacing={4} >
                  <FormControlLabel
                    control={<Checkbox checked={this.state.fields.Inactive} onChange={(e) => {
                      let newStatus = !this.state.fields.Inactive;
                      this.handleChange(newStatus, 'Inactive')
                    }} name="Status Inactive" color="primary" />}
                    label="Status Inactive"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} lg={12} md={12} sm={12} xl={12} xs={12} direction="row">
                <Grid item lg={6} md={6} sm={6} xl={6} xs={6} spacing={4} >
                  <InputField
                    label={"Date of Purchase"}
                    type={"date"}
                    required={false}
                    value={this.state.fields.PurchaseDate}
                    changeText={(text) => this.handleChange(text, 'PurchaseDate')}
                    error={this.state.errors.PurchaseDate}
                    initialName={"Date of Purchase"}
                    errorMsg={"Please enter date of purchase."}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xl={6} xs={6} spacing={4} >
                  <InputField
                    label={"Purchase Price(incl GST)"}
                    type={"number"}
                    required={false}
                    value={this.state.fields.PurchasePrice}
                    changeText={(text) => this.handleChange(text, 'PurchasePrice')}
                    error={this.state.errors.PurchasePrice}
                    initialName={"Purchase Price"}
                    errorMsg={"Please enter purchase price."}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4} lg={12} md={12} sm={12} xl={12} xs={12} direction="row">
                <Grid item lg={6} md={6} sm={6} xl={6} xs={6} spacing={4} >
                  <InputField
                    label={"Guarantee Due Date"}
                    type={"date"}
                    required={false}
                    value={this.state.fields.WarrantyDate}
                    changeText={(text) => this.handleChange(text, 'WarrantyDate')}
                    error={this.state.errors.WarrantyDate}
                    initialName={"Warranty Date"}
                    errorMsg={"Please enter guarantee date."}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xl={6} xs={6} spacing={4} align="right">
                  <Button
                    onClick={() => this.AddingService()}
                    className={[classes.buttonSuccess]}
                    variant="contained" color="primary">
                    Add Service
                      </Button>
                </Grid>
              </Grid>
              <Grid container item spacing={4} lg={12} md={12} sm={12} xl={12} xs={12} >
                <Grid item lg={6} md={6} sm={6} xl={6} xs={6} spacing={4}>
                  <Buttons
                    close={() => this.CancelClick()}
                    closeText={"Cancel"}
                    save={() => this.SubmitClick()}
                    saveText={"Submit"}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xl={4} xs={4}>
              {/* <Grid container spacing={4} md={12} style={{ border: "1px solid grey", height: screenHeight - 60, marginBottom: '6%' }}>
                <GridList cols={1}
                  style={{
                    height: screenHeight - 60,
                    padding: 10
                  }}>

                  {NotCompletedService.map((items) => (
                    <GridListTile
                      style={{
                        height: "auto",
                        width: '100%',
                        overflowY: 'auto',
                      }} key={items.id} >
                      <Grid item md={12}>
                        <Typography >
                          Job Type: {capsFirstLetter(items.jobType)}
                        </Typography>
                        <Typography >
                          Complementry: {items.complementary ? "Yes" : "No"}
                        </Typography>
                        <Grid container justify="space-between">
                          <Typography inline align="left">Fee: ${items.fee}</Typography>
                          <Typography inline align="right">{DMY(RemoveTime(items.serviceDate))}</Typography>
                        </Grid>
                        <Typography >
                          Service Status: {ServiceStatusType(items.serviceStatusId)}
                        </Typography>
                        <Divider />
                      </Grid>
                    </GridListTile>

                  ))}
                </GridList>
              </Grid> */}
              <Grid container spacing={4} lg={12} md={12} sm={12} xl={12} xs={12} style={{ border: "1px solid grey", height: screenHeight - 55 }}>
                <Paper style={{
                  maxHeight: screenHeight - 58,
                  backgroundColor: "transparent",
                  width: '100%',
                  overflow: 'auto'
                }}>
                  {NotCompletedService.map((items) => (
                    <Grid item lg={12} md={12} sm={12} xl={12} xs={12} style={{ padding: 10 }}>
                      <Grid container direction={"row"} lg={12} md={12} sm={12} xl={12} xs={12} >
                        <Grid item lg={9} md={9} sm={9} xl={9} xs={9}>
                          <Typography >
                            Job Type: {capsFirstLetter(items.jobType)}
                          </Typography>
                          <Typography >
                            Complementry: {items.complementary ? "Yes" : "No"}
                          </Typography>
                          <Typography>Fee: ${items.fee}</Typography>
                          {/*  <Grid container justify="space-between">
                            <Typography inline align="left">Fee: ${items.fee}</Typography>
                            <Typography inline align="right">{DMY(RemoveTime(items.serviceDate))}</Typography>
                          </Grid> */}
                          <Typography >
                            Service Status: {ServiceStatusType(items.serviceStatusId)}
                          </Typography>
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xl={3} xs={3}>
                          <Grid item lg={12} md={12} sm={12} xl={12} xs={12} style={{ paddingTop: '60%' }}>
                            <Typography inline align="right">{DMY(RemoveTime(items.serviceDate))}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider />
                    </Grid>
                  ))}
                </Paper >
              </Grid>
              <Grid container spacing={4} lg={12} md={12} sm={12} xl={12} xs={12} style={{ border: "1px solid grey", height: screenHeight - 55, marginTop: '5%' }}>
                <Paper style={{
                  maxHeight: screenHeight - 58,
                  backgroundColor: "transparent",
                  width: '100%',
                  overflow: 'auto'
                }}>
                  {completedService.map((items) => (
                    <Grid item lg={12} md={12} sm={12} xl={12} xs={12} style={{ padding: 10 }}>
                      <Grid container direction={"row"} lg={12} md={12} sm={12} xl={12} xs={12} >
                        <Grid item lg={9} md={9} sm={9} xl={9} xs={9}>
                          <Typography >
                            Job Type: {capsFirstLetter(items.jobType)}
                          </Typography>
                          <Typography >
                            Complementry: {items.complementary ? "Yes" : "No"}
                          </Typography>
                          <Typography>Fee: ${items.fee}</Typography>
                          {/*  <Grid container justify="space-between">
                            <Typography inline align="left">Fee: ${items.fee}</Typography>
                            <Typography inline align="right">{DMY(RemoveTime(items.serviceDate))}</Typography>
                          </Grid> */}
                          <Typography >
                            Service Status: {ServiceStatusType(items.serviceStatusId)}
                          </Typography>
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xl={3} xs={3}>
                          <Grid item container lg={12} md={12} sm={12} xl={12} xs={12} direction={"row"} alignItems="flex-end" justify="flex-end">
                            {items.paymentStatusName == 'Partially Paid' ?
                              <Tooltip title={"Pay Balance"} >
                                <IconButton
                                  onClick={() => this.editDate(items.id)}
                                  aria-label="Balance"
                                  size="small"
                                >
                                  <AddCheckIcon fontSize="small" color="primary" />
                                </IconButton>
                              </Tooltip>
                              : null}
                            <Tooltip title={"View Details"}>
                              <IconButton
                                onClick={() => this.OpenDetails(items.id)}
                                aria-label="Details"
                                size="small"
                              >
                                <VisibilityIcon fontSize="small" color="primary" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xl={12} xs={12} style={{ paddingTop: '30%' }}>
                            <Typography inline align="right">{DMY(RemoveTime(items.serviceDate))}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider />
                    </Grid>
                  ))}
                </Paper >
              </Grid>
            </Grid>
          </Grid>
          {this.state.DetailsState ? <ViewDetails
            Title={"Service Details"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.DetailsState}
            ModelCancel={this.CloseDetails}
          /> : null}
          {this.state.ListModel ? <ViewList
            Title={"Product Details"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.ListModel}
            ModelConfirm={this.ProductChosen}
            ModelCancel={this.CloseList}
          /> : null}
          {this.state.AddServiceModel ? <NewService
            Title={"Add Service"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.AddServiceModel}
            ModelConfirm={this.AddedService}
            ModelCancel={this.CloseAddService}
          /> : null}
          {this.state.UpdateDateState ? <UpdateDate
            Title={"Update Balance Paid Date"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.UpdateDateState}
            ModelConfirm={this.UpdatedDate}
            ModelCancel={this.CloseUpdateDate}
          /> : null}
          {this.state.ViewFormState ? <ViewForm
            Title={"View Form"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.ViewFormState}
            // ModelConfirm={this.UpdatedDate}
            ModelCancel={this.CloseFormView}
          /> : null}
          {this.state.ImportModel ? <ImportCustomers
            Title={"Import Customers"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.ImportModel}
            ModelConfirm={this.CloseImportModel}
            ModelCancel={this.CloseImportModel}
          /> : null}
          <LoadingOverlay Status={this.state.isLoading} />
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    JobTypesSuccess: state.GetJobTypesReducer.Success,
    JobTypesFailure: state.GetJobTypesReducer.Failure,
    JobTypesList: state.GetJobTypesReducer.JobTypes,

    AddServiceSuccess: state.AddServiceReducer.Success,
    AddServicecFailure: state.AddServiceReducer.Failure,
    AddServiceResponse: state.AddServiceReducer.AddingResponse,

    AddServiceDetailSuccess: state.AddServiceDetailReducer.Success,
    AddServicecDetailFailure: state.AddServiceDetailReducer.Failure,
    AddServiceDetailResponse: state.AddServiceDetailReducer.AddingResponse,

    CustProductSuccess: state.GetCustomerProductReducer.Success,
    CustProductFailure: state.GetCustomerProductReducer.Failure,
    CustProductList: state.GetCustomerProductReducer.CustProduct,

    PaymentTypeSuccess: state.GetPaymentTypesReducer.Success,
    PaymentTypeFailure: state.GetPaymentTypesReducer.Failure,
    PaymentTypeList: state.GetPaymentTypesReducer.PaymentTypes,

    SCOrdersSuccess: state.GetSellnchillReducer.SOrdersSuccess,
    SCOrdersFailure: state.GetSellnchillReducer.SOrdersFailure,
    SCOrders: state.GetSellnchillReducer.SOrders,
    SCTokenSuccess: state.GetSellnchillReducer.STokenSuccess,
    SCTokenFailure: state.GetSellnchillReducer.STokenFailure,
    SCToken: state.GetSellnchillReducer.SToken,
    SCOrdersDetailSuccess: state.GetSellnchillReducer.SOrdersDetailSuccess,
    SCOrdersDetailFailure: state.GetSellnchillReducer.SOrdersDetailFailure,
    SCOrdersDetail: state.GetSellnchillReducer.SOrdersDetail,

    UserDetails: state.LoginReducer.UserDetails,
    LoaderState: state.LoaderReducer.State
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetJobTypes: () => dispatch(GetJobTypes()),
    GetPaymentTypes: () => dispatch(GetPaymentTypes()),
    AddService: (reqData) => dispatch(AddService(reqData)),
    GetCustomerProduct: (reqData) => dispatch(GetCustomerProduct(reqData)),
    AddServiceDetail: (reqData) => dispatch(AddServiceDetail(reqData)),
    refreshReduxStates: () => dispatch(refreshReduxStates()),
    GetSellnchillToken: (reqData) => dispatch(GetSellnchillToken(reqData)),
    GetCustomerOrders: (reqData) => dispatch(GetCustomerOrders(reqData)),
    GetCustomerOrderDetails: (reqData) => dispatch(GetCustomerOrderDetails(reqData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CreateService));
