import React, { useState, useEffect, Component } from 'react';
import {
    Grid, Dialog, GridList, DialogContent,
    GridListTile, DialogTitle, makeStyles,
    Typography, Divider, IconButton, DeleteIcon,
    EditIcon, _, swal, toast, useSelector, useDispatch,
    Card, CardMedia, Button, moment, Box, DialogActions
} from '../../utilities/PackagesList';
import {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown,
    Buttons, LoadingOverlay
} from "../../utilities/ComponentsList";
import { isEmptyObj, DMY, CDatePlusYMD, CheckBetween , CDateMinusYMD, DDMY} from "../../utilities/AppFunctions";
import { AssignService, GetTeamService } from '../../api/Services';
import { getTeams } from './../../api/Team';
import { refreshReduxStates } from './../../api/Refresh';
import useStyles from "./../../utilities/Styles";
const EditLeaves = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [Addvalues, setAddValues] = React.useState({
        Team: '',
        TeamSelected: ''
    });
    const [Createerrors, setCreateErrors] = useState({});
    const TeamsResponse = useSelector((state) => state.getTeamsReducer);
    const TeamListData = TeamsResponse.TeamList ? TeamsResponse.TeamList : [];   
    let ParentState = props.parentState;
    let UpdatingData = ParentState.EditData;
    let LeaveDates = ""
    let ExistTeams = [];
    useEffect(() => {
        dispatch(getTeams(1));
        let TeamID = UpdatingData ? UpdatingData.extendedProps.teamId : "";
        let FilterData = _.filter(TeamListData,{'id':TeamID});
        //alert(TeamID+'--'+JSON.stringify(FilterData))
        setAddValues({
            ...Addvalues,
            Team: FilterData.length>0 ? FilterData[0] : "",
            TeamSelected: TeamID
        })
    }, []) 
    
    if(UpdatingData){
        let startDate = UpdatingData.start;
        let EndDate = UpdatingData.end ? UpdatingData.end : UpdatingData.start;
        LeaveDates = DDMY(startDate)+" to "+DDMY(EndDate);
        ExistTeams.push(UpdatingData.extendedProps.teamId)
    }
    const submitForm = () => {
        let srrorObj = {};
        let errorStatus = false;
        Object.keys(Addvalues).map(function (k) {
            srrorObj[k] = Addvalues[k] ? false : true;
            errorStatus = errorStatus ? true : !Addvalues[k];
        })
        setCreateErrors(srrorObj);
        if (!errorStatus) {
            props.ModelConfirm(Addvalues.Team)
        }
    };

    return (
        <Dialog
            open={props.Status}
            //onClose={()=>this.closeDialog()}
            fullWidth={true}
            fullScreen={false}
            maxWidth="sm"
            //  className={classes.dialogWindow}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
        >
            <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={4}>
                    <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                        <Dropdown
                            label={"Select Team"}
                            data={TeamListData}
                            getOptionLabel={option => (option.teamName ? option.teamName : "")}
                            getOptionSelected={(option, value) => option.id === value.id}
                            //getOptionDisabled={(option) => ExistTeams.includes(option.id)}
                            value={Addvalues.Team}
                            ItemChange={(event, newValue) => {
                                setAddValues({
                                    ...Addvalues,
                                    TeamSelected: newValue ? newValue.id : null,
                                    Team: newValue ? newValue : null
                                });
                                setCreateErrors({
                                    ...Createerrors,
                                    TeamSelected: newValue ? false : true,
                                    Team: newValue ? false : true
                                })
                            }}
                            error={Createerrors.TeamSelected}
                            initialName={"Team"}
                            errorMsg={"Please choose team."}
                        />
                    </Grid>
                    <Grid item container direction="row" lg={12} md={12} sm={12} xl={12} xs={12} >
                        <Grid item md={8} >
                            <Typography className={classes.boldText}>Leave Date(s) : {LeaveDates}</Typography>
                        </Grid>
                        <Grid container md={4} direction="row" item style={{ display: "flex", justifyContent: "flex-end",alignContent:'center' }}>
                        <Button
                            onClick={() => props.parentClass.RemoveClick(UpdatingData)}
                            variant="contained"
                            color="secondary"
                            className={classes.buttonCancel}
                        >
                            Delete
                        </Button>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </DialogContent>
            <DialogActions>
                <Buttons
                    close={() => props.ModelCancel()}
                    closeText={"Cancel"}
                    save={() => submitForm()}
                    saveText={"Submit"}
                />
            </DialogActions>
        </Dialog>
    );
}

export default EditLeaves;