import React, { useState, useEffect, Component } from "react";
import {
  Grid, TextField, Button, Switch,
  FormControlLabel, IconButton, DeleteIcon,
  EditIcon, MUIDataTable, Autocomplete, connect,
  _, swal, toast
} from "./../../utilities/PackagesList";
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay, ButtonIcon
} from "./../../utilities/ComponentsList";
import { VechileItems } from "./../../utilities/MockData";
import {isEmptyObj, UnChangeObj} from "./../../utilities/AppFunctions";
import { getStaffTypes, getStaffs, AddStaff, UpdateStaff, DeleteStaff } from './../../api/Staff';
import { refreshReduxStates } from './../../api/Refresh';
import { LoaderStatus } from './../../api/Loading';
import CreateStaffs from "./CreateStaffs";
import EditStaffs from "./EditStaffs";

let getStaffState = false;
class Staffs extends Component {

  constructor(props) {
      super(props);
      this.state = {
          DialogState: false,
          DeleteID: null,
          EditID: null,
          SelectedIndex: null,
          ModelType: null,
          ModelState: false,
          EditModelState: false,
          StaffLists: [],
          StaffTypes: [],
          isLoading: true,
          getCalls:[1,1],
          postCalls:[0,0,0],
          SelectingList:[],
      }
      props.getStaffs();
      props.getStaffTypes();
  }
  UNSAFE_componentWillReceiveProps=(props)=>{
    if(this.state.getCalls[0]){
        
        if(props.StaffListSuccess){
          let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = getStaffState ? false : succed.includes(1);
          getStaffState = false;
            this.setState({
              StaffLists : props.StaffLists,
              isLoading : loaderStatus,
              getCalls:succed
            })
        }else if(props.StaffListFailure){
          let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = getStaffState ? false : succed.includes(1);
          getStaffState = false;
          this.setState({
            StaffLists : [],
            getCalls:succed,
            isLoading : loaderStatus
          })
        }
    }
    if(this.state.getCalls[1]){
      
      if(props.StaffTypeSuccess){
        let succed = this.state.getCalls;
      succed[1] = 0;
      let loaderStatus = succed.includes(1);
          this.setState({
            StaffTypes : props.StaffTypes,
            getCalls:succed,
            isLoading : loaderStatus
          })
      }else if(props.StaffTypeFailure){
        let succed = this.state.getCalls;
      succed[1] = 0;
      let loaderStatus = succed.includes(1);
        this.setState({
          StaffTypes : [],
          getCalls:succed,
          isLoading : loaderStatus
        })
      }
    }
    if(this.state.postCalls[2]){
      if(props.DeleteStaffSuccess){
        let updatedStaffList = this.state.StaffLists;
        updatedStaffList.splice(this.state.SelectedIndex,1);
          this.setState({
            postCalls:[0,0,0],
            isLoading : false,
            DialogState: false,
            DeleteID: null,
            SelectedIndex: null,
            getCalls:[0,0],
            StaffLists : updatedStaffList
          },()=>{
           /*  getStaffState = true;
            this.props.getStaffs(); */
            this.props.refreshReduxStates();
            toast.success('Deleted Successfully!', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: false,
              });
          })
      }else if(props.DeleteStaffFailure){
        this.setState({
          postCalls:[0,0,0],
          isLoading : false,
          DialogState: false,
          DeleteID: null,
          SelectedIndex: null,
        },()=>{
          toast.error('Failed!'+props.DeleteStaffResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
            });
        })
      }
    }
}
  editPress=(id, index)=>{
    //let EditID = id;
    let staffDatas = UnChangeObj(this.state.StaffLists);
    //let staffSelected = staffDatas[index]; 
    this.setState({
      EditModelState: true,
      EditID: id,
      ModelType: "Edit",
      SelectingList: staffDatas,
      SelectedIndex: index
    }) 
  }
  CloseEdit = () => {
    this.setState({
      EditModelState: false,
      ModelType: null,
      EditID: null,
      SelectingList:[],
      SelectedIndex: null,
    })
  }
  EditedStaff = (EditedObject) => {
    let ListData = this.state.StaffLists;
    let EditingID = this.state.EditID;
    let ItemIndex = ListData.findIndex(itm => itm.id === EditingID);
    ListData[ItemIndex] = EditedObject;
    this.setState({
      EditModelState: false,
      ModelType: null,
      EditID: null,
      SelectingList:[],
      SelectedIndex: null,
      isLoading: false,
      StaffLists: ListData,
      getCalls:[0,0],
    },()=>{
      this.props.refreshReduxStates();
      /* getStaffState = true;
      this.props.getStaffs(); */
    })
  }
  deletePress=(id, index)=>{
    //let staffDatas = this.state.StaffLists;
    //let staffSelected = staffDatas[index];
    this.setState({
      DialogState: !this.state.DialogState,
      DeleteID: id,
      SelectedIndex: index      
    }) 
  }
  DeleteClose = () => {
    this.setState({
      DialogState: !this.state.DialogState,
      DeleteID: null,
      SelectedIndex: null,
    })
  }
  
  DeleteConfirm = () => {
    this.setState({
      isLoading: true,
      postCalls:[0,0,1]
    },()=>{
      this.props.DeleteStaff(this.state.DeleteID);
    })
  }
  AddingStaff = () => {
    this.setState({
      ModelState: true,
      ModelType: "Add",
    }) 
  }
  
  CloseCreate = () => {
    this.setState({
      ModelState: !this.state.ModelState,
      ModelType: null,
      EditID: null,
      SelectedIndex: null,
    })
  }
  AddedStaff = () => {
    this.setState({
      ModelState: !this.state.ModelState,
      ModelType: null,
      EditID: null,
      SelectedIndex: null,
      isLoading: true,
      getCalls:[1,0,0],
    },()=>{
      getStaffState = true;
      this.props.getStaffs();
    })
  }
  render(){
    const columns = [
      {
        name: "id",
        label: "ID",
        options: {
          filter: false,
          sort: false,
          display:false
        }
      },{
        name: "empId",
        label: "Emp ID",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "firstName",
        label: "First Name",
        options: {
          display:false,
        }
      },
      {
        name: "lastName",
        label: "Last Name",
        options: {
          display:false,
        }
      },
      {
        name: "firstName",
        label: "Name",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            console.log(tableMeta.rowData, '......');
            return (
                <div>{value} {tableMeta.rowData[3]}</div>
            );
        }
        }
      },
      {
        name: "email",
        label: "Email",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "phone",
        label: "Phone",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "staffTypeId",
        label: "Staff Type",
        options:{
          filter: false,
          sort: true,
        }
      },
      {
        name: "",
        label: "Actions",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <ButtonIcon 
                EditTitle={"Edit Staff"}
                DeleteTitle={"Delete Staff"}
                Edit={() => this.editPress(tableMeta.rowData[0], tableMeta.rowIndex)}
                Delete={() => this.deletePress(tableMeta.rowData[0], tableMeta.rowIndex)}
                />
              )
          }
        }
      }
    ];
   
    return (
      <>
        {/* <PageTitle title="Staffs" /> */}
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
          <MUIDataTable
            title="Staffs List"
            data={this.state.StaffLists}
            columns={columns}
            options={{
              sort: true,
              selectableRows: false,
              print: false,
              download: false,
              viewColumns: false,
              filter: false,
              responsive:true,
              customToolbar: () => {
                return (
                  <CustomToolbar title={"Add Staff"} iconOnPress={() => this.AddingStaff()} />
                );
              }
            }}
          />
          </Grid>
          <AlertDialog
            Status={this.state.DialogState}
            DialogConfirm={()=>{this.DeleteConfirm()}}
            DialogCancel={()=>{this.DeleteClose()}}
            Title={"Delete Staff"}
            Content={"Are you sure, you want to delete the staff ?"}
          />
          {this.state.ModelState ?<CreateStaffs
            Title={"Add Staff"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.ModelState}
            ModelConfirm={this.AddedStaff}
            ModelCancel={this.CloseCreate} 
          /> : null}
          {this.state.EditModelState ?<EditStaffs
            Title={"Edit Staff"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.EditModelState}
            ModelConfirm={this.EditedStaff}
            ModelCancel={this.CloseEdit} 
          /> : null}
          <LoadingOverlay Status={this.state.isLoading} /> 
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    StaffListSuccess: state.getStaffsReducer.Success,
    StaffListFailure: state.getStaffsReducer.Failure,
    StaffLists: state.getStaffsReducer.StaffList,

    StaffTypeSuccess: state.getStaffTypesReducer.Success,
    StaffTypeFailure: state.getStaffTypesReducer.Failure,
    StaffTypes: state.getStaffTypesReducer.StaffTypes,

    AddStaffSuccess: state.AddStaffReducer.Success,
    AddStaffFailure: state.AddStaffReducer.Failure,
    AddStaffResponse: state.AddStaffReducer.AddingResponse,

    EditStaffSuccess: state.UpdateStaffReducer.Success,
    EditStaffFailure: state.UpdateStaffReducer.Failure,
    EditStaffResponse: state.UpdateStaffReducer.UpdateResponse,

    DeleteStaffSuccess: state.DeleteStaffReducer.Success,
    DeleteStaffFailure: state.DeleteStaffReducer.Failure,
    DeleteStaffResponse: state.DeleteStaffReducer.DeleteResponse,

    UserDetails: state.LoginReducer.UserDetails,
    LoaderState: state.LoaderReducer.State
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaffs: () => dispatch(getStaffs()),
    getStaffTypes: () => dispatch(getStaffTypes()),
    AddStaff: (addObject) => dispatch(AddStaff(addObject)),
    UpdateStaff: (updateObject, StaffID) => dispatch(UpdateStaff(updateObject, StaffID)),
    DeleteStaff: (StaffID) => dispatch(DeleteStaff(StaffID)),
    refreshReduxStates: () => dispatch(refreshReduxStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Staffs);
