import React, { useState, useEffect, Component } from "react";
import {
  Grid, TextField, Button, Switch, Tooltip,
  FormControlLabel, IconButton, DeleteIcon,
  EditIcon, MUIDataTable, Autocomplete, connect,
  _, swal, toast, Typography, Checkbox, withStyles
} from "../../utilities/PackagesList";
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay, ButtonIcon
} from "../../utilities/ComponentsList";
import { DateMinusYMD, PriceFormat, convertDateDMY, todayDate, dateTimetoDate, dateTimetoTime, UnChangeObj } from "../../utilities/AppFunctions";
import { GetAdhocService, DeleteAdhocService } from '../../api/Services';
import { getTeams } from '../../api/Team';
import { refreshReduxStates } from '../../api/Refresh';
import { LoaderStatus } from '../../api/Loading';
import CreateAdhoc from "./CreateAdhoc";
import EditAdhoc from "./EditAdhoc";
import styles from "../../utilities/StylesClass";

class AdhocService extends Component {

  constructor(props) {
    super(props);
    this.state = {      
      DateStartSelected: DateMinusYMD(15),
      DateEndSelected: todayDate(),
      AddModel:false,
      EditModel:false,
      DeleteModel:false,
      EditID:null,
      DeleteID:null,
      ChosenIndex:null,
      getCalls: [1],
      postCalls:[0],
      AdhocServiceLists:[],
      SelectingList : []
    }
   
    let filterObj = {
      StartDate: DateMinusYMD(15),
      EndDate: todayDate()
    }
    props.GetAdhocService();
  }
  UNSAFE_componentWillReceiveProps = (props) => {
    if (this.state.getCalls[0]) {
      if (props.AdhocServiceSuccess) {
       /*  let postalSelected = this.state.PostalCode;
        let ContactNo = this.state.FilterContactNo; */
        let services = props.AdhocServiceList;
        services = _.orderBy(services, ['createdAt'],['asc']);
        /* let FilteredService = _.filter(services, function (item) {
          return (postalSelected ? (postalSelected == item.postalCode) : true)
            && (ContactNo ? (ContactNo == item.phoneNumber) : true);
        }); */
        this.setState({
          AdhocServiceLists: services,
          isLoading: false,
          getCalls: [0]
        })
      } else if (props.AdhocServiceFailure) {
        this.setState({
          AdhocServiceLists: [],
          getCalls: [0],
          isLoading: false
        })
      }
    }
    if (this.state.postCalls[0]) {
      if (props.DeleteAdhocServiceSuccess) {
        let updatedList = this.state.AdhocServiceLists;
        updatedList.splice(this.state.ChosenIndex, 1);
        this.setState({
          postCalls: [0],
          isLoading: false,
          DeleteModel: false,
          DeleteID: null,
          ChosenIndex: null,
          AdhocServiceLists: updatedList
        }, () => {
          this.props.refreshReduxStates();
          toast.success('Deleted Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      } else if (props.DeleteAdhocServicecFailure) {
        this.setState({
          postCalls: [0],
          isLoading: false,
          DeleteModel: false,
          DeleteID: null,
          ChosenIndex: null,
        }, () => {
          toast.error(props.DeleteAdhocServiceResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      }
    }
  }
  //DELETE ADHOC
  deletePress = (id, index) => {
    this.setState({
      DeleteModel:true,
      DeleteID:id,
      ChosenIndex:index,
    })
  }
  DeleteClose = () => {
    this.setState({
      DeleteModel:false,
      DeleteID:null,
      ChosenIndex:null,
    })
  }

  DeleteConfirm = () => {
    this.setState({
      isLoading: true,
      postCalls: [1],
      getCalls: [0]
    }, () => {
      this.props.DeleteAdhocService(this.state.DeleteID);
    })
  }

  CloseAdd = () => {
    this.setState({
      AddModel:false,
    })
  }
  AddingService = () => {
    this.setState({
      AddModel:true,
    })
  }
  AddedAdhoc = () => {
    this.setState({
      AddModel:false,
      getCalls:[1]
    },()=>{
      let filterObj = {
        StartDate: DateMinusYMD(15),
        EndDate: todayDate()
      }
      this.props.GetAdhocService(filterObj);
    })
  }
  //EDITING ADHOC
  editPress = (id, index) => {
    let SelectionList = UnChangeObj(this.state.AdhocServiceLists)
    this.setState({
      EditID:id,
      ChosenIndex:index,
      SelectingList : SelectionList,
      EditModel:true,
    })
  }
  CloseEdit = () => {
    this.setState({
      EditModel:false,
      EditID:null,
      SelectingList:[],
      ChosenIndex:null,
    })
  }
  EditedAdhoc = (EditedObject) => {
    let ListData = this.state.AdhocServiceLists;
    let EditedID = this.state.EditID;
    let newList = ListData.filter(function (value, index) {
      let Exist = value.id === EditedID;
      if (Exist) {
        value.product= EditedObject.product;
        value.cost= EditedObject.cost;
        value.sku= EditedObject.sku;
      }
      return value;
    });
    this.setState({
      AdhocServiceLists : newList,
      EditModel:false,
      EditID:null,
      SelectingList:[],
      ChosenIndex:null,
    }, () => {
      this.props.refreshReduxStates();
    })
  }
  //EDIT ADHOC END
  
  searchClick = () => {
    if (!this.state.DateStartSelected || !this.state.DateEndSelected) {
      toast.warning("Please choose start and end date", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      });
      return;
    }
    let dateStartPicked = this.state.DateStartSelected ? this.state.DateStartSelected : null;
    let dateEndPicked = this.state.DateEndSelected ? this.state.DateEndSelected : null;
    let filterObj = {
      StartDate: dateStartPicked,
      EndDate: dateEndPicked
    }
    console.log("filterObj",JSON.stringify(filterObj))
    this.setState({
      isLoading:true,
      getCalls: [1]
    },()=>{
      this.props.GetAdhocService(filterObj);
    })
  }
  clearSearchClick = (type) => {
    let filterObj = {
      StartDate: DateMinusYMD(15),
      EndDate: todayDate()
    }
    this.setState({
      DateStartSelected: DateMinusYMD(15),
      DateEndSelected: todayDate(),
      isLoading: true,
      getCalls: [1],
    },()=>{
      this.props.GetAdhocService(filterObj);
    })
  }
  render() {
    const { classes } = this.props;
    const columns = [
      {
        name: "id",
        label: "SNO",
        options: {
          sort: true,
          filter: false,
          display:true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item md={12}>
                  <Typography>{tableMeta.rowIndex+1}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "sku",
        label: "SKU",
        options: {
          sort: true,
          filter: false,
          display:true
        }
      },
      {
        name: "product",
        label: "Product/Service",
        options: {
          filter: false,
          sort: true,
          display:true
        }
      },
      {
        name: "cost",
        label: "Cost",
        options: {
          filter: false,
          sort: true,
          display:true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item md={12}>
                    <Typography>{PriceFormat(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "createdAt",
        label: "Service Date",
        options: {
          filter: false,
          sort: true,
          display:false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item md={12}>
                    <Typography>{convertDateDMY(value)}</Typography>
              </Grid>
            )
          }
        }
      },
      {
        name: "",
        label: "Actions",
        options: {
          filter: false,
          sort: false,
          display:true,
          setCellProps: () => ({ style: { minWidth: "120px"} }),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <ButtonIcon
                EditTitle={"Edit Adhoc Service"}
                DeleteTitle={"Delete Adhoc Service"}
                ViewTitle={null}
                Edit={() => this.editPress(tableMeta.rowData[0], tableMeta.rowIndex)}
                Delete={() => this.deletePress(tableMeta.rowData[0], tableMeta.rowIndex)}
              />
            )
          }
        }
      }
    ];


    return (
      <>
        <Grid container spacing={4}>
          {/* <Grid container item spacing={1} md={12} direction={"row"} style={{ marginTop: 0 }}>
            <Grid item md={2}>
              <InputField
                label={"Start Date"}
                type={"date"}
                required={false}
                value={this.state.DateStartSelected}
                changeText={(data) => { this.setState({ DateStartSelected: data ? data.target.value : null }) }}
                // error={Createerrors.Date}
                initialName={"Start Date"}
                errorMsg={"Please enter date."}
              />
            </Grid>
            <Grid item md={2}>
              <InputField
                label={"End Date"}
                type={"date"}
                required={false}
                value={this.state.DateEndSelected}
                changeText={(data) => { this.setState({ DateEndSelected: data ? data.target.value : null }) }}
                // error={Createerrors.Date}
                initialName={"End Date"}
                errorMsg={"Please enter date."}
              />
            </Grid>
            <Grid item md={1} >
              <Button
                onClick={() => this.clearSearchClick()}
                className={classes.buttonCancel}
                variant="contained" color="primary">
                Clear
                  </Button>
            </Grid>
            <Grid item md={1} >
              <Button
                onClick={() => this.searchClick()}
                className={classes.buttonSuccess}
                variant="contained" color="primary">
                Search
                  </Button>
            </Grid>
          </Grid> */}
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <MUIDataTable
              title="Adhoc Service List"
              data={this.state.AdhocServiceLists}
              columns={columns}
              options={{
                sort: true,
                selectableRows: false,
                print: false,
                download: false,
                viewColumns: false,
                responsive:true,
                filter: false,
                customToolbar: () => {
                  return (
                    <CustomToolbar title={"Add Adhoc Service"} iconOnPress={() => this.AddingService()} />
                  );
                }
              }}
            />
          </Grid>
          <AlertDialog
            Status={this.state.DeleteModel}
            DialogConfirm={() => { this.DeleteConfirm() }}
            DialogCancel={() => { this.DeleteClose() }}
            Title={"Delete Adhoc Service"}
            Content={"Are you sure, you want to delete the Adhoc service?"}
          />
          {this.state.AddModel ? <CreateAdhoc
            Title={"Add Adhoc Service"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.AddModel}
            ModelConfirm={this.AddedAdhoc}
            ModelCancel={this.CloseAdd}
          /> : null}
          {this.state.EditModel ? <EditAdhoc
            Title={"Edit Adhoc Service"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.EditModel}
            ModelConfirm={this.EditedAdhoc}
            ModelCancel={this.CloseEdit}
          /> : null}
          <LoadingOverlay Status={this.state.isLoading} />
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    AdhocServiceSuccess: state.GetAdhocServiceReducer.Success,
    AdhocServiceFailure: state.GetAdhocServiceReducer.Failure,
    AdhocServiceList: state.GetAdhocServiceReducer.AdhocServices,

    DeleteAdhocServiceSuccess: state.DeleteAdhocServiceReducer.Success,
    DeleteAdhocServicecFailure: state.DeleteAdhocServiceReducer.Failure,
    DeleteAdhocServiceResponse: state.DeleteAdhocServiceReducer.DeleteResponse,

    UserDetails: state.LoginReducer.UserDetails,
    LoaderState: state.LoaderReducer.State
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetAdhocService: (filterObj) => dispatch(GetAdhocService(filterObj)),
    DeleteAdhocService: (id) => dispatch(DeleteAdhocService(id)),
    refreshReduxStates: () => dispatch(refreshReduxStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdhocService));
