import React, { useState, useEffect, Component } from 'react';
import {
  Grid, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, makeStyles,
  Switch, FormControlLabel, IconButton, DeleteIcon,
  EditIcon, _, swal, toast, useSelector, useDispatch
} from '../../utilities/PackagesList';
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay
} from "../../utilities/ComponentsList";
import { isEmptyObj } from "../../utilities/AppFunctions";
import { refreshReduxStates } from './../../api/Refresh';
const useStyles = makeStyles(theme => ({
  dialogTitle: {

  }
}))
const CreateService = (props) => {
  const dispatch = useDispatch();
  const createState = useSelector((state) => state.AddAdhocReducer);
  const classes = useStyles();
  const [Addvalues, setAddValues] = React.useState({
    Address: '',
    PostalCode: '',
    CustomerNumber : '',
    CustomInvoice:'',
    CustomerName:'',
    Items:'',
    Remarks:''
  });
  const [Createerrors, setCreateErrors] = useState({});
  
  if (createState.Success && !isEmptyObj(createState.AddingResponse)) {
    toast.success('Added Successfully!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: false,
    });
    props.parentClass.AddedService()
  } else if (createState.Failure && !isEmptyObj(createState.AddingResponse)) {
    toast.error(createState.AddingResponse.message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: false,
    });
    dispatch(refreshReduxStates());
  }
  const handleChange = name => event => {
    let targetValue = event.target.value;
    setAddValues({ ...Addvalues, [name]: targetValue });
      setCreateErrors({ ...Createerrors, [name]: targetValue ? false : true })
    
  };
  
  const submitForm = () => {
    let srrorObj = {};
    let errorStatus = false;
    Object.keys(Addvalues).map(function (k) {
      if(k==='StaffSelected'){
        srrorObj[k] = (Addvalues[k].length>0) ? false : true;
        errorStatus = errorStatus ? true : !(Addvalues[k].length>0);
      }else if(k==='Remarks'){
        srrorObj[k] = false;
        errorStatus = errorStatus ? true : false;
        
      }else if(k==='CustomInvoice'){
        srrorObj[k] = false;
        errorStatus = errorStatus ? true : false;
        
      }else{
        srrorObj[k] = Addvalues[k] ? false : true;
        errorStatus = errorStatus ? true : !Addvalues[k];
      }
      
    })
   setCreateErrors(srrorObj);
    if(!errorStatus){
      let addObject = {
          customerName: Addvalues.CustomerName,
          customerNumber : Addvalues.CustomerNumber,
          invoiceNumber : Addvalues.CustomInvoice,
          address : Addvalues.Address,
          postalCode : Addvalues.PostalCode,
          items : Addvalues.Items,
          remarks : Addvalues.Remarks
      }
      //console.log(JSON.stringify(addObject))
      //dispatch(AddAdhoc(addObject));
    } 
  };

  return (
    <Dialog
      open={props.Status}
      //onClose={()=>this.closeDialog()}
      fullWidth
      maxWidth="sm"
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
    >
      <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
      <DialogContent dividers>
      <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <Grid
              container
              spacing={6}
              direction="row">
              <Grid
                  item
                  lg={6} md={6} sm={6} xl={6} xs={6}
                  spacing={6}
                >
                 <InputField
                    label={"Customer Name"}
                    type={"text"}
                    required={true}
                    value={Addvalues.CustomerName}
                    changeText={handleChange('CustomerName')}
                    error={Createerrors.CustomerName}
                    initialName={"Customer Name"}
                    errorMsg={"Please enter customer name."}
                  />
              </Grid>
              <Grid
                  item
                  lg={6} md={6} sm={6} xl={6} xs={6}
                  spacing={6}
                >
                 <InputField
                    label={"Custom Invoice"}
                    type={"number"}
                    required={true}
                    value={Addvalues.CustomInvoice}
                    changeText={handleChange('CustomInvoice')}
                    error={Createerrors.CustomInvoice}
                    initialName={"Custom Invoice"}
                    errorMsg={"Please enter custom invoice."}
                  />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={6}
              direction="row">
              <Grid
                  item
                  lg={6} md={6} sm={6} xl={6} xs={6}
                  spacing={6}
                >
                 <InputField
                    label={"Contact Number"}
                    type={"number"}
                    required={true}
                    value={Addvalues.CustomerNumber}
                    changeText={handleChange('CustomerNumber')}
                    error={Createerrors.CustomerNumber}
                    initialName={"Contact Number"}
                    errorMsg={"Please enter Contact number."}
                  />
              </Grid>
              <Grid
                  item
                  lg={6} md={6} sm={6} xl={6} xs={6}
                  spacing={6}
                >
                 <InputField
                    label={"Address"}
                    type={"text"}
                    required={true}
                    value={Addvalues.Address}
                    changeText={handleChange('Address')}
                    error={Createerrors.Address}
                    initialName={"Address"}
                    errorMsg={"Please enter address."}
                  />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={6}
              direction="row">
              <Grid
                  item
                  lg={6} md={6} sm={6} xl={6} xs={6}
                  spacing={6}
                >
                 <InputField
                    label={"Postal Code"}
                    type={"number"}
                    required={true}
                    value={Addvalues.PostalCode}
                    changeText={handleChange('PostalCode')}
                    error={Createerrors.PostalCode}
                    initialName={"Postal Code"}
                    errorMsg={"Please enter postal code."}
                  />
              </Grid>
              
            </Grid>
            
            <Grid
              container
              spacing={6}
              >
                <Grid
                  item
                  lg={12} md={12} sm={12} xl={12} xs={12}
                  spacing={6}
                >
                <InputField
                    label={"Items"}
                    type={"text"}
                    multiline={true}
                    required={true}
                    rows={5}
                    value={Addvalues.Items}
                    changeText={handleChange('Items')}
                    error={Createerrors.Items}
                    initialName={"Items"}
                    errorMsg={"Please enter Items."}
                  />
                  </Grid>
              </Grid>
              <Grid
              container
              spacing={6}
              >
                <Grid
                  item
                  lg={12} md={12} sm={12} xl={12} xs={12}
                  spacing={6}
                >
                <InputField
                    label={"Remarks"}
                    type={"text"}
                    multiline={true}
                    required={false}
                    rows={3}
                    maxLength={600}
                    value={Addvalues.Remarks}
                    changeText={handleChange('Remarks')}
                    error={Createerrors.Remarks}
                    initialName={"Remarks"}
                    errorMsg={"Please enter remarks."}
                  />
                  </Grid>
              </Grid>
          </Grid>
          <Buttons
              close={() => props.parentClass.CloseAdd()}
              closeText={"Cancel"}
              save={() => submitForm()}
              saveText={"Create"}
            />
        </Grid>
     </DialogContent>
    </Dialog>
  );
}

export default CreateService;