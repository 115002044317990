import React, { useState, useEffect, Component } from 'react';
import {
    Grid, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, makeStyles,
    Switch, FormControlLabel, IconButton, DeleteIcon,
    EditIcon, _, swal, toast, useSelector, useDispatch
} from '../../utilities/PackagesList';
import {
    PageTitle, CustomToolbar, AlertDialog,
    CustomModel, InputField, Dropdown,
    Buttons, LoadingOverlay
} from "../../utilities/ComponentsList";
import { isEmptyObj } from "../../utilities/AppFunctions";
import { UpdateTeam } from '../../api/Team';
const useStyles = makeStyles(theme => ({
    dialogTitle: {

    }
}))
const EditTeams = (props) => {
    const dispatch = useDispatch();
    const EditState = useSelector((state) => state.UpdateTeamReducer);
    const classes = useStyles();
    const [Editvalues, setEditValues] = useState({
        Vehicle: '',
        TeamsName: '',
        Staff: [],
        VehicleSelected: '',
        StaffSelected : [],
        TeamType: '',
        TeamTypeID: '',
      });
      
    const [Editerrors, setEditErrors] = useState({});

    let ParentState = props.parentState;
    let EditingID = ParentState.EditID;
    let userSelection = ParentState.SelectedIndex;
    let TeamListData = ParentState.SelectingList ? _.filter(ParentState.SelectingList, { 'id': EditingID }) : [];
    TeamListData = TeamListData.length>0 ? TeamListData[0]: [];  
    let AssignedStaffs = TeamListData ? TeamListData.assignedStaff : [];
    let UnAssignedList =  ParentState.UnAssignedSV ;
    let unAssignedStaffs = UnAssignedList.length>0 ? UnAssignedList[0].staffs : [];
    let unAssignedVechiles = UnAssignedList.length>0 ?  UnAssignedList[0].vehicles : [];
    let AssignedVechile = {
        "vehicleId": TeamListData.vehicelId,
        "vehicleNumber": TeamListData.vehicleNumber
    };
    const AssignedStaffName = [];
    const AssignedStaffID = [];
  
    let checkVechileExist = _.filter(unAssignedVechiles, { 'vehicleId': TeamListData.vehicelId});
    if(parseInt(checkVechileExist.length)===0){
        unAssignedVechiles.push({"vehicleId":TeamListData.vehicelId,"vehicleNumber":TeamListData.vehicleNumber});
    }
    AssignedStaffs.map((v) => {
        AssignedStaffName.push({"staffId":v.id,"staffName":v.name});
        AssignedStaffID.push(v.id);
        let checkStaffExist = _.filter(unAssignedStaffs, { 'staffId': v.id});
        if(parseInt(checkStaffExist.length)===0){
            unAssignedStaffs.push({"staffId":v.id,"staffName":v.name});
            
        }
        
        
    });
    let TeamTypeList = props.parentState.TeamTypeLists;
    let TeamTypeSelected = "";let TeamTypeSelectedID = "";
    if(TeamTypeList.length>0 && ParentState.EditID){
      let checkExist = _.filter(TeamTypeList, { 'teamTypeId': TeamListData.teamTypeId});
      TeamTypeSelected = checkExist[0];
      TeamTypeSelectedID = checkExist[0].teamTypeId;
    }
    useEffect(() => {
        if(ParentState.EditID){
          setEditValues({
            Vehicle: ParentState.EditID ? AssignedVechile : "",
            TeamsName: ParentState.EditID ? TeamListData.teamName : "",
            Staff: ParentState.EditID ? AssignedStaffName : [],
            VehicleSelected: ParentState.EditID ? TeamListData.vehicelId : "",
            StaffSelected : ParentState.EditID ? AssignedStaffID : [],
            TeamType: ParentState.EditID ? TeamTypeSelected : "",
            TeamTypeID: ParentState.EditID ? TeamTypeSelectedID : "",
          })
        } 
        
      }, [ParentState.EditID])
      if (EditState.Success && !isEmptyObj(EditState.UpdateResponse)) {
        toast.success('Updated Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
        let StaffList = [];
          Editvalues.Staff.map((v) => {
            StaffList.push({
              "name":v.staffName,
              "empId":v.staffId,
              "id":v.staffId
            });            
          });
          let EditedObject={
            "id": parseInt(props.parentState.EditID),
            "teamName": Editvalues.TeamsName,
            "vehicleNumber": Editvalues.Vehicle.vehicleNumber,
            "vehicelId": Editvalues.VehicleSelected,
            "assignedStaff": StaffList,
            "teamTypeId": Editvalues.TeamTypeID,
            "teamTypeName": Editvalues.TeamType.teamTypeName
          }
       
        props.parentClass.EditedTeam(EditedObject)
    } else if (EditState.Failure && !isEmptyObj(EditState.UpdateResponse)) {
        toast.error(EditState.UpdateResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
        });
    }
    const handleChange = name => event => {
        let targetValue = event.target.value;
        
        setEditValues({ ...Editvalues, [name]: targetValue });
        setEditErrors({ ...Editerrors, [name]: targetValue ? false : true })
      };
      const saveEdit = () => {
        let srrorObj = {};
        let errorStatus = false;
        Object.keys(Editvalues).map(function (k) {
          srrorObj[k] = Editvalues[k] ? false : true;
          errorStatus = errorStatus ? true : !Editvalues[k];
          if(k==='StaffSelected'){
            srrorObj[k] = (Editvalues[k].length>0) ? false : true;
            errorStatus = errorStatus ? true : !(Editvalues[k].length>0);
          }
        })
        setEditErrors(srrorObj);
        if(Editvalues.TeamsName && Editvalues.StaffSelected.length>0 && 
            Editvalues.VehicleSelected && Editvalues.TeamTypeID){
          let updateObject = {
            vehicleId: Editvalues.VehicleSelected,
            staffId : Editvalues.StaffSelected,
            teamName: Editvalues.TeamsName,
            teamTypeId: Editvalues.TeamTypeID,
          }
         
         dispatch(UpdateTeam(updateObject,ParentState.EditID));
        } 
      };

      return (
        <Dialog
          open={props.Status}
          fullWidth
          maxWidth="sm"
          disableBackdropClick={false}
          disableEscapeKeyDown={false}
        >
          <DialogTitle className={classes.dialogTitle} id="dialog-title">{props.Title}</DialogTitle>
          <DialogContent dividers>
          <Grid container spacing={4}>
              <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                <Grid
                  container
                  spacing={6}
                  direction="column"
                >
                  <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                  <InputField
                        label="Team Name"
                        type={"text"}
                        required={true}
                        value={Editvalues.TeamsName}
                        changeText={handleChange('TeamsName')}
                        error={Editerrors.TeamsName}
                        initialName={"Name"}
                        errorMsg={"Please enter team name."}
                      />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xl={12} xs={12} >
                <Dropdown
                  label={"Team Type"}
                  data={TeamTypeList}
                  getOptionLabel={option => (option.teamTypeName ? option.teamTypeName : "")}
                  getOptionSelected={(option, value) => option.teamTypeId === value.teamTypeId}
                  value={Editvalues.TeamType}
                  ItemChange={(event, newValue) => {
                    setEditValues({
                      ...Editvalues,
                      TeamTypeID: newValue ? newValue.teamTypeId : null,
                      TeamType: newValue ? newValue : null
                    });
                    setEditErrors({
                      ...Editerrors,
                      TeamTypeID: newValue ? false : true,
                      TeamType: newValue ? false : true
                    })
                  }}
                  error={Editerrors.TeamTypeID}
                  initialName={"Team Type"}
                  errorMsg={"Please choose team type."}
                />
              </Grid>
                  <Grid item lg={12} md={12} sm={12} xl={12} xs={12} >
                  <Dropdown
                      label={"Vehicle Number"}
                      data={unAssignedVechiles}
                      getOptionLabel={option => (option.vehicleNumber ? option.vehicleNumber : "")}
                      getOptionSelected={(option, value) => option.vehicleId === value.vehicleId}
                      value={Editvalues.Vehicle}
                      ItemChange={(event, newValue) => {
                        setEditValues({
                          ...Editvalues,
                          VehicleSelected: newValue ? newValue.vehicleId : null,
                          Vehicle: newValue ? newValue : null
                        });
                        setEditErrors({
                          ...Editerrors,
                          VehicleSelected: newValue ? false : true,
                          Vehicle: newValue ? false : true
                        })
                      }}
                      error={Editerrors.Vehicle}
                      initialName={"Number"}
                      errorMsg={"Please choose vehicle number."}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                  <Dropdown
                      label={"Staff"}
                      data={unAssignedStaffs}
                      multiple={true}
                      filterSelectedOptions={true}
                      getOptionLabel={option => (option.staffName ? option.staffName : "")}
                      getOptionSelected={(option, value) => option.staffId === value.staffId}
                      value={Editvalues.Staff}
                      ItemChange={(event, newValue) => {
                        if (newValue !== null) {
                          const items = [];
                          const IDs = [];
                          newValue.map((v) => {
                            items.push(v);
                            IDs.push(v.staffId)
                          });
                          setEditValues({
                            ...Editvalues,
                            StaffSelected: IDs ? IDs : null,
                            Staff: items ? items : null
                          });
                          setEditErrors({
                            ...Editerrors,
                            StaffSelected: IDs.length>0 ? false : true,
                            Staff: IDs.length>0 ? false : true
                          })
                        }
                      }} 
                      error={Editerrors.StaffSelected}
                      initialName={"Staff"}
                      errorMsg={"Please choose staff."}
                    />
                  </Grid>
                </Grid>
    
              </Grid>
              <Buttons
                close={() => props.parentClass.CloseEdit()}
                closeText={"Cancel"}
                save={() => saveEdit()}
                saveText={"Update"}
              />
            </Grid>
        </DialogContent>
        </Dialog>
      );
}

export default EditTeams;