import React, { useState, useEffect, Component } from "react";
import {
  Grid, TextField, Button, Switch, Tooltip,
  FormControlLabel, IconButton, DeleteIcon,
  EditIcon, MUIDataTable, Autocomplete, connect,
  _, swal, toast, Typography, Checkbox, withStyles
} from "./../../utilities/PackagesList";
import {
  PageTitle, CustomToolbar, AlertDialog,
  CustomModel, InputField, Dropdown,
  Buttons, LoadingOverlay, ButtonIcon
} from "./../../utilities/ComponentsList";
import { DateMinusYMD, LastDayMonth, convertDateDMY, todayDate, DMYD, AMPM, UnChangeObj, GetFullMobileNo} from "../../utilities/AppFunctions";
import { getAdhoc, DeleteAdhoc, getOrder, UpdateOrderStatus } from './../../api/Adhoc';
import { getTeams } from './../../api/Team';
import { SendSMS } from '../../api/Notification';
import { refreshReduxStates } from './../../api/Refresh';
import { LoaderStatus } from './../../api/Loading';
import CreateAdhoc from "./CreateAdhoc";
import EditAdhoc from "./EditAdhoc";
import AssignTeam from "./AssignTeam";
import ViewList from "./ViewList";
import styles from "./../../utilities/StylesClass";
let getAdhocState = false;
class AdhocDelivery extends Component {

  constructor(props) {
    super(props);
    this.state = {
      DialogState: false,
      CancelState: false,
      CancelID: null,
      DeleteID: null,
      EditID: null,
      SelectedIndex: null,
      ModelType: null,
      ModelState: false,
      EditModelState: false,
      TeamsLists: [],
      AdhocLists: [],
      OrderLists: [],
      FilteredOrderList: [],
      isLoading: true,
      getCalls: [1, 1],
      postCalls: [0, 0, 0],
      SelectedOrders: [],
      SelectedOrderIndex: [],
      AssignTeamState: false,
      PostalCode: "",
      FilterContactNo: "",
      DateStartSelected: DateMinusYMD(15),
      DateEndSelected: todayDate(),
      Filter2ListTypes: [{ id: 1, name: "Pick List" }],
      Filter2ListID: 1,
      Filter2ListSelected: { id: 1, name: "Pick List" },
      Filter2Date: "",
      TeamSelectedID: "",
      TeamSelected: "",
      ListModel: false,
      SelectingList:[]
    }
    let filterObj = {
      StartDate: DateMinusYMD(15),
      EndDate: todayDate(),
      OrderType: 1,
      DeliveryStatus: 1,
      SellerType: ""
    }
    props.getTeams(2);
    props.getOrder(filterObj);

  }
  UNSAFE_componentWillReceiveProps = (props) => {

    if (this.state.getCalls[0]) {
      if (props.OrdersListSuccess) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = getAdhocState ? false : succed.includes(1);
        let postalSelected = this.state.PostalCode;
        let ContactNo = this.state.FilterContactNo;
        let orders = props.OrdersLists;//_.filter(props.OrdersLists, { 'orderType': "adHocDelivery" });
        /* let FilteredOrders = _.filter(orders, function (item) {
          return (dateTimetoDate(item.createdAt) >= DateMinusYMD(15) && dateTimetoDate(item.createdAt) <= todayDate());
        }); */
        
        orders = _.orderBy(orders, ['createdAt'],['asc']);
        let FilteredOrders = _.filter(orders, function (item) {
          return (postalSelected ? (postalSelected == item.postalCode) : true)
            && (ContactNo ? (ContactNo == item.contactNumber) : true);
        });
        console.log(JSON.stringify(orders))
        console.log(JSON.stringify(FilteredOrders))
        getAdhocState = false;
        this.setState({
          OrderLists: orders,
          FilteredOrderList: FilteredOrders,
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.OrdersListFailure) {
        let succed = this.state.getCalls;
        succed[0] = 0;
        let loaderStatus = getAdhocState ? false : succed.includes(1);
        getAdhocState = false;
        this.setState({
          OrderLists: [],
          FilteredOrderList:[],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.getCalls[1]) {
      if (props.TeamListSuccess) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          TeamsLists: props.TeamLists,
          isLoading: loaderStatus,
          getCalls: succed
        })
      } else if (props.TeamListFailure) {
        let succed = this.state.getCalls;
        succed[1] = 0;
        let loaderStatus = succed.includes(1);
        this.setState({
          TeamsLists: [],
          getCalls: succed,
          isLoading: loaderStatus
        })
      }
    }
    if (this.state.postCalls[2]) {
      if (props.DeleteAdhocSuccess) {
        let updatedList = this.state.FilteredOrderList;
        updatedList.splice(this.state.SelectedIndex, 1);

        this.setState({
          postCalls: [0, 0, 0],
          isLoading: false,
          DialogState: false,
          DeleteID: null,
          SelectedIndex: null,
          FilteredOrderList: updatedList
        }, () => {
          this.props.refreshReduxStates();
          toast.success('Deleted Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      } else if (props.DeleteAdhocFailure) {
        this.setState({
          postCalls: [0, 0, 0],
          isLoading: false,
          DialogState: false,
          DeleteID: null,
          SelectedIndex: null,
        }, () => {
          toast.error(props.DeleteAdhocResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      }
    }
    if (this.state.postCalls[3]) {
      if (props.OrderUpdateSuccess) {
        let CancelingID = this.state.CancelID;
        let listData = this.state.FilteredOrderList;
        let updatedFilterList = _.filter(listData, function (item) {
          return CancelingID == item.id ? false : true;
        });
        this.setState({
          postCalls: [0, 0, 0],
          isLoading: false,
          CancelID: null,
          CancelState: false,
          FilteredOrderList: updatedFilterList,
        }, () => {
          this.props.refreshReduxStates();
          toast.success('Updated Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      } else if (props.OrderUpdateFailure) {
        this.setState({
          postCalls: [0, 0, 0],
          isLoading: false,
          CancelID: null,
          CancelState: false,
        }, () => {
          toast.error(props.OrderUpdateResponse.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        })
      }
    }
  }



  //DELETE ADHOC
  deletePress = (type, index) => {
    let Data = this.state.FilteredOrderList;
    let Selected = Data[index];
    this.setState({
      DialogState: !this.state.DialogState,
      DeleteID: Selected.id,
      SelectedIndex: index      
    })
  }
  DeleteClose = () => {
    this.setState({
      DialogState: !this.state.DialogState,
      DeleteID: null,
      SelectedIndex: null,
    })
  }

  DeleteConfirm = () => {
    this.setState({
      isLoading: true,
      postCalls: [0, 0, 1],
      getCalls: [0, 0]
    }, () => {
      this.props.DeleteAdhoc(this.state.DeleteID);
    })
  }
  //DELETE ADHOC END
  //ADDING ADHOC
  CloseAdd = () => {
    this.setState({
      ModelState: !this.state.ModelState,
      ModelType: null,
      EditID: null,
      SelectedIndex: null,
    })
  }
  AddingAdhoc = () => {
    this.setState({
      ModelState: true,
      ModelType: "Add",
    })
  }
  AddedAdhoc = () => {
    let filterObj = {
      StartDate: DateMinusYMD(15),
      EndDate: todayDate(),
      OrderType: 1,
      DeliveryStatus: 1,
      SellerType: null
    }
    this.setState({
      ModelState: !this.state.ModelState,
      ModelType: null,
      EditID: null,
      SelectedIndex: null,
      isLoading: true,
      getCalls: [1, 0],
    }, () => {
      getAdhocState = true;
      this.props.getOrder(filterObj);
    })
  }
  //ADDING ADHOC END
  //EDITING ADHOC
  editPress = (id, index) => {
    let Data = UnChangeObj(this.state.FilteredOrderList);
   // let Selected = Data[index];
    this.setState({
      EditModelState: true,
      EditID: id,
      SelectingList : Data,
      ModelType: "Edit",
      SelectedIndex: index
    })
  }
  CloseEdit = () => {
    this.setState({
      EditModelState: false,
      ModelType: null,
      SelectingList:[],
      EditID: null,
      SelectedIndex: null,
    })
  }
  EditedAdhoc = (EditedObject) => {
    /* let ListData = this.state.FilteredOrderList;
    ListData[this.state.SelectedIndex] = EditedObject; */
    let ListData = this.state.FilteredOrderList;
    let EditingID = this.state.EditID;
    let ItemIndex = ListData.findIndex(itm => itm.id === EditingID);
    ListData[ItemIndex] = EditedObject;
    this.setState({
      EditModelState: false,
      ModelType: null,
      EditID: null,
      SelectedIndex: null,
      FilteredOrderList: ListData,
      SelectingList:[],
      //getCalls:[1],
    }, () => {
      this.props.refreshReduxStates();
      /* getAdhocState = true;
     this.props.getAdhoc();  */
    })
  }
  //EDIT ADHOC END
  //ASSIGN TEAM
  AssignedTeam = (updatedDetails) => {
    let ListData = this.state.FilteredOrderList;
    let FullOrderList = this.state.OrderLists;
    let selectedOrd = this.state.SelectedOrders;
    let FselectedOrd = this.state.SelectedOrders;
    console.log(DMYD(updatedDetails.date),AMPM(updatedDetails.time))
    let smsObj = [];
    let newList = ListData.filter(function (value, index) {
      let orderExist = selectedOrd.includes(value.id);
       if (orderExist) {
        smsObj.push(
          {
            "type": "sms",
            "body": "Thank you for ordering from Petshop. Your order will be delivered on "+DMYD(updatedDetails.date)+" between 9.30am to 5.30pm. Please do not reply. System generated message.",
            "toMobileNo": GetFullMobileNo(value.contactNumber)
          }
        )
      } 
      return !orderExist;
    })
    //console.log(FselectedOrd,selectedOrd)
    let FullList = FullOrderList.filter(function (value, index) {
      let orderExist = selectedOrd.includes(value.id);
      
      /* if (orderExist) {
        var Arrayindex = FselectedOrd.indexOf(value.id);
        FselectedOrd.splice(Arrayindex, 1);
      } */
      return !orderExist;
    })
    
    this.setState({
      AssignTeamState: false,
      SelectedOrders: [],
      SelectedOrderIndex: [],
      OrderLists: FullList,
      FilteredOrderList: newList,
      getCalls: [0, 0]
    }, () => {
        console.log(JSON.stringify(smsObj))
       // this.props.SendSMS(smsObj);
      //this.props.refreshReduxStates();
    })
  }
  CloseAssign = () => {
    this.setState({
      AssignTeamState: false,
      SelectedOrders: [],
      SelectedOrderIndex: []
    })
  }
  //ASSIGN TEAM END
  checkedItemsChange = (e, value, tableMeta, updateValue) => {
    let selectedItems = this.state.SelectedOrders;
    let selectedIndex = this.state.SelectedOrderIndex;
    let Arrayindex = selectedItems.indexOf(value);
    if (selectedItems.includes(value)) {
      if (Arrayindex > -1) {
        selectedItems.splice(Arrayindex, 1);
        //selectedIndex.splice(Arrayindex, 1);
      }
    } else {
      selectedItems.push(value);
      // selectedIndex.push(Arrayindex);
    }
    this.setState({
      SelectedOrders: selectedItems,
      //SelectedOrderIndex : selectedIndex
    });
  }
  ScheduleDeleviryClick = () => {
    this.props.history.push('/deliverystatus')
  }
  ScheduleDeleviryClick = () => {
    if (this.state.SelectedOrders.length > 0) {
      this.setState({
        AssignTeamState: true
      })
    } else {
      toast.warning('Please choose order!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      });
    }
  }
  searchClick = (type) => {
    if (type == 1) {
      if (!this.state.DateStartSelected && !this.state.DateEndSelected && !this.state.PostalCode && !this.state.FilterContactNo) {
        toast.warning('Please choose any one filter option!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: false,
        });
        return;
      } else if (!this.state.DateStartSelected || !this.state.DateEndSelected) {
        toast.warning("Please choose start and end date", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: false,
        });
        return;
      }
      let dateStartPicked = this.state.DateStartSelected ? this.state.DateStartSelected : null;
      let dateEndPicked = this.state.DateEndSelected ? this.state.DateEndSelected : null;
      let postalSelected = this.state.PostalCode;
      let ContactNo = this.state.FilterContactNo;

      /* let ItemsSelected = _.filter(this.state.OrderLists, function (item) {
        return (dateStartPicked && dateEndPicked ? (dateTimetoDate(item.createdAt) >= dateStartPicked && dateTimetoDate(item.createdAt) <= dateEndPicked) : true)
          && (postalSelected ? (postalSelected == item.postalCode) : true)
          && (ContactNo ? (ContactNo == item.contactNumber) : true);
      }); */
      let filterObj = {
        StartDate: dateStartPicked,
        EndDate: dateEndPicked,
        OrderType: 1,
        DeliveryStatus: 1,
        SellerType: ""
      }
      this.setState({
        isLoading:true,
        getCalls: [1, 0],
        //FilteredOrderList: ItemsSelected,
      },()=>{
        this.props.getOrder(filterObj);
      })
    } else if (type == 2) {
      if (!this.state.TeamSelectedID) {
        toast.warning('Please choose any team!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: false,
        });
        return;
      } else {
        this.setState({
          ListModel: true
        })
      }
    }
  }
  clearSearchClick = (type) => {
    if (type == 1) {
      let filterObj = {
        StartDate: DateMinusYMD(15),
        EndDate: todayDate(),
        OrderType: 1,
        DeliveryStatus: 1,
        SellerType: ""
      }
     /*  let FilteredOrders = _.filter(this.state.OrderLists, function (item) {
        return (dateTimetoDate(item.createdAt) >= DateMinusYMD(15) && dateTimetoDate(item.createdAt) <= todayDate());
      }); */
      this.setState({
       // FilteredOrderList: FilteredOrders,
        DateStartSelected: DateMinusYMD(15),
        DateEndSelected: todayDate(),
        PostalCode: "",
        FilterContactNo: null,
        isLoading: true,
        getCalls: [1, 0],
      },()=>{
        this.props.getOrder(filterObj);
      })
    } else if (type == 2) {
      this.setState({
        Filter2ListID: "",
        Filter2ListSelected: "",
        Filter2Date: "",
        TeamSelectedID: "",
        TeamSelected: ""
      })
    }
  }
  CloseList = () => {
    this.setState({
      ListModel: false
    })
  }
  CanceledConfirm = () => {
    let formData = new FormData();
    formData.append('deliveryStatusId', 5);
    formData.append('orderId', this.state.CancelID);
    this.setState({
      postCalls: [0, 0, 0, 1],
      isLoading: true
    }, () => {
      this.props.UpdateOrderStatus(formData);
    })
  }
  CanceledClose = () => {
    this.setState({
      CancelState: false,
      CancelID: null,
    })
  }
  CancelOrder = (id, index) => {
    this.setState({
      CancelState: true,
      CancelID: id,
    })
  }
  render() {
    const { classes } = this.props;
    const columns = [
      {
        name: "id",
        label: "Select",
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Grid item md={12}>
                <Checkbox
                  checked={this.state.SelectedOrders ? (this.state.SelectedOrders).includes(value) : false}
                  onChange={(e) => this.checkedItemsChange(e, value, tableMeta, updateValue)}
                  name="checkedB"
                  color="primary"
                  size="small"
                />
              </Grid>
            )
          }
        }
      },
      {
        name: "orderNumber",
        label: "Order Number",
        options: {
          filter: false,
          sort: true,
          display:false
        }
      },
      {
        name: "customerInvoiceNumber",
        label: "Custom Invoice",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "createdAt",
        label: "Order Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return convertDateDMY(value);
          }
        }
      },
      {
        name: "customerName",
        label: "Customer Name",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "contactNumber",
        label: "Contact Number",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "address",
        label: "Address",
        options: {
          filter: false,
          sort: false,
        }
      },
      {
        name: "postalCode",
        label: "Postal Code",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "items",
        label: "Items to Deliver",
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({ style: { minWidth: "250px"} }),
          /* customBodyRender: (value, tableMeta, updateValue) => {
            let checkString = value.includes(",");
            let items = checkString ? value.split(",") : [value];
            return (
              <Grid item md={12}>
                {items.map((itemVal, index) => (
                  <Typography>{itemVal}</Typography>
                ))}

              </Grid>
            )
          } */
        }
      },
      {
        name: "remark",
        label: "Remarks",
        options: {
          filter: false,
          sort: false,
        }
      },
      {
        name: "",
        label: "Actions",
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({ style: { minWidth: "120px"} }),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <ButtonIcon
                EditTitle={"Edit Order"}
                DeleteTitle={"Delete Order"}
                CancelTitle={"Cancel Order"}
                Cancel={() => this.CancelOrder(tableMeta.rowData[0], tableMeta.rowIndex)}
                Edit={() => this.editPress(tableMeta.rowData[0], tableMeta.rowIndex)}
                Delete={() => this.deletePress(tableMeta.rowData[0], tableMeta.rowIndex)}
              />
            )
          }
        }
      }
    ];


    return (
      <>
        {/* <PageTitle title="Ad Hoc Delivery" /> */}
        <Grid container spacing={4}>
          {/* <Grid item md={12} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => this.ScheduleDeleviryClick()}
              className={classes.buttonSuccess}
              variant="contained" color="primary">
              Delivery Status
          </Button>
          </Grid> */}
          <Grid container item spacing={1} lg={12} md={12} sm={12} xl={12} xs={12} direction={"row"} style={{ marginTop: 0 }}>
            <Grid item lg={2} md={2} sm={2} xl={2} xs={2}>
              <InputField
                label={"Start Date"}
                type={"date"}
                required={false}
                value={this.state.DateStartSelected}
                changeText={(data) => { 
                  this.setState({ 
                    DateStartSelected: data ? data.target.value : null,
                    DateEndSelected: data ? LastDayMonth(data.target.value) : null 
                  }) }}
                // error={Createerrors.Date}
                initialName={"Start Date"}
                errorMsg={"Please enter date."}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={2} xl={2} xs={2}>
              <InputField
                label={"End Date"}
                type={"date"}
                required={false}
                value={this.state.DateEndSelected}
                changeText={(data) => { this.setState({ DateEndSelected: data ? data.target.value : null }) }}
                // error={Createerrors.Date}
                initialName={"End Date"}
                errorMsg={"Please enter date."}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={2} xl={2} xs={2}>
              <InputField
                label={"Postal Code"}
                type={"number"}
                required={false}
                value={this.state.PostalCode}
                changeText={(data) => this.setState({ PostalCode: data ? data.target.value : null })}
                // error={Createerrors.Date}
                initialName={"Postal Code"}
                errorMsg={"Please enter postal code."}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={2} xl={2} xs={2}>
              <InputField
                label={"Contact Number"}
                type={"number"}
                required={false}
                value={this.state.FilterContactNo}
                changeText={(data) => this.setState({ FilterContactNo: data ? data.target.value : null })}
                // error={Createerrors.Date}
                initialName={"Contact Number"}
                errorMsg={"Please enter contact no."}
              />
            </Grid>
            <Grid item lg={1} md={1} sm={1} xl={1} xs={1}>
              <Button
                onClick={() => this.clearSearchClick(1)}
                className={classes.buttonCancel}
                variant="contained" color="primary">
                Clear
                  </Button>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xl={1} xs={1}>
              <Button
                onClick={() => this.searchClick(1)}
                className={classes.buttonSuccess}
                variant="contained" color="primary">
                Search
                  </Button>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>

            <Button
              onClick={() => this.ScheduleDeleviryClick()}
              className={classes.buttonSuccess}
              variant="contained" color="primary">
              Schedule Delivery
                </Button>

          </Grid>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <MUIDataTable
              title="Ad Hoc Delivery List"
              data={this.state.FilteredOrderList}
              columns={columns}
              options={{
                sort: true,
                selectableRows: false,
                print: false,
                download: false,
                viewColumns: false,
                responsive:true,
                filter: false,
                customToolbar: () => {
                  return (
                    <CustomToolbar title={"Add Ad Hoc"} iconOnPress={() => this.AddingAdhoc()} />
                  );
                }
              }}
            />
          </Grid>
          {/* <Grid container item spacing={4} lg={12} direction={"row"} style={{ marginTop: 0 }}>
            <Grid item lg={6} >
              <Button
                onClick={() => this.ScheduleDeleviryClick()}
                className={classes.buttonSuccess}
                variant="contained" color="primary">
                Schedule Delivery
                </Button>
            </Grid>
            <Grid item lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={() => this.DeleviryStatusClick()}
                className={classes.buttonSuccess}
                variant="contained" color="primary">
                Delivery Status
            </Button>
            </Grid>
          </Grid> */}
          <AlertDialog
            Status={this.state.DialogState}
            DialogConfirm={() => { this.DeleteConfirm() }}
            DialogCancel={() => { this.DeleteClose() }}
            Title={"Delete Ad Hoc"}
            Content={"Are you sure, you want to delete the Ad hoc ?"}
          />
           <AlertDialog
            Status={this.state.CancelState}
            DialogConfirm={() => { this.CanceledConfirm() }}
            DialogCancel={() => { this.CanceledClose() }}
            Title={"Cancel Order"}
            Content={"Are you sure, you want to cancel order ?"}
          />
          {this.state.ModelState ? <CreateAdhoc
            Title={"Add Ad Hoc"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.ModelState}
            ModelConfirm={this.AddedAdhoc}
            ModelCancel={this.CloseAdd}
          /> : null}
          {this.state.EditModelState ? <EditAdhoc
            Title={"Edit Ad Hoc"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.EditModelState}
            ModelConfirm={this.EditedAdhoc}
            ModelCancel={this.CloseEdit}
          /> : null}
          {this.state.AssignTeamState ? <AssignTeam
            Title={"Assign Team"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.AssignTeamState}
            ModelConfirm={this.AssignedTeam}
            ModelCancel={this.CloseAssign}
          /> : null}
          {this.state.ListModel ? <ViewList
            Title={"Lists"}
            parentClass={this}
            parentState={this.state}
            Status={this.state.ListModel}
            ModelCancel={this.CloseList}
          /> : null}
          <LoadingOverlay Status={this.state.isLoading} />
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    TeamListSuccess: state.getTeamsReducer.Success,
    TeamListFailure: state.getTeamsReducer.Failure,
    TeamLists: state.getTeamsReducer.TeamList,

    OrdersListSuccess: state.getOrderReducer.Success,
    OrdersListFailure: state.getOrderReducer.Failure,
    OrdersLists: state.getOrderReducer.OrdersList,

    AdhocListSuccess: state.getAdhocReducer.Success,
    AdhocListFailure: state.getAdhocReducer.Failure,
    AdhocList: state.getAdhocReducer.AdhocList,

    OrderUpdateSuccess: state.UpdateOrderStatusReducer.Success,
    OrderUpdateFailure: state.UpdateOrderStatusReducer.Failure,
    OrderUpdateResponse: state.UpdateOrderStatusReducer.UpdateResponse,

    DeleteAdhocSuccess: state.DeleteAdhocReducer.Success,
    DeleteAdhocFailure: state.DeleteAdhocReducer.Failure,
    DeleteAdhocResponse: state.DeleteAdhocReducer.DeleteResponse,

    UserDetails: state.LoginReducer.UserDetails,
    LoaderState: state.LoaderReducer.State
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTeams: (ID) => dispatch(getTeams(ID)),
    getOrder: (filterObj) => dispatch(getOrder(filterObj)),
    getAdhoc: () => dispatch(getAdhoc()),
    DeleteAdhoc: (ID) => dispatch(DeleteAdhoc(ID)),
    UpdateOrderStatus: (UpdateObj) => dispatch(UpdateOrderStatus(UpdateObj)),
    SendSMS: (SMSBody) => dispatch(SendSMS(SMSBody)),
    refreshReduxStates: () => dispatch(refreshReduxStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdhocDelivery));
